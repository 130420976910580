/**
 * Decodes a base64 string and returns the decoded value.
 * @param base64 - The base64 string to decode.
 * @returns The decoded string.
 */
export const decodeBase64 = (base64: string): string => {
  const decodedString = window.atob(base64);
  return decodeURIComponent(
    Array.prototype.map
      .call(decodedString, function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
};

/**
 * Decodes a JSON Web Token (JWT) and returns the decoded payload.
 * @param token - The JWT to decode.
 * @returns The decoded payload as a record of key-value pairs.
 */
export const decodeJWT = (token: string): Record<string, any> => {
  let decoded: Record<string, any> = {};
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    const jsonPayload = decodeBase64(base64);
    const decoded = JSON.parse(jsonPayload);
    return decoded;
  } catch (error) {
    console.error('Error: Could not decode JWT: ' + error);
  }
  return decoded;
};

/**
 * Retrieves the organization IDs from a JWT token.
 * @param token - The JWT token.
 * @returns An array of organization IDs.
 */
export const getOrgsFromJWT = (token: string): string[] => {
  const decodedJWT = decodeJWT(token);
  const orgs: string[] = [];

  for (const key in decodedJWT) {
    if (key.startsWith('org_') && key !== 'org_') {
      const orgId = key.replace('org_', '');

      orgs.push(orgId);
    }
  }
  return orgs;
};
