import { Organization, TransportItemType, KeyDescription, SustainabilityScoreInput } from '../../generated/sof-graphql';
import { SofApplicationContextActions, SofApplicationContextActionTypes } from './actions';
import { initialState } from './SofApplicationContext';

export interface SofApplicationContextState {
  organizations: Organization[];
  activeOrganization: Organization | null;
  transportItemTypes: TransportItemType[];
  vehicleBrands: KeyDescription[];
  orgRoles: string[];
  sustainabilityScores: SustainabilityScoreInput[];
}

export const sofApplicationContextReducer = (
  state: SofApplicationContextState,
  action: SofApplicationContextActions,
): SofApplicationContextState => {
  switch (action.type) {
    case SofApplicationContextActionTypes.UPDATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SofApplicationContextActionTypes.CLEAR: {
      return initialState;
    }
    case SofApplicationContextActionTypes.SET_ACTIVE_ORGANIZATION: {
      return {
        ...state,
        activeOrganization: action.payload,
      };
    }

    default:
      return state;
  }
};
