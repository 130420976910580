import React, { useContext } from 'react';
import { Navigate } from 'react-router';
import { CognitoUser } from '../../../hooks/auth/useAuth';
import { routesMap, TeraloRoute } from '../../PageRoutes/routes';
import { SofApplicationContext } from '../../../context/SofApplicationContext/SofApplicationContext';
import { checkPermissions } from '../../../utils/permissions';
interface Props {
  user: CognitoUser | null;
  redirectPath?: string;
  children?: React.ReactElement;
  requiredPermissions: string[];
}

const ProtectedRoute = ({ user, redirectPath, children, requiredPermissions }: Props) => {
  const { state } = useContext(SofApplicationContext);

  if (!user?.username) {
    return <Navigate to={redirectPath || routesMap[TeraloRoute.LOGIN]} replace />;
  }

  if (state.orgRoles && state.orgRoles.length > 0 && !checkPermissions(requiredPermissions, state.orgRoles)) {
    return <Navigate to="/" replace />;
  }

  return children ? children : <div />;
};

export default ProtectedRoute;
