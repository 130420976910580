import en from '../translations/en.json';
import it from '../translations/it.json';
import fr from '../translations/fr.json';

const messages: Record<string, { id: string; defaultMessage: string; message: string }[]> = {
  en,
  it,
  fr,
};

const parseTranslationsFromJSON = (language: string): Record<string, string> => {
  return messages[language].reduce((acc: Record<string, string>, value) => {
    value.message !== '' ? (acc[value.id] = value.message) : (acc[value.id] = value.defaultMessage);
    return acc;
  }, {});
};

const getTranslations = (language: string): Record<string, string> => {
  if (!messages.hasOwnProperty(language)) {
    console.error(`Locale '${language}' not found`);
    return parseTranslationsFromJSON('en');
  }

  return messages[language].reduce((acc: Record<string, string>, value) => {
    value.message !== '' ? (acc[value.id] = value.message) : (acc[value.id] = value.defaultMessage);
    return acc;
  }, {});
};

export default getTranslations;
