import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { SofApplicationContext } from '../../../context/SofApplicationContext/SofApplicationContext';

interface Props {
  navigationMenuOpen: boolean;
  closeUserMenu: () => void;
  children: React.ReactNode;
}

const Main = ({ navigationMenuOpen, children, closeUserMenu }: Props) => {
  const { state } = useContext(SofApplicationContext);

  const open: boolean = state.activeOrganization?.id ? navigationMenuOpen : false;

  return (
    <StyledMain onClick={closeUserMenu} open={open}>
      {children}
    </StyledMain>
  );
};

const StyledMain = styled.main<{ open: boolean }>`
  padding-top: 64px;
  height: 100vh;
  @media (min-width: 1535px) {
    margin-left: ${(props) => (props.open ? 320 : 0)}px;
  }

  @media (min-width: ${(props: any) => props.theme.breakpoints.values.sm}px) {
    flex-grow: 1;
    transition: margin cubic-bezier(0.4, 0, 0.6, 1) 195;
    margin-left: 0;

    ${(props) =>
      props.open
        ? `
  margin-left: 320px;
  transition: margin cubic-bezier(0.0, 0, 0.2, 1) 225;
  `
        : ''}
  }
`;

export default Main;
