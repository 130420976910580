import * as React from 'react';
import { CircularProgress } from '@ccsdk/kingbolt';
import { AuthContext } from './context/AuthContext';
import { useEffect, useMemo, useState } from 'react';
import Header from './components/Header/Header';
import NavigationMenu from './components/NavigationMenu/NavigationMenu';
import { CognitoUser, useAuth } from './hooks/auth/useAuth';
import AppRoutes from './components/PageRoutes/AppRoutes';
import Main from './components/common/Main/Main';
import { Hub } from 'aws-amplify';
import ToastComponent from './components/common/Toast/ToastComponent';
import { SofApplicationContextProvider } from './context/SofApplicationContext/SofApplicationContext';
import AppProviders from './context';
/*
 * This is entry point. It contains a router which sets the routes and components.
 */

function App() {
  const [currentUser, setCurrentUser] = useState<CognitoUser | null>(null);
  const [userChecked, setUserChecked] = useState(false);
  const [navigationMenuOpen, setNavigationMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const { checkCurrentUser, signOut } = useAuth();

  const token: string = useMemo(() => (currentUser as any)?.signInUserSession?.idToken?.jwtToken, [currentUser]);

  useEffect(() => {
    return Hub.listen('auth', ({ payload: { event } }) => {
      const isDev = process.env.NODE_ENV === 'development';
      if (isDev) console.log('event ----->', event);
      if (event === 'tokenRefresh_failure') {
        if (isDev)
          console.log('Do you actually get signedOut and redirected to login or do we need to add next line??');
        //navigate(routesMap[TeraloRoute.LOGIN]);
        signOut();
      }
    });
  }, [signOut]);

  useEffect(() => {
    if (!currentUser?.username && !userChecked) {
      checkCurrentUser(setCurrentUser, () => setUserChecked(true));
    }
  }, [currentUser, checkCurrentUser, userChecked]);

  if (!userChecked) {
    return <CircularProgress data-testid="user-not-checked-circular-progress" />;
  }

  return (
    <AppProviders>
      <SofApplicationContextProvider token={token} currentUser={currentUser}>
        <AuthContext.Provider value={{ user: currentUser, setUser: setCurrentUser }}>
          <>
            <Header
              setNavigationMenuOpen={setNavigationMenuOpen}
              navigationMenuOpen={navigationMenuOpen}
              userMenuOpen={userMenuOpen}
              setUserMenuOpen={setUserMenuOpen}
            />
            <NavigationMenu open={navigationMenuOpen} />

            <Main closeUserMenu={() => setUserMenuOpen(false)} navigationMenuOpen={navigationMenuOpen}>
              <React.Suspense fallback={<CircularProgress data-testid="fallback-circular-progress" />}>
                <AppRoutes currentUser={currentUser} />
              </React.Suspense>
            </Main>

            <ToastComponent />
          </>
        </AuthContext.Provider>
      </SofApplicationContextProvider>
    </AppProviders>
  );
}

export default App;
