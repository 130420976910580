import React, { createContext, Dispatch, useEffect, useMemo, useReducer, useState } from 'react';
import { ApplicationContext as ApplicationContextValue, useApplicationContextLazyQuery } from '../../generated/graphql';
import { ApplicationContextActions, ApplicationContextActionTypes } from './actions';
import { applicationContextReducer } from './reducers';
import { useToast, ToastLevel } from '../ToastContext/ToastContext';

export const initialState: ApplicationContextValue = {
  activeFlorg: {
    id: null,
    name: '',
    organizationTypes: [],
    disabled: false,
    contactPerson: {
      firstName: '',
      lastName: '',
      email: '',
    },
  },
  activeUser: {
    florgsWithRoles: [],
    id: '',
    name: '',
  },
  fuelTypes: [],
  transportItemTypes: [],
  vehicleBrands: [],
  environmentalClasses: [],
  userRights: [],
};

export const ApplicationContext = createContext<{
  applicationState: ApplicationContextValue;
  applicationDispatch: Dispatch<ApplicationContextActions>;
  contextFetched: boolean;
  clearApplicationContext: () => void;
}>({
  applicationState: initialState,
  applicationDispatch: () => null,
  contextFetched: false,
  clearApplicationContext: () => null
});

interface Props {
  children: React.ReactNode;
  token?: string;
}

export const ApplicationContextProvider: React.FC<Props> = ({ children, token }) => {
  const [state, dispatch] = useReducer(applicationContextReducer, initialState);
  const [contextFetched, setContextFetched] = useState(false);
  const [getApplicationContext] = useApplicationContextLazyQuery();
  const { openToast } = useToast();

  useEffect(() => {
    if (token) {
      getApplicationContext({
        onCompleted: (result) => {
          dispatch({
            type: ApplicationContextActionTypes.UPDATE,
            payload: result.getApplicationContext as ApplicationContextValue,
          });
          setContextFetched(true);
        },
        onError: (error) => {
          openToast(JSON.stringify(error), ToastLevel.ERROR);
        },
      });
    }
  }, [openToast, getApplicationContext, token]);

  const clearApplicationContext = () => {
    dispatch({
      type: ApplicationContextActionTypes.CLEAR
    })
    setContextFetched(false);
  }

  const value = useMemo(
    () => ({
      applicationState: state,
      applicationDispatch: dispatch,
      contextFetched,
      clearApplicationContext,
    }),
    [state, contextFetched],
  );

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};
