import React, {
  createContext,
  FunctionComponent,
  useContext, useMemo,
  useState,
} from 'react';

export enum ToastLevel {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

const defaultContextValue = {
  showToast: false,
  toastMessage: '',
  toastLevel: ToastLevel.ERROR,
  closeToast: () => {},
  openToast: () => {},
};

interface ToastContextInterface {
  showToast: boolean;
  toastLevel: ToastLevel;
  closeToast(): void;
  openToast(message: string, level?: ToastLevel): void;
  toastMessage: string;
}

export const ToastContext =
  createContext<ToastContextInterface>(defaultContextValue);
const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastContext');
  }
  return context;
};

interface Props {
  children: React.ReactNode
}

const ToastContextProvider: FunctionComponent<Props> = ({ children }) => {
  const [showToast, setShowToast] = useState<boolean>(
    defaultContextValue.showToast,
  );
  const [toastMessage, setToastMessage] = useState<string>(
    defaultContextValue.toastMessage,
  );
  const [toastLevel, setToastLevel] = useState<ToastLevel>(
    defaultContextValue.toastLevel,
  );

  const closeToast = () => {
    setShowToast(false);
  };

  const openToast = (message: string, level: ToastLevel = ToastLevel.ERROR) => {
    setToastLevel(level);
    setShowToast(true);
    setToastMessage(message);
  };

  const value = useMemo(() => ({
    showToast,
    closeToast,
    openToast,
    toastMessage,
    toastLevel,
    useToast,
  }), [showToast, toastMessage, toastLevel ]);

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

export { ToastContextProvider, useToast };
