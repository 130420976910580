import { createContext } from 'react';
import { CognitoUser } from '../hooks/auth/useAuth';

export interface AuthContextValue {
  user: CognitoUser | null;
  setUser: (user: any) => void;
}

export const AuthContext = createContext<AuthContextValue>({
  user: null,
  setUser: () => {},
});
