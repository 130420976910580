/**
 * Checks if the user has the required permissions.
 * @param requiredPermissions - An array of required permissions.
 * @param orgRoles - An array of org roles.
 * @returns A boolean indicating whether the user has the required permissions.
 */
export const checkPermissions = (requiredPermissions: string[] | null, orgRoles: string[]): boolean => {
  const userPermissions: Set<string> = new Set(orgRoles);
  return requiredPermissions
    ? requiredPermissions.every((requiredPermission) => userPermissions.has(requiredPermission))
    : true;
};
