import { ActionMap } from '../types';
import { SofApplicationContextState } from './reducer';
import { Organization } from '../../generated/sof-graphql';

export enum SofApplicationContextActionTypes {
  UPDATE = 'UPDATE',
  CLEAR = 'CLEAR',
  SET_ACTIVE_ORGANIZATION = 'SET_ACTIVE_ORGANIZATION',
}

export type SofApplicationContextPayload = {
  [SofApplicationContextActionTypes.UPDATE]: SofApplicationContextState;
  [SofApplicationContextActionTypes.SET_ACTIVE_ORGANIZATION]: Organization;
  [SofApplicationContextActionTypes.CLEAR]: undefined;
};

export type SofApplicationContextActions =
  ActionMap<SofApplicationContextPayload>[keyof ActionMap<SofApplicationContextPayload>];
