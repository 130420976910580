import { MessageDescriptor } from 'react-intl';
interface IntlMessage {
  [key: string]: MessageDescriptor;
}

/**
 * Retrieves a translation message descriptor by its string key.
 * @param key - The string key of the translation message.
 * @param messages - The object containing the translation messages.
 * @param suffix - An optional suffix to append to the key before searching for the message.
 * @returns The message descriptor if found, otherwise null.
 */
export const getTranslationByStringKey = (
  key: string,
  messages: IntlMessage,
  suffix?: string,
): MessageDescriptor | null => {
  let concatStringKey = suffix ? `${key.toLowerCase()}${suffix}` : `${key.toLowerCase()}`;
  if (messages[concatStringKey]) {
    return messages[`${concatStringKey}`];
  }
  return null;
};
