import React, { useContext } from 'react';
import { Snackbar, Alert } from '@ccsdk/kingbolt';
import { ToastContext } from '../../../context/ToastContext/ToastContext';

const ToastComponent = () => {
  const { showToast, closeToast, toastMessage, toastLevel } = useContext(ToastContext);
  return (
    <Snackbar data-testid={'toast-snackbar'} open={showToast} autoHideDuration={5000} onClose={closeToast}>
      <Alert
        data-testid={'toast-alert'}
        onClose={closeToast}
        severity={toastLevel || 'error'}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {toastMessage || '-'}
      </Alert>
    </Snackbar>
  );
};

export default ToastComponent;
