import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { AppBar, IconButton, Toolbar, Typography, Icon, Avatar } from '@ccsdk/kingbolt';
import { AuthContext, AuthContextValue } from '../../context/AuthContext';
import styled from '@emotion/styled';
import { ReactComponent as AppLogo } from '../../static/app_logo.svg';
import UserMenu from '../UserMenu/UserMenu';
import { Link } from 'react-router-dom';
import { TeraloRoute, routesMap } from '../PageRoutes/routes';
import { SofApplicationContext } from '../../context/SofApplicationContext/SofApplicationContext';
import { FrFlagIcon, EnFlagIcon } from '../../icons';
import { useIntlProviderContext } from '../../context/IntlContext/IntlContext';

interface Props {
  setNavigationMenuOpen: Dispatch<SetStateAction<boolean>>;
  navigationMenuOpen: boolean;
  setUserMenuOpen: Dispatch<SetStateAction<boolean>>;
  userMenuOpen: boolean;
}

const Header: React.FC<Props> = ({ setNavigationMenuOpen, navigationMenuOpen, setUserMenuOpen, userMenuOpen }) => {
  const { user } = useContext<AuthContextValue>(AuthContext);
  const { state } = useContext(SofApplicationContext);
  const { changeLocale } = useIntlProviderContext();
  const getInitials = (): string => {
    if (user?.attributes?.family_name && user.attributes.given_name && user.attributes.family_name !== 'placeholder')
      return user.attributes.given_name.charAt(0).toUpperCase() + user.attributes.family_name.charAt(0).toUpperCase();
    if (user?.attributes?.email) return user.attributes.email.slice(0, 2).toUpperCase();
    return '';
  };
  const initials: string = getInitials();

  const toggleNavigationMenu = () => {
    setNavigationMenuOpen((value) => !value);
  };

  const toggleUserMenu = () => {
    setUserMenuOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    setNavigationMenuOpen(!!state.activeOrganization?.id);
  }, [state.activeOrganization?.id, setNavigationMenuOpen]);

  const email: string | undefined = user?.attributes?.email;

  const showUserMenu: boolean = !!user?.username && !!email && !!state.activeOrganization;

  return (
    <AppHeaderBar position="fixed">
      <AppHeader>
        <LogoSection>
          {user && state.activeOrganization && (
            <IconButton size="large" edge="start" aria-label="menu" sx={{ mr: 2 }} onClick={toggleNavigationMenu}>
              <Icon type={navigationMenuOpen ? 'close' : 'hamburger'} color="primary" />
            </IconButton>
          )}
          <Link to={routesMap[TeraloRoute.DASHBOARD]}>
            <AppLogo />
          </Link>
        </LogoSection>
        <LocaleSection>
          <FrFlagIcon onClick={() => changeLocale('fr')} />
          <EnFlagIcon onClick={() => changeLocale('en')} />
        </LocaleSection>
        {showUserMenu && (
          <HeaderSection>
            <Divider />
            <UserInfo onClick={toggleUserMenu}>
              <AccountName data-testid="header-account-name" variant="button">
                {state.activeOrganization?.description}
              </AccountName>
              <UserAvatar
                data-testid="user-avatar"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
              >
                {initials}
              </UserAvatar>
            </UserInfo>
            <UserMenu open={userMenuOpen} toggleUserMenu={toggleUserMenu} />
          </HeaderSection>
        )}
      </AppHeader>
    </AppHeaderBar>
  );
};
const LocaleSection = styled.div`
  display: flex;
  align-items: center;
  width: 60px;
  margin-left: auto;
  margin-right: 30px;
  svg {
    margin-left: 10px;
    cursor: pointer;
    width: 100%;
  }
`;

const AppHeaderBar = styled(AppBar)`
  .MuiToolbar-root {
    padding-right: 0;
    width: 100%;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;

  :hover {
    background-color: ${(props: any) => props.theme.palette.action.hover};
    border-radius: 24px;
    cursor: pointer;
  }
`;

const Divider = styled.div`
  margin-right: 16px;
  height: 24px;
  width: 1px;
  background-color: ${(props: any) => props.theme.palette.grey[300]};

  @media (max-width: ${(props: any) => props.theme.breakpoints.values.sm}px) {
    margin-right: 0;
  }
`;

const AppHeader = styled(Toolbar)`
  background-color: ${(props: any) => props.theme.palette.common.white};
  justify-content: space-between;
  border-bottom: 1px solid ${(props: any) => props.theme.palette.grey[300]};
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
`;

const AccountName = styled(Typography)`
  @media (max-width: ${(props: any) => props.theme.breakpoints.values.sm}px) {
    display: none;
  }
  color: ${(props: any) => props.theme.palette.common.black};
`;

const UserAvatar = styled(Avatar)`
  margin-left: 8px;
  font-size: 14px;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default Header;
