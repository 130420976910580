import { Routes } from 'react-router-dom';
import { Route } from 'react-router';
import React, { useContext } from 'react';
import { RouteConfig, routesList } from './routes';
import ProtectedRoute from '../common/ProtectedRoute/ProtectedRoute';
import { CognitoUser } from '../../hooks/auth/useAuth';
import NoRouteMatchContainer from '../NoRouteMatch/NoRouteMatchContainer';
import { CircularProgress } from '@ccsdk/kingbolt';
import { SofApplicationContext } from '../../context/SofApplicationContext/SofApplicationContext';

interface Props {
  currentUser: CognitoUser | null;
}

const AppRoutes: React.FC<Props> = ({ currentUser }) => {
  let routes: React.JSX.Element[] = [];
  const { contextFetched } = useContext(SofApplicationContext);

  const getRoute = (
    path: string | string[],
    Component: React.LazyExoticComponent<React.ComponentType<any>>,
    requiredPermissions: string[] | null,
  ): React.JSX.Element[] => {
    const pageToRender = requiredPermissions ? (
      <ProtectedRoute user={currentUser} requiredPermissions={requiredPermissions}>
        {!contextFetched && currentUser ? <CircularProgress /> : <Component />}
      </ProtectedRoute>
    ) : (
      <Component />
    );

    if (Array.isArray(path)) {
      return path.map((routePath) => <Route key={routePath} path={routePath} element={pageToRender} />);
    }
    return [<Route key={path} path={path} element={pageToRender} />];
  };

  routesList.forEach((routeElement) => {
    if ('subItems' in routeElement && routeElement.subItems) {
      routeElement.subItems.forEach((routeItem: RouteConfig) => {
        routes = [...routes, ...getRoute(routeItem.path, routeItem.component, routeItem.requiredPermissions)];
      });
      return;
    }

    if ('path' in routeElement) {
      routes = [...routes, ...getRoute(routeElement.path, routeElement.component, routeElement.requiredPermissions)];
    }
  });

  return (
    <Routes>
      {routes}
      <Route path="*" element={<NoRouteMatchContainer />} />
    </Routes>
  );
};

export default AppRoutes;
