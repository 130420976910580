import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Avatar, Typography } from '@ccsdk/kingbolt';
import { ApplicationContext } from '../../context/ApplicationContext/ApplicationContextProvider';
import { SofApplicationContext } from '../../context/SofApplicationContext/SofApplicationContext';

interface Props {
  className?: string;
}

const UserData = ({ className }: Props) => {
  const {
    applicationState: { activeUser },
  } = useContext(ApplicationContext);
  const {
    state: { activeOrganization },
  } = useContext(SofApplicationContext);

  const [fistName, lastName] = activeUser.name.split(' ');
  const initials: string = `${fistName.charAt(0).toUpperCase()}${
    lastName?.length ? lastName.charAt(0).toUpperCase() : ''
  }`;

  return (
    <Container className={className}>
      <UserAvatar>{initials}</UserAvatar>
      <Typography variant="body1">{activeUser.name}</Typography>
      <Typography variant="body1">{activeOrganization?.description || ''}</Typography>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
`;

const UserAvatar = styled(Avatar)`
  width: 3.5rem;
  height: 3.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  background-color: ${(props: any) => props.theme.palette.grey[200]};
  border: 2px solid ${(props: any) => props.theme.palette.grey[700]};
  color: ${(props: any) => props.theme.palette.common.black};
`;

export default UserData;
