import React, { createContext, FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import getTranslations from '../../utils/i18n';
import { IntlProvider } from 'react-intl';

const defaultLocale: string = 'en';

const defaultContextValue = {
  locale: { locale: defaultLocale, messages: getTranslations(defaultLocale) },
  changeLocale: () => {},
};

interface IntlContextInterface {
  locale: { locale: string; messages: Record<string, string> };
  changeLocale(locale: string): void;
}

export const IntlContext = createContext<IntlContextInterface>(defaultContextValue);

const useIntlProviderContext = () => {
  const context = useContext(IntlContext);
  if (!context) {
    throw new Error('useIntlProviderContext must be used within a IntlContext');
  }
  return context;
};

interface Props {
  children: React.ReactNode;
  selectedLanguage?: string;
}

const IntlContextProvider: FunctionComponent<Props> = ({ children, selectedLanguage }) => {
  const [locale, setLocale] = useState<{ locale: string; messages: Record<string, string> }>(
    selectedLanguage
      ? { locale: selectedLanguage, messages: getTranslations(selectedLanguage) }
      : defaultContextValue.locale,
  );

  const changeLocale = useCallback((locale: string): void => {
    if (locale) setLocale({ locale: locale, messages: getTranslations(locale) });
  }, []);

  const value = useMemo(
    () => ({
      locale,
      changeLocale,
    }),
    [changeLocale, locale],
  );

  return (
    <IntlContext.Provider value={value}>
      <IntlProvider locale={locale.locale} defaultLocale={defaultLocale} messages={locale.messages}>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};

export { IntlContextProvider, useIntlProviderContext };
