import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import messages from '../../messages';
import { Page, Container, Body, Card, CardHeader, CardContent, Button, Typography } from '@ccsdk/kingbolt';
import { routesMap, TeraloRoute } from '../PageRoutes/routes';

const NoRouteMatchContainer = () => {
  const { formatMessage } = useIntl();

  return (
    <Page>
      <Body>
        <Container maxWidth="xl">
          <Wrapper>
            <Card>
              <CardHeader title={formatMessage(messages.page_not_found)} />
              <CardContent>
                <Typography>{formatMessage(messages.page_not_found_description)}</Typography>
                <SecondaryText>{formatMessage(messages.page_not_found_instruction)}</SecondaryText>
              </CardContent>
              <CardContent>
                <Link to={routesMap[TeraloRoute.DASHBOARD]}>
                  <Button variant="outlined">{formatMessage(messages.start_page)}</Button>
                </Link>
              </CardContent>
            </Card>
          </Wrapper>
        </Container>
      </Body>
    </Page>
  );
};

const Wrapper = styled.div`
  margin: 2rem auto;
  max-width: 600px;
`;

const SecondaryText = styled(Typography)`
  margin-top: 1rem;
`;

export default NoRouteMatchContainer;
