import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  LocalDate: any;
  LocalDateTime: any;
};

export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  pid: Scalars['ID'];
  postCode: Scalars['String'];
};

export type AddressInput = {
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  postCode: Scalars['String'];
};

/**
 *
 * The information related to the context the user is logged in
 */
export type ApplicationContext = {
  __typename?: 'ApplicationContext';
  activeFlorg: Florg;
  activeUser: User;
  environmentalClasses: Array<EnvironmentalClass>;
  fuelTypes: Array<FuelType>;
  systemSetting?: Maybe<SystemSetting>;
  transportItemTypes: Array<TransportItemType>;
  userRights: Array<Scalars['String']>;
  vehicleBrands: Array<VehicleBrand>;
};

export type AssignTispFlorgPidInput = {
  florgPid: Scalars['String'];
  tispFlorgPid?: InputMaybe<Scalars['String']>;
};

/**
 *
 * A transport order assignment
 */
export type AssignedTransportOrder = {
  __typename?: 'AssignedTransportOrder';
  assignedFlorg: FlorgRef;
  assignedOrder: TransportOrder;
  assigningFlorg: FlorgRef;
  completedTime?: Maybe<Scalars['LocalDateTime']>;
  id: Scalars['ID'];
};

export type B2BClient = {
  __typename?: 'B2BClient';
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  clientSecret: Scalars['String'];
};

export enum BusinessType {
  Online = 'ONLINE',
  Retail = 'RETAIL',
  Total = 'TOTAL',
}

export type Co2eBusinessType = {
  __typename?: 'Co2eBusinessType';
  businessType?: Maybe<BusinessType>;
  item?: Maybe<Co2eWidget>;
};

export type Co2eWidget = {
  __typename?: 'Co2eWidget';
  lastMile?: Maybe<Scalars['Float']>;
  lineHaul?: Maybe<Scalars['Float']>;
  perKm?: Maybe<Scalars['Float']>;
  perUnit?: Maybe<Scalars['Float']>;
  periodDifference?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  perThu?: Maybe<Scalars['Float']>;
};

export type CompanyDetails = {
  __typename?: 'CompanyDetails';
  address: Address;
  registrationNumber: Scalars['String'];
  vatNumber: Scalars['String'];
};

export type CompanyDetailsInput = {
  address: AddressInput;
  registrationNumber: Scalars['String'];
  vatNumber: Scalars['String'];
};

export type DashboardData = {
  __typename?: 'DashboardData';
  previousPeriod?: Maybe<PreviousPeriod>;
  widgets: Widgets;
};

export enum DashBoardFuelType {
  Diesel = 'DIESEL',
  Electric = 'ELECTRIC',
  IgnitionGas = 'IGNITION_GAS',
  Renewable = 'RENEWABLE',
  Unknown = 'UNKNOWN',
}

export type DashboardInput = {
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};

export type DashboardRight = {
  __typename?: 'DashboardRight';
  rightPid: Scalars['String'];
  widgetType: DashboardWidgetType;
};

export enum DashboardWidgetType {
  Co2E = 'CO2E',
  EngineMix = 'ENGINE_MIX',
  GoodsVolume = 'GOODS_VOLUME',
  ItemsTransported = 'ITEMS_TRANSPORTED',
  Targets = 'TARGETS',
  Transports = 'TRANSPORTS',
}

/**
 *
 * The response returned for a delete request
 */
export type DeleteResponse = {
  __typename?: 'DeleteResponse';
  errorReasons?: Maybe<Array<ErrorReasonTuple>>;
  pids?: Maybe<Array<Scalars['String']>>;
  status: DeleteStatus;
};

export enum DeleteStatus {
  Error = 'ERROR',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export type DeleteUserInput = {
  sofPid: Scalars['String'];
};

/**
 *
 * ElectricVehicle
 */
export type ElectricVehicle = {
  __typename?: 'ElectricVehicle';
  environmentClass?: Maybe<EnvironmentalClass>;
  fuelType?: Maybe<FuelType>;
  grossVehicleWeight?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  telematicUnitType?: Maybe<TelematicType>;
  tispVehiclePid?: Maybe<Scalars['String']>;
  vehicleBrand?: Maybe<VehicleBrand>;
};

/**
 *
 * ElectricVehicle
 */
export type ElectricVehicleInput = {
  environmentClassId?: InputMaybe<Scalars['ID']>;
  fuelTypeId?: InputMaybe<Scalars['Int']>;
  grossVehicleWeight?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  telematicUnitType?: InputMaybe<TelematicType>;
  tispVehiclePid: Scalars['String'];
  vehicleBrandId?: InputMaybe<Scalars['ID']>;
};

export enum EmailStatus {
  Error = 'ERROR',
  Sent = 'SENT',
}

export enum EnergyMix {
  Average = 'AVERAGE',
  CoalAndOil = 'COAL_AND_OIL',
  HydroAndWind = 'HYDRO_AND_WIND',
}

export type EngineMixBusinessType = {
  __typename?: 'EngineMixBusinessType';
  businessType?: Maybe<BusinessType>;
  item?: Maybe<EngineMixWidget>;
};

export type EngineMixItem = {
  __typename?: 'EngineMixItem';
  fuelType?: Maybe<DashBoardFuelType>;
  lastMile?: Maybe<Scalars['Int']>;
  lineHaul?: Maybe<Scalars['Int']>;
};

export type EngineMixWidget = {
  __typename?: 'EngineMixWidget';
  items?: Maybe<Array<Maybe<EngineMixItem>>>;
};

export type EnvironmentalClass = {
  __typename?: 'EnvironmentalClass';
  id: Scalars['ID'];
  label: Scalars['String'];
};

/**
 *
 * Key Value pair containing error reasons, pids are the keys
 */
export type ErrorReasonTuple = {
  __typename?: 'ErrorReasonTuple';
  pid?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/**
 *
 * The Fleet organization owning the vehicles we are operating on, users working for the fleet are also referenced through the fleet context.
 */
export type Florg = {
  __typename?: 'Florg';
  address?: Maybe<Scalars['String']>;
  cdbId?: Maybe<Scalars['String']>;
  companyDetails?: Maybe<CompanyDetails>;
  companySignatory?: Maybe<Person>;
  contactPerson: Person;
  createdDate?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  florgsGrantedAccessToThisFlorg?: Maybe<Array<FlorgRef>>;
  grantedFlorgs?: Maybe<Array<FlorgRef>>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  invitedBy?: Maybe<Scalars['String']>;
  invoicingContact?: Maybe<Person>;
  name: Scalars['String'];
  organizationTypes: Array<OrganizationType>;
  taxCode?: Maybe<Scalars['String']>;
  tispFlorgPid?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Array<Vehicle>>;
};

export type FlorgApiNameInput = {
  apiName: Scalars['String'];
  pid: Scalars['String'];
};

export enum FlorgConnectionType {
  Accepted = 'ACCEPTED',
  New = 'NEW',
}

/**
 *
 * Florg : used for updating florg from support gui only.
 */
export type FlorgInput = {
  address?: InputMaybe<Scalars['String']>;
  companyDetails?: InputMaybe<CompanyDetailsInput>;
  companySignatory?: InputMaybe<PersonInput>;
  contactPerson: PersonInput;
  id?: InputMaybe<Scalars['ID']>;
  invitationToken?: InputMaybe<Scalars['String']>;
  invoicingContact?: InputMaybe<PersonInput>;
  name: Scalars['String'];
  organizationTypes: Array<Scalars['String']>;
};

export type FlorgInvitation = {
  __typename?: 'FlorgInvitation';
  acceptanceTime?: Maybe<Scalars['LocalDateTime']>;
  accepted: Scalars['Boolean'];
  contactPerson: Person;
  emailStatus?: Maybe<EmailStatus>;
  inviteLink: Scalars['String'];
  invitedOrganizationType?: Maybe<Scalars['String']>;
  inviterOrganizationType?: Maybe<Scalars['String']>;
  inviterRelationRole?: Maybe<RelationRole>;
  name: Scalars['String'];
  pid: Scalars['ID'];
  receivingTime?: Maybe<Scalars['LocalDateTime']>;
  sendingCompanyName: Scalars['String'];
  sendingPerson?: Maybe<Person>;
  sendingTime: Scalars['LocalDateTime'];
  valid: Scalars['Boolean'];
};

export type FlorgInvitationInput = {
  contactPerson: PersonInput;
  invitedFlorgType: Scalars['String'];
  inviterFlorgType: Scalars['String'];
  inviterRelationRole: RelationRole;
  message?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/**
 *
 * A reference to a Fleet organization
 */
export type FlorgRef = {
  __typename?: 'FlorgRef';
  apiName?: Maybe<Scalars['String']>;
  connectionType?: Maybe<FlorgConnectionType>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FlorgSustainabilityScoreInput = {
  score: Scalars['Int'];
  threshold: Scalars['Float'];
};

export type FlorgWithRoles = {
  __typename?: 'FlorgWithRoles';
  florg: Florg;
  roles: Array<Role>;
};

export type FlorgWithRolesInput = {
  florgPid: Scalars['String'];
  rolePids: Array<Scalars['String']>;
};

/**
 *
 * Enum of the fuel qualities
 */
export enum FuelQuality {
  B0 = 'B0',
  B7 = 'B7',
  B30 = 'B30',
  B100 = 'B100',
  BioLngB7 = 'BioLNG_B7',
  BioLngHvo = 'BioLNG_HVO',
  Electric = 'Electric',
  Hvo = 'HVO',
  Hvo100 = 'HVO100',
  LngB7 = 'LNG_B7',
  LngHvo = 'LNG_HVO',
  Standard = 'Standard',
}

/**
 *
 * A type of fuel
 */
export type FuelType = {
  __typename?: 'FuelType';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type GeoTabGroupData = {
  __typename?: 'GeoTabGroupData';
  account: Scalars['String'];
  florgPid: Scalars['String'];
  name: Scalars['String'];
  pid: Scalars['String'];
};

export type GeoTabGroupInput = {
  name: Scalars['String'];
  pid?: InputMaybe<Scalars['String']>;
};

export type GhgReportData = {
  __typename?: 'GhgReportData';
  carrierName: Scalars['String'];
  co2TTW?: Maybe<Scalars['Float']>;
  co2Usage?: Maybe<Scalars['Float']>;
  co2WTT?: Maybe<Scalars['Float']>;
  co2WTW?: Maybe<Scalars['Float']>;
  coUsage?: Maybe<Scalars['Float']>;
  delayInMin?: Maybe<Scalars['Float']>;
  estimated?: Maybe<Scalars['Boolean']>;
  fromTime: Scalars['LocalDateTime'];
  fuelUsedInLitres?: Maybe<Scalars['Float']>;
  goodsDescription: Scalars['String'];
  hcUsage?: Maybe<Scalars['Float']>;
  noxUsage?: Maybe<Scalars['Float']>;
  percentageOfCargo?: Maybe<Scalars['Float']>;
  pmUsage?: Maybe<Scalars['Float']>;
  productNumber: Scalars['String'];
  routeName?: Maybe<Scalars['String']>;
  so2Usage?: Maybe<Scalars['Float']>;
  toTime: Scalars['LocalDateTime'];
  transportBuyerName: Scalars['String'];
  transportNumber: Scalars['String'];
  transportOrderNumber: Scalars['String'];
  vehicleName: Scalars['String'];
};

/**
 *
 * The filters used when retrieving the GHG report data
 */
export type GhgReportFiltersInput = {
  carrierPids?: InputMaybe<Array<Scalars['String']>>;
  fromTime: Scalars['String'];
  organizationType: Scalars['String'];
  pointOfSaleIds?: InputMaybe<Array<Scalars['ID']>>;
  products?: InputMaybe<Array<GhgReportProductInput>>;
  routeDestinations: Array<Scalars['String']>;
  routeStarts: Array<Scalars['String']>;
  routes?: InputMaybe<Array<GhgReportRouteInput>>;
  toTime: Scalars['String'];
  transportBuyerPids?: InputMaybe<Array<Scalars['String']>>;
  transportNumbers?: InputMaybe<Array<Scalars['String']>>;
  transportOrderNumbers?: InputMaybe<Array<Scalars['String']>>;
  vehiclePids?: InputMaybe<Array<Scalars['String']>>;
};

export type GhgReportProductInput = {
  goodsType: Scalars['String'];
  productNumber: Scalars['String'];
};

export type GhgReportRouteInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type GhgReportTransportData = {
  __typename?: 'GhgReportTransportData';
  carrierName?: Maybe<Scalars['String']>;
  co2TTW?: Maybe<Scalars['Float']>;
  co2WTT?: Maybe<Scalars['Float']>;
  co2WTW?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  estimated?: Maybe<Scalars['Boolean']>;
  from: Scalars['String'];
  fromTime: Scalars['LocalDateTime'];
  fuel?: Maybe<Scalars['Float']>;
  orderNumbers: Array<Scalars['String']>;
  sustainabilityScore?: Maybe<Scalars['Int']>;
  to: Scalars['String'];
  toTime: Scalars['LocalDateTime'];
  transportMode: TransportMode;
  transportNumber: Scalars['String'];
  transportPid: Scalars['ID'];
  trueTransparency?: Maybe<Scalars['Float']>;
  vehicleFlorgName?: Maybe<Scalars['String']>;
  vehicleName?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type GhgReportTransportOrderData = {
  __typename?: 'GhgReportTransportOrderData';
  carrierName?: Maybe<Scalars['String']>;
  co2TTW?: Maybe<Scalars['Float']>;
  co2WTT?: Maybe<Scalars['Float']>;
  co2WTW?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  estimated?: Maybe<Scalars['Boolean']>;
  from: Scalars['String'];
  fromTime: Scalars['LocalDateTime'];
  sustainabilityScore?: Maybe<Scalars['Int']>;
  to: Scalars['String'];
  toTime: Scalars['LocalDateTime'];
  transportIds: Array<Scalars['String']>;
  transportModes: Array<TransportMode>;
  transportOrderId: Scalars['String'];
  transportOrderNumber: Scalars['String'];
  trueTransparency?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type GoodsVolumeBusinessType = {
  __typename?: 'GoodsVolumeBusinessType';
  businessType?: Maybe<BusinessType>;
  item?: Maybe<GoodsVolumeWidget>;
};

export type GoodsVolumeWidget = {
  __typename?: 'GoodsVolumeWidget';
  lastMile?: Maybe<Scalars['Int']>;
  lineHaul?: Maybe<Scalars['Int']>;
  periodDifference?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
};

export type ItemsTransportedBusinessType = {
  __typename?: 'ItemsTransportedBusinessType';
  businessType?: Maybe<BusinessType>;
  item?: Maybe<ItemsTransportedWidget>;
};

export type ItemsTransportedWidget = {
  __typename?: 'ItemsTransportedWidget';
  lastMile?: Maybe<Scalars['Int']>;
  lineHaul?: Maybe<Scalars['Int']>;
  periodDifference?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
};

export enum LoadCharacteristic {
  AverageMixed = 'AVERAGE_MIXED',
  Container = 'CONTAINER',
  Heavy = 'HEAVY',
  Light = 'LIGHT',
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptConnectionsToFlorg: Array<FlorgRef>;
  acceptReceivedFlorgInvitations: Array<FlorgInvitation>;
  changeActiveFlorg: Florg;
  createB2BClient?: Maybe<B2BClient>;
  createFlorgInvitation?: Maybe<FlorgInvitation>;
  createSustainabilityScoresForFlorg: Array<SustainabilityScoreData>;
  createSystemSettingForTest?: Maybe<SystemSetting>;
  createUser?: Maybe<User>;
  deleteB2BClient: DeleteResponse;
  deleteConnectionsToFlorg?: Maybe<DeleteResponse>;
  deleteFlorg?: Maybe<DeleteResponse>;
  deletePointOfInterest?: Maybe<DeleteResponse>;
  deleteReceivedFlorgInvitations?: Maybe<DeleteResponse>;
  deleteRoute?: Maybe<DeleteResponse>;
  deleteSentFlorgInvitations?: Maybe<DeleteResponse>;
  deleteTransport?: Maybe<DeleteResponse>;
  deleteTransportOrder?: Maybe<DeleteResponse>;
  deleteUser?: Maybe<Scalars['String']>;
  deleteVehicles?: Maybe<DeleteResponse>;
  grantAccessToFlorg?: Maybe<Scalars['String']>;
  receiveFlorgInvitation?: Maybe<FlorgInvitation>;
  resendFlorgInvitation?: Maybe<FlorgInvitation>;
  storeGeoTabGroup?: Maybe<GeoTabGroupData>;
  storeRoute?: Maybe<Route>;
  storeScaniaUser?: Maybe<RfmsUserData>;
  storeTransport?: Maybe<Transport>;
  storeTransportOrder?: Maybe<TransportOrder>;
  storeTransportOrders: Array<TransportOrder>;
  storeVehicle: Vehicle;
  storeVehicleStaticParameters?: Maybe<ElectricVehicle>;
  storeVolvoUser?: Maybe<RfmsUserData>;
  updateFlorgApiNames: Array<FlorgRef>;
};

export type MutationAcceptConnectionsToFlorgArgs = {
  pids: Array<Scalars['ID']>;
};

export type MutationAcceptReceivedFlorgInvitationsArgs = {
  pids: Array<Scalars['ID']>;
};

export type MutationChangeActiveFlorgArgs = {
  florgPid: Scalars['String'];
};

export type MutationCreateB2BClientArgs = {
  clientName: Scalars['String'];
};

export type MutationCreateFlorgInvitationArgs = {
  invitation: FlorgInvitationInput;
};

export type MutationCreateSustainabilityScoresForFlorgArgs = {
  scores: Array<FlorgSustainabilityScoreInput>;
};

export type MutationCreateSystemSettingForTestArgs = {
  name: Scalars['String'];
};

export type MutationCreateUserArgs = {
  userInput: UserInput;
};

export type MutationDeleteB2BClientArgs = {
  clientId: Scalars['String'];
};

export type MutationDeleteConnectionsToFlorgArgs = {
  pids: Array<Scalars['ID']>;
};

export type MutationDeleteFlorgArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePointOfInterestArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteReceivedFlorgInvitationsArgs = {
  pids: Array<Scalars['ID']>;
};

export type MutationDeleteRouteArgs = {
  pid: Scalars['String'];
};

export type MutationDeleteSentFlorgInvitationsArgs = {
  pids: Array<Scalars['ID']>;
};

export type MutationDeleteTransportArgs = {
  pid: Scalars['ID'];
};

export type MutationDeleteTransportOrderArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
  deleteUserInput: DeleteUserInput;
};

export type MutationDeleteVehiclesArgs = {
  pids: Array<Scalars['String']>;
};

export type MutationGrantAccessToFlorgArgs = {
  ownerFlorgPid: Scalars['String'];
  receiverFlorgPids: Array<InputMaybe<Scalars['String']>>;
};

export type MutationReceiveFlorgInvitationArgs = {
  token: Scalars['String'];
};

export type MutationResendFlorgInvitationArgs = {
  pid: Scalars['ID'];
};

export type MutationStoreGeoTabGroupArgs = {
  group: GeoTabGroupInput;
};

export type MutationStoreRouteArgs = {
  route: RouteInput;
};

export type MutationStoreScaniaUserArgs = {
  storeUserRequest: StoreScaniaRfmsUserInput;
};

export type MutationStoreTransportArgs = {
  transport: TransportInput;
};

export type MutationStoreTransportOrderArgs = {
  transportOrder: TransportOrderInput;
};

export type MutationStoreTransportOrdersArgs = {
  transportOrders: Array<TransportOrderInput>;
};

export type MutationStoreVehicleArgs = {
  vehicle: VehicleInput;
};

export type MutationStoreVehicleStaticParametersArgs = {
  electricVehicleInput?: InputMaybe<ElectricVehicleInput>;
};

export type MutationStoreVolvoUserArgs = {
  storeUserRequest: StoreVolvoRfmsUserInput;
};

export type MutationUpdateFlorgApiNamesArgs = {
  apiNameInputs: Array<FlorgApiNameInput>;
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
};

export type OrganizationTypeWithRoles = {
  __typename?: 'OrganizationTypeWithRoles';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  roles: Array<Role>;
};

export type OrganizationTypeWithRolesInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  key: Scalars['String'];
  rolePids: Array<Scalars['String']>;
};

/**
 *
 * Enum of the possible owner types
 */
export enum OwnerType {
  Florg = 'FLORG',
  User = 'USER',
}

export type Person = {
  __typename?: 'Person';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type PersonInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type PointOfInterest = {
  __typename?: 'PointOfInterest';
  address?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type PointOfInterestInput = {
  __typename?: 'PointOfInterestInput';
  address?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type PreviousPeriod = {
  __typename?: 'PreviousPeriod';
  from: Scalars['LocalDateTime'];
  to: Scalars['LocalDateTime'];
};

export type Product = {
  __typename?: 'Product';
  goodsType: Scalars['String'];
  productNumber: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getAllFlorgsForCurrentUser: Array<Florg>;
  getAllRoles: Array<Role>;
  getAllTelematicTypes: Array<TelematicTypeResponse>;
  getAllUsersForCurrentFlorg: Array<UserInFlorg>;
  getApplicationContext?: Maybe<ApplicationContext>;
  getAssignedTransportOrders: Array<AssignedTransportOrder>;
  getB2BClients: Array<B2BClient>;
  getBusinessTypes?: Maybe<Array<BusinessType>>;
  getConnectedFlorg?: Maybe<Florg>;
  getConnectedFlorgs: Array<FlorgRef>;
  getDashboard: DashboardData;
  getDistanceForRoute?: Maybe<Scalars['Float']>;
  getEnvironmentalClasses: Array<EnvironmentalClass>;
  getFlorgInvitationByPid?: Maybe<FlorgInvitation>;
  getFlorgSuggestions: Array<Scalars['String']>;
  getFuelTypes: Array<FuelType>;
  getGeoTabGroups: Array<GeoTabGroupData>;
  getGhgReportDataForCarrier: Array<GhgReportData>;
  getGhgReportDataForTransportBuyer: Array<GhgReportData>;
  getGhgReportTransportDataForCarrier: Array<GhgReportTransportData>;
  getGhgReportTransportDataForTransportBuyer: Array<GhgReportTransportData>;
  getGhgReportTransportOrderDataForCarrier: Array<GhgReportTransportOrderData>;
  getGhgReportTransportOrderDataForTransportBuyer: Array<GhgReportTransportOrderData>;
  getProductsForAssignedTransportOrders: Array<Product>;
  getProductsFromOrderItems: Array<Product>;
  getReceivedFlorgInvitations: Array<FlorgInvitation>;
  getReportRoutes: Array<Route>;
  getRoute: Route;
  getRoutes: Array<Route>;
  getScaniaUsers: Array<RfmsUserData>;
  getSentFlorgInvitations: Array<FlorgInvitation>;
  getSustainabilityScoresForFlorg: Array<SustainabilityScoreData>;
  getTelematicSuggestions: Array<TelematicTypeSuggestion>;
  getTransport?: Maybe<Transport>;
  getTransportOrder?: Maybe<TransportOrder>;
  getTransportOrderItems: Array<TransportOrderItem>;
  getTransportOrders: Array<TransportOrderListItem>;
  getTransports: Array<Transport>;
  getUserByPidForCurrentFlorg?: Maybe<UserInFlorg>;
  getVehicleBrands: Array<VehicleBrand>;
  getVehicleDataFromVin?: Maybe<VehicleDataFromVin>;
  getVolvoUsers: Array<RfmsUserData>;
  test?: Maybe<Scalars['String']>;
  vehicle: Vehicle;
  /**
   *
   * Gets vehicles for the user's florg.
   */
  vehicles: Array<VehicleListItem>;
  vehiclesWithSubcarriers: Array<VehicleListItem>;
};

export type QueryGetAssignedTransportOrdersArgs = {
  filter?: InputMaybe<SearchFilterInput>;
};

export type QueryGetConnectedFlorgArgs = {
  pid: Scalars['ID'];
};

export type QueryGetDashboardArgs = {
  dashboardInput: DashboardInput;
};

export type QueryGetDistanceForRouteArgs = {
  pid: Scalars['String'];
  transportMode: TransportMode;
};

export type QueryGetFlorgInvitationByPidArgs = {
  pid: Scalars['ID'];
};

export type QueryGetFlorgSuggestionsArgs = {
  florgType: Scalars['String'];
};

export type QueryGetGhgReportDataForCarrierArgs = {
  filters?: InputMaybe<GhgReportFiltersInput>;
};

export type QueryGetGhgReportDataForTransportBuyerArgs = {
  filters?: InputMaybe<GhgReportFiltersInput>;
};

export type QueryGetGhgReportTransportDataForCarrierArgs = {
  filters?: InputMaybe<GhgReportFiltersInput>;
};

export type QueryGetGhgReportTransportDataForTransportBuyerArgs = {
  filters?: InputMaybe<GhgReportFiltersInput>;
};

export type QueryGetGhgReportTransportOrderDataForCarrierArgs = {
  filters?: InputMaybe<GhgReportFiltersInput>;
};

export type QueryGetGhgReportTransportOrderDataForTransportBuyerArgs = {
  filters?: InputMaybe<GhgReportFiltersInput>;
};

export type QueryGetProductsForAssignedTransportOrdersArgs = {
  filter?: InputMaybe<SearchFilterInput>;
};

export type QueryGetProductsFromOrderItemsArgs = {
  filter?: InputMaybe<SearchFilterInput>;
};

export type QueryGetReceivedFlorgInvitationsArgs = {
  florgType: Scalars['String'];
};

export type QueryGetRouteArgs = {
  pid: Scalars['String'];
};

export type QueryGetRoutesArgs = {
  filter?: InputMaybe<RouteFilterInput>;
};

export type QueryGetSentFlorgInvitationsArgs = {
  florgType: Scalars['String'];
};

export type QueryGetTelematicSuggestionsArgs = {
  telematicSuggestionInputs: Array<TelematicSuggestionInput>;
};

export type QueryGetTransportArgs = {
  buyerPid?: InputMaybe<Scalars['String']>;
  pid: Scalars['ID'];
};

export type QueryGetTransportOrderArgs = {
  carrierPid?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type QueryGetTransportOrdersArgs = {
  filter?: InputMaybe<SearchFilterInput>;
};

export type QueryGetTransportsArgs = {
  filter?: InputMaybe<SearchFilterInput>;
};

export type QueryGetUserByPidForCurrentFlorgArgs = {
  pid: Scalars['String'];
};

export type QueryGetVehicleDataFromVinArgs = {
  vin: Scalars['String'];
};

export type QueryVehicleArgs = {
  pid: Scalars['ID'];
};

export type QueryVehiclesArgs = {
  filter?: InputMaybe<VehiclesFilterInput>;
};

export enum RelationRole {
  Child = 'CHILD',
  Parent = 'PARENT',
}

export type RfmsUserData = {
  __typename?: 'RfmsUserData';
  name: Scalars['String'];
  pid: Scalars['String'];
};

export type Right = {
  __typename?: 'Right';
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  pid: Scalars['String'];
};

export type RightInput = {
  description?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  pid?: InputMaybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  pid: Scalars['String'];
  rights: Array<Right>;
};

export type RoleInput = {
  description?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  pid?: InputMaybe<Scalars['String']>;
  rightPids: Array<Scalars['String']>;
};

/**
 *
 * A Route
 */
export type Route = {
  __typename?: 'Route';
  from?: Maybe<PointOfInterest>;
  label?: Maybe<Scalars['String']>;
  pid: Scalars['String'];
  to?: Maybe<PointOfInterest>;
  waypoints?: Maybe<Array<PointOfInterest>>;
};

/**
 *
 * Input to get paginated routes filtered by fields from and to
 */
export type RouteFilterInput = {
  from?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  size: Scalars['Int'];
  to?: InputMaybe<Scalars['String']>;
};

/**
 *
 * A Route input object
 */
export type RouteInput = {
  from: Scalars['String'];
  label?: InputMaybe<Scalars['String']>;
  pid?: InputMaybe<Scalars['String']>;
  to: Scalars['String'];
  waypoints?: InputMaybe<Array<Scalars['String']>>;
};

export type SearchFilterInput = {
  page: Scalars['Int'];
  searchPattern?: InputMaybe<Scalars['String']>;
  size: Scalars['Int'];
};

export type StoreScaniaRfmsUserInput = {
  clientId: Scalars['String'];
  name: Scalars['String'];
  pid?: InputMaybe<Scalars['String']>;
  secret: Scalars['String'];
};

export type StoreVolvoRfmsUserInput = {
  name: Scalars['String'];
  password: Scalars['String'];
  pid?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type SustainabilityScoreData = {
  __typename?: 'SustainabilityScoreData';
  florgPid?: Maybe<Scalars['String']>;
  score: Scalars['Int'];
  threshold: Scalars['Float'];
};

/**
 *
 * The Fleet organization owning the vehicles we are operating on, users working for the fleet are also referenced through the fleet context.
 */
export type SystemSetting = {
  __typename?: 'SystemSetting';
  id: Scalars['ID'];
  onDemandServiceDatasetName?: Maybe<Scalars['String']>;
};

export enum TargetType {
  Emission = 'EMISSION',
  Intensity = 'INTENSITY',
}

export type TargetsMetric = {
  __typename?: 'TargetsMetric';
  current: Scalars['Float'];
  date?: Maybe<Scalars['LocalDate']>;
  target: Scalars['Float'];
};

export type TargetsWidget = {
  __typename?: 'TargetsWidget';
  current: Scalars['Float'];
  goal: Scalars['Float'];
  metrics?: Maybe<Array<Maybe<TargetsMetric>>>;
  periodDifference?: Maybe<Scalars['Float']>;
  type: TargetType;
};

export enum TelematicStatus {
  Activated = 'ACTIVATED',
  Activating = 'ACTIVATING',
  Deactivated = 'DEACTIVATED',
  Error = 'ERROR',
  Registered = 'REGISTERED',
  Unknown = 'UNKNOWN',
}

export type TelematicSuggestionInput = {
  name: Scalars['String'];
  vehicleBrandId: Scalars['ID'];
  vin?: InputMaybe<Scalars['String']>;
  year: Scalars['Int'];
};

/**
 *
 * Enum of the Telematic unit types
 */
export enum TelematicType {
  Geotab = 'GEOTAB',
  HighMobility = 'HIGH_MOBILITY',
  Manual = 'MANUAL',
  Scania = 'SCANIA',
  Tgw = 'TGW',
  Volvo = 'VOLVO',
}

export type TelematicTypeInput = {
  label?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  telematicType?: InputMaybe<TelematicType>;
};

export type TelematicTypeResponse = {
  __typename?: 'TelematicTypeResponse';
  label: Scalars['String'];
  priority: Scalars['Int'];
  telematicType: TelematicType;
};

export type TelematicTypeSuggestion = {
  __typename?: 'TelematicTypeSuggestion';
  name: Scalars['String'];
  suggestions: Array<TelematicTypeResponse>;
};

/**
 *
 * The transport requested to be transported by the end customer
 */
export type Transport = {
  __typename?: 'Transport';
  co2e?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['LocalDateTime']>;
  energyUsedInKwh?: Maybe<Scalars['Float']>;
  fuelQuality: FuelQuality;
  kmDriven?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  mode?: Maybe<TransportMode>;
  pid: Scalars['String'];
  route?: Maybe<Route>;
  startTime?: Maybe<Scalars['LocalDateTime']>;
  sustainabilityScore?: Maybe<Scalars['Int']>;
  totalCargoWeight?: Maybe<Scalars['Float']>;
  totalGoodsVolume?: Maybe<Scalars['Float']>;
  transportNumber: Scalars['String'];
  transportOrders?: Maybe<Array<TransportOrder>>;
  trueTransparency?: Maybe<Scalars['Float']>;
  usedFuelInLitres?: Maybe<Scalars['Float']>;
  vehicle?: Maybe<Vehicle>;
};

/**
 *
 * The transport order requested to be transported by the end customer
 */
export type TransportInput = {
  assignedVehicleId?: InputMaybe<Scalars['String']>;
  completedTransportOrderPids?: InputMaybe<Array<Scalars['String']>>;
  endTime?: InputMaybe<Scalars['String']>;
  energyUsedInKwh?: InputMaybe<Scalars['Float']>;
  from?: InputMaybe<Scalars['String']>;
  fuelQuality: FuelQuality;
  kmDriven?: InputMaybe<Scalars['Float']>;
  label?: InputMaybe<Scalars['String']>;
  pid?: InputMaybe<Scalars['String']>;
  routeLabel?: InputMaybe<Scalars['String']>;
  routePid?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  totalCargoWeight?: InputMaybe<Scalars['Float']>;
  totalGoodsVolume?: InputMaybe<Scalars['Float']>;
  transportNumber: Scalars['String'];
  transportOrderPids?: InputMaybe<Array<Scalars['String']>>;
  transportOrderVolumes?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  usedFuelInLitres?: InputMaybe<Scalars['Float']>;
  waypoints?: InputMaybe<Array<Scalars['String']>>;
};

/**
 *
 * A type of Transport Item
 */
export type TransportItemType = {
  __typename?: 'TransportItemType';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export enum TransportMode {
  Air = 'AIR',
  Rail = 'RAIL',
  Road = 'ROAD',
  Water = 'WATER',
}

/**
 *
 * The transport order requested to be transported by the end customer
 */
export type TransportOrder = {
  __typename?: 'TransportOrder';
  assignedCarriers?: Maybe<Array<FlorgRef>>;
  businessType: BusinessType;
  co2e?: Maybe<Scalars['Float']>;
  completed?: Maybe<Scalars['Boolean']>;
  creationTime?: Maybe<Scalars['LocalDateTime']>;
  id: Scalars['ID'];
  orderItems?: Maybe<Array<TransportOrderItem>>;
  transportOrderNumber: Scalars['String'];
  pid: Scalars['String'];
  route?: Maybe<Route>;
  serviceLevel?: Maybe<Scalars['String']>;
  shipmentTag?: Maybe<Scalars['String']>;
  totalVolumeInCd3?: Maybe<Scalars['Float']>;
  totalWeightInKg?: Maybe<Scalars['Float']>;
  transportItemType?: Maybe<TransportItemType>;
  transports?: Maybe<Array<Transport>>;
  trueTransparency?: Maybe<Scalars['Float']>;
  updateTime?: Maybe<Scalars['LocalDateTime']>;
  wantedArrivalTime?: Maybe<Scalars['LocalDateTime']>;
  wantedPickupTime?: Maybe<Scalars['LocalDateTime']>;
};

/**
 *
 * The transport order requested to be transported by the end customer
 */
export type TransportOrderInput = {
  assignedCarrierPids?: InputMaybe<Array<Scalars['String']>>;
  businessType?: InputMaybe<BusinessType>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  orderItems?: InputMaybe<Array<TransportOrderItemInput>>;
  transportOrderNumber: Scalars['String'];
  pid?: InputMaybe<Scalars['String']>;
  replaceAssignedCarriers?: InputMaybe<Scalars['Boolean']>;
  routePid?: InputMaybe<Scalars['String']>;
  serviceLevel?: InputMaybe<Scalars['String']>;
  shipmentTag?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  totalVolumeInCd3?: InputMaybe<Scalars['Float']>;
  totalWeightInKg?: InputMaybe<Scalars['Float']>;
  transportItemTypeId?: InputMaybe<Scalars['String']>;
  wantedArrivalTime: Scalars['String'];
  wantedPickupTime?: InputMaybe<Scalars['String']>;
};

/**
 *
 * The transport order requested to be transported by the end customer
 */
export type TransportOrderItem = {
  __typename?: 'TransportOrderItem';
  brand?: Maybe<Scalars['String']>;
  goodsType: Scalars['String'];
  goodsVolume?: Maybe<Scalars['Float']>;
  goodsWeight: Scalars['Float'];
  id: Scalars['ID'];
  noOfItems: Scalars['Int'];
  orderReference?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  totalVolumeInCd3?: Maybe<Scalars['Float']>;
  totalWeightInKg: Scalars['Float'];
  transportItemType?: Maybe<TransportItemType>;
};

/**
 *
 * The transport order items
 */
export type TransportOrderItemInput = {
  brand?: InputMaybe<Scalars['String']>;
  goodsType: Scalars['String'];
  goodsVolume?: InputMaybe<Scalars['Float']>;
  goodsWeight: Scalars['Float'];
  id?: InputMaybe<Scalars['ID']>;
  noOfItems: Scalars['Int'];
  orderReference?: InputMaybe<Scalars['String']>;
  productNumber?: InputMaybe<Scalars['String']>;
  totalVolumeInCd3?: InputMaybe<Scalars['Float']>;
  totalWeightInKg: Scalars['Float'];
  transportItemTypeId?: InputMaybe<Scalars['String']>;
};

/**
 *
 * Partial data of TransportOrder, to improve fetching performance
 */
export type TransportOrderListItem = {
  __typename?: 'TransportOrderListItem';
  creationTime?: Maybe<Scalars['LocalDateTime']>;
  id: Scalars['ID'];
  transportOrderNumber: Scalars['String'];
  pid: Scalars['String'];
  totalVolumeInCd3?: Maybe<Scalars['Float']>;
  totalWeightInKg?: Maybe<Scalars['Float']>;
  updateTime?: Maybe<Scalars['LocalDateTime']>;
};

export type TransportsBusinessType = {
  __typename?: 'TransportsBusinessType';
  businessType?: Maybe<BusinessType>;
  item?: Maybe<TransportsWidget>;
};

export type TransportsWidget = {
  __typename?: 'TransportsWidget';
  lastMile?: Maybe<Scalars['Int']>;
  lineHaul?: Maybe<Scalars['Int']>;
  periodDifference?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
};

export type UpdateDashboardRightInput = {
  rightPid: Scalars['String'];
  widgetType: DashboardWidgetType;
};

/**
 *
 * A user in the system
 */
export type User = {
  __typename?: 'User';
  florgsWithRoles: Array<FlorgWithRoles>;
  id: Scalars['ID'];
  name: Scalars['String'];
  selectedVehicle?: Maybe<Vehicle>;
  userConsentAccepted?: Maybe<Scalars['Boolean']>;
};

export type UserInFlorg = {
  __typename?: 'UserInFlorg';
  id: Scalars['ID'];
  name: Scalars['String'];
  roles: Array<Role>;
};

export type UserInput = {
  email: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  roles: Array<Scalars['String']>;
};

export type UserOrganizationRoleInput = {
  florgWithRoles: Array<FlorgWithRolesInput>;
  userPid: Scalars['String'];
};

/**
 *
 * The static information of a vehicle
 */
export type Vehicle = {
  __typename?: 'Vehicle';
  energyMix?: Maybe<EnergyMix>;
  environmentClass?: Maybe<EnvironmentalClass>;
  fuelType?: Maybe<FuelType>;
  grossVehicleWeight?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pid: Scalars['String'];
  telematicStatus?: Maybe<TelematicStatus>;
  telematicUnitId?: Maybe<Scalars['String']>;
  telematicUnitType?: Maybe<TelematicType>;
  tispVehiclePid: Scalars['String'];
  type?: Maybe<VehicleCharacteristic>;
  vehicleBrand?: Maybe<VehicleBrand>;
  vehicleStatus?: Maybe<Array<VehicleStatus>>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type VehicleBrand = {
  __typename?: 'VehicleBrand';
  common: Scalars['Boolean'];
  id: Scalars['ID'];
  label: Scalars['String'];
};

export enum VehicleCharacteristic {
  ArticulatedTruck = 'ARTICULATED_TRUCK',
  RigidTruck = 'RIGID_TRUCK',
  Train = 'TRAIN',
  Van = 'VAN',
}

export type VehicleDataFromVin = {
  __typename?: 'VehicleDataFromVin';
  brandId?: Maybe<Scalars['Int']>;
  checkDigit?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  plantCode?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
};

/**
 *
 * Vehicle
 */
export type VehicleInput = {
  energyMix?: InputMaybe<EnergyMix>;
  environmentClassId?: InputMaybe<Scalars['ID']>;
  fuelTypeId: Scalars['ID'];
  grossVehicleWeight?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  odometer?: InputMaybe<Scalars['Int']>;
  pid?: InputMaybe<Scalars['String']>;
  tankCapacity?: InputMaybe<Scalars['Float']>;
  telematicAccount?: InputMaybe<Scalars['String']>;
  telematicDescription?: InputMaybe<Scalars['String']>;
  telematicGroupPid?: InputMaybe<Scalars['String']>;
  telematicUnitId?: InputMaybe<Scalars['String']>;
  telematicUnitType?: InputMaybe<TelematicType>;
  tispVehiclePid?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<VehicleCharacteristic>;
  vehicleBrandId?: InputMaybe<Scalars['ID']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

/**
 *
 * The static information of a vehicle
 */
export type VehicleListItem = {
  __typename?: 'VehicleListItem';
  fuelType?: Maybe<FuelType>;
  grossVehicleWeight?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  pid: Scalars['String'];
  telematicStatus?: Maybe<TelematicStatus>;
  telematicUnitType?: Maybe<TelematicType>;
  tispVehiclePid: Scalars['String'];
  type?: Maybe<VehicleCharacteristic>;
  vehicleBrand?: Maybe<VehicleBrand>;
  vin?: Maybe<Scalars['String']>;
};

/**
 *
 * The dynamic information regarding the status of the vehicle
 */
export type VehicleStatus = {
  __typename?: 'VehicleStatus';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  positionTime?: Maybe<Scalars['LocalDateTime']>;
  soc?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
};

export type VehiclesFilterInput = {
  brand?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fuelType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageFilter?: InputMaybe<SearchFilterInput>;
  telematicStatus?: InputMaybe<Array<InputMaybe<TelematicStatus>>>;
  telematicType?: InputMaybe<Array<InputMaybe<TelematicType>>>;
};

export type Widgets = {
  __typename?: 'Widgets';
  co2e?: Maybe<Array<Maybe<Co2eBusinessType>>>;
  engineMix?: Maybe<Array<Maybe<EngineMixBusinessType>>>;
  goodsVolume?: Maybe<Array<Maybe<GoodsVolumeBusinessType>>>;
  itemsTransported?: Maybe<Array<Maybe<ItemsTransportedBusinessType>>>;
  targets?: Maybe<Array<Maybe<TargetsWidget>>>;
  transports?: Maybe<Array<Maybe<TransportsBusinessType>>>;
};

export type ApplicationContextQueryVariables = Exact<{ [key: string]: never }>;

export type ApplicationContextQuery = {
  __typename?: 'Query';
  getApplicationContext?: {
    __typename?: 'ApplicationContext';
    userRights: Array<string>;
    activeUser: { __typename?: 'User'; id: string; name: string };
    activeFlorg: { __typename?: 'Florg'; id?: string | null; name: string };
    systemSetting?: { __typename?: 'SystemSetting'; onDemandServiceDatasetName?: string | null } | null;
    fuelTypes: Array<{ __typename?: 'FuelType'; id: string; label: string }>;
    transportItemTypes: Array<{ __typename?: 'TransportItemType'; id: string; label: string }>;
    vehicleBrands: Array<{ __typename?: 'VehicleBrand'; id: string; label: string }>;
    environmentalClasses: Array<{ __typename?: 'EnvironmentalClass'; id: string; label: string }>;
  } | null;
};

export type GetAssignedTransportOrdersQueryVariables = Exact<{
  filter?: InputMaybe<SearchFilterInput>;
}>;

export type GetAssignedTransportOrdersQuery = {
  __typename?: 'Query';
  getAssignedTransportOrders: Array<{
    __typename?: 'AssignedTransportOrder';
    id: string;
    completedTime?: any | null;
    assignedFlorg: { __typename?: 'FlorgRef'; id: string; name: string };
    assigningFlorg: { __typename?: 'FlorgRef'; id: string; name: string };
    assignedOrder: {
      __typename?: 'TransportOrder';
      id: string;
      pid: string;
      transportOrderNumber: string;
      wantedPickupTime?: any | null;
      wantedArrivalTime?: any | null;
      orderItems?: Array<{
        __typename?: 'TransportOrderItem';
        id: string;
        goodsWeight: number;
        goodsType: string;
        noOfItems: number;
        totalWeightInKg: number;
        productNumber?: string | null;
        transportItemType?: { __typename?: 'TransportItemType'; id: string; label: string } | null;
      }> | null;
      transports?: Array<{
        __typename?: 'Transport';
        pid: string;
        transportNumber: string;
        startTime?: any | null;
        endTime?: any | null;
        totalCargoWeight?: number | null;
        kmDriven?: number | null;
        usedFuelInLitres?: number | null;
        energyUsedInKwh?: number | null;
        vehicle?: {
          __typename?: 'Vehicle';
          pid: string;
          name?: string | null;
          vin?: string | null;
          vehicleBrand?: { __typename?: 'VehicleBrand'; id: string; label: string } | null;
          fuelType?: { __typename?: 'FuelType'; id: string; label: string } | null;
        } | null;
      }> | null;
      assignedCarriers?: Array<{ __typename?: 'FlorgRef'; id: string; name: string }> | null;
      route?: { __typename?: 'Route'; pid: string } | null;
    };
  }>;
};

export type GetAssignedTransportOrdersForFilterQueryVariables = Exact<{
  filter?: InputMaybe<SearchFilterInput>;
}>;

export type GetAssignedTransportOrdersForFilterQuery = {
  __typename?: 'Query';
  getAssignedTransportOrders: Array<{
    __typename?: 'AssignedTransportOrder';
    assignedFlorg: { __typename?: 'FlorgRef'; id: string; name: string };
    assigningFlorg: { __typename?: 'FlorgRef'; id: string; name: string };
    assignedOrder: {
      __typename?: 'TransportOrder';
      transportOrderNumber: string;
      orderItems?: Array<{
        __typename?: 'TransportOrderItem';
        goodsType: string;
        productNumber?: string | null;
      }> | null;
    };
  }>;
};

export type GetB2BClientsQueryVariables = Exact<{ [key: string]: never }>;

export type GetB2BClientsQuery = {
  __typename?: 'Query';
  getB2BClients: Array<{ __typename?: 'B2BClient'; clientName: string; clientId: string; clientSecret: string }>;
};

export type CreateB2BClientMutationVariables = Exact<{
  clientName: Scalars['String'];
}>;

export type CreateB2BClientMutation = {
  __typename?: 'Mutation';
  createB2BClient?: { __typename?: 'B2BClient'; clientName: string; clientId: string; clientSecret: string } | null;
};

export type DeleteB2BClientMutationVariables = Exact<{
  clientId: Scalars['String'];
}>;

export type DeleteB2BClientMutation = {
  __typename?: 'Mutation';
  deleteB2BClient: {
    __typename?: 'DeleteResponse';
    status: DeleteStatus;
    errorReasons?: Array<{ __typename?: 'ErrorReasonTuple'; pid?: string | null; value?: string | null }> | null;
  };
};

export type GetBusinessTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetBusinessTypesQuery = { __typename?: 'Query'; getBusinessTypes?: Array<BusinessType> | null };

export type GetSentFlorgInvitationsQueryVariables = Exact<{
  florgType: Scalars['String'];
}>;

export type GetSentFlorgInvitationsQuery = {
  __typename?: 'Query';
  getSentFlorgInvitations: Array<{
    __typename?: 'FlorgInvitation';
    pid: string;
    inviteLink: string;
    name: string;
    accepted: boolean;
    sendingTime: any;
    acceptanceTime?: any | null;
    sendingCompanyName: string;
    valid: boolean;
    emailStatus?: EmailStatus | null;
    sendingPerson?: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    } | null;
    contactPerson: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    };
  }>;
};

export type GetReceivedFlorgInvitationsQueryVariables = Exact<{
  florgType: Scalars['String'];
}>;

export type GetReceivedFlorgInvitationsQuery = {
  __typename?: 'Query';
  getReceivedFlorgInvitations: Array<{
    __typename?: 'FlorgInvitation';
    pid: string;
    inviteLink: string;
    name: string;
    accepted: boolean;
    sendingTime: any;
    receivingTime?: any | null;
    acceptanceTime?: any | null;
    sendingCompanyName: string;
    valid: boolean;
    emailStatus?: EmailStatus | null;
    inviterOrganizationType?: string | null;
    invitedOrganizationType?: string | null;
    inviterRelationRole?: RelationRole | null;
    sendingPerson?: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    } | null;
    contactPerson: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    };
  }>;
};

export type GetFlorgInvitationByPidQueryVariables = Exact<{
  pid: Scalars['ID'];
}>;

export type GetFlorgInvitationByPidQuery = {
  __typename?: 'Query';
  getFlorgInvitationByPid?: {
    __typename?: 'FlorgInvitation';
    pid: string;
    inviteLink: string;
    name: string;
    accepted: boolean;
    sendingTime: any;
    receivingTime?: any | null;
    acceptanceTime?: any | null;
    sendingCompanyName: string;
    valid: boolean;
    emailStatus?: EmailStatus | null;
    inviterOrganizationType?: string | null;
    invitedOrganizationType?: string | null;
    inviterRelationRole?: RelationRole | null;
    sendingPerson?: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    } | null;
    contactPerson: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    };
  } | null;
};

export type CreateFlorgInvitationMutationVariables = Exact<{
  invitation: FlorgInvitationInput;
}>;

export type CreateFlorgInvitationMutation = {
  __typename?: 'Mutation';
  createFlorgInvitation?: {
    __typename?: 'FlorgInvitation';
    pid: string;
    inviteLink: string;
    name: string;
    valid: boolean;
    emailStatus?: EmailStatus | null;
    contactPerson: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    };
  } | null;
};

export type ResendFlorgInvitationMutationVariables = Exact<{
  pid: Scalars['ID'];
}>;

export type ResendFlorgInvitationMutation = {
  __typename?: 'Mutation';
  resendFlorgInvitation?: {
    __typename?: 'FlorgInvitation';
    pid: string;
    inviteLink: string;
    name: string;
    valid: boolean;
    emailStatus?: EmailStatus | null;
    contactPerson: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    };
  } | null;
};

export type DeleteSentFlorgInvitationsMutationVariables = Exact<{
  pids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DeleteSentFlorgInvitationsMutation = {
  __typename?: 'Mutation';
  deleteSentFlorgInvitations?: {
    __typename?: 'DeleteResponse';
    status: DeleteStatus;
    pids?: Array<string> | null;
    errorReasons?: Array<{ __typename?: 'ErrorReasonTuple'; pid?: string | null; value?: string | null }> | null;
  } | null;
};

export type DeleteReceivedFlorgInvitationsMutationVariables = Exact<{
  pids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DeleteReceivedFlorgInvitationsMutation = {
  __typename?: 'Mutation';
  deleteReceivedFlorgInvitations?: {
    __typename?: 'DeleteResponse';
    status: DeleteStatus;
    pids?: Array<string> | null;
    errorReasons?: Array<{ __typename?: 'ErrorReasonTuple'; pid?: string | null; value?: string | null }> | null;
  } | null;
};

export type AcceptReceivedFlorgInvitationsMutationVariables = Exact<{
  pids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AcceptReceivedFlorgInvitationsMutation = {
  __typename?: 'Mutation';
  acceptReceivedFlorgInvitations: Array<{
    __typename?: 'FlorgInvitation';
    pid: string;
    inviteLink: string;
    name: string;
    accepted: boolean;
    sendingTime: any;
    receivingTime?: any | null;
    acceptanceTime?: any | null;
    sendingCompanyName: string;
    valid: boolean;
    emailStatus?: EmailStatus | null;
    inviterOrganizationType?: string | null;
    invitedOrganizationType?: string | null;
    inviterRelationRole?: RelationRole | null;
    sendingPerson?: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    } | null;
    contactPerson: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    };
  }>;
};

export type ReceiveFlorgInvitationMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type ReceiveFlorgInvitationMutation = {
  __typename?: 'Mutation';
  receiveFlorgInvitation?: {
    __typename?: 'FlorgInvitation';
    pid: string;
    inviteLink: string;
    name: string;
    accepted: boolean;
    sendingTime: any;
    receivingTime?: any | null;
    acceptanceTime?: any | null;
    sendingCompanyName: string;
    valid: boolean;
    emailStatus?: EmailStatus | null;
    inviterOrganizationType?: string | null;
    invitedOrganizationType?: string | null;
    inviterRelationRole?: RelationRole | null;
    sendingPerson?: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    } | null;
    contactPerson: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    };
  } | null;
};

export type GetConnectedFlorgsQueryVariables = Exact<{ [key: string]: never }>;

export type GetConnectedFlorgsQuery = {
  __typename?: 'Query';
  getConnectedFlorgs: Array<{
    __typename?: 'FlorgRef';
    id: string;
    name: string;
    iconUrl?: string | null;
    connectionType?: FlorgConnectionType | null;
    apiName?: string | null;
  }>;
};

export type GetConnectedFlorgQueryVariables = Exact<{
  pid: Scalars['ID'];
}>;

export type GetConnectedFlorgQuery = {
  __typename?: 'Query';
  getConnectedFlorg?: {
    __typename?: 'Florg';
    id?: string | null;
    name: string;
    iconUrl?: string | null;
    taxCode?: string | null;
    createdDate?: string | null;
    address?: string | null;
    invitedBy?: string | null;
    disabled: boolean;
    tispFlorgPid?: string | null;
    grantedFlorgs?: Array<{
      __typename?: 'FlorgRef';
      id: string;
      name: string;
      connectionType?: FlorgConnectionType | null;
      iconUrl?: string | null;
      apiName?: string | null;
    }> | null;
    florgsGrantedAccessToThisFlorg?: Array<{
      __typename?: 'FlorgRef';
      id: string;
      name: string;
      connectionType?: FlorgConnectionType | null;
      iconUrl?: string | null;
      apiName?: string | null;
    }> | null;
    companyDetails?: {
      __typename?: 'CompanyDetails';
      vatNumber: string;
      registrationNumber: string;
      address: {
        __typename?: 'Address';
        pid: string;
        addressLine1: string;
        addressLine2?: string | null;
        postCode: string;
        city: string;
        country: string;
      };
    } | null;
    contactPerson: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    };
    companySignatory?: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    } | null;
    invoicingContact?: {
      __typename?: 'Person';
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
    } | null;
    organizationTypes: Array<{ __typename?: 'OrganizationType'; key: string; description?: string | null }>;
  } | null;
};

export type AcceptConnectionsToFlorgMutationVariables = Exact<{
  pids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AcceptConnectionsToFlorgMutation = {
  __typename?: 'Mutation';
  acceptConnectionsToFlorg: Array<{
    __typename?: 'FlorgRef';
    id: string;
    name: string;
    connectionType?: FlorgConnectionType | null;
    iconUrl?: string | null;
    apiName?: string | null;
  }>;
};

export type DeleteConnectionsToFlorgMutationVariables = Exact<{
  pids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DeleteConnectionsToFlorgMutation = {
  __typename?: 'Mutation';
  deleteConnectionsToFlorg?: {
    __typename?: 'DeleteResponse';
    status: DeleteStatus;
    pids?: Array<string> | null;
    errorReasons?: Array<{ __typename?: 'ErrorReasonTuple'; pid?: string | null; value?: string | null }> | null;
  } | null;
};

export type GetCustomReportForCarrierQueryVariables = Exact<{
  filters?: InputMaybe<GhgReportFiltersInput>;
}>;

export type GetCustomReportForCarrierQuery = {
  __typename?: 'Query';
  getGhgReportTransportOrderDataForCarrier: Array<{
    __typename?: 'GhgReportTransportOrderData';
    transportOrderId: string;
    transportOrderNumber: string;
    fromTime: any;
    toTime: any;
    from: string;
    to: string;
    transportModes: Array<TransportMode>;
    carrierName?: string | null;
    co2WTT?: number | null;
    co2TTW?: number | null;
    co2WTW?: number | null;
    weight?: number | null;
    distance?: number | null;
    sustainabilityScore?: number | null;
    trueTransparency?: number | null;
    transportIds: Array<number>;
  }>;
};

export type GetCustomTransportReportForCarrierQueryVariables = Exact<{
  filters?: InputMaybe<GhgReportFiltersInput>;
}>;

export type GetCustomTransportReportForCarrierQuery = {
  __typename?: 'Query';
  getGhgReportTransportDataForCarrier: Array<{
    __typename?: 'GhgReportTransportData';
    transportPid: string;
    transportNumber: string;
    fromTime: any;
    toTime: any;
    from: string;
    to: string;
    transportMode: TransportMode;
    carrierName?: string | null;
    vehicleName?: string | null;
    vehicleFlorgName?: string | null;
    co2WTT?: number | null;
    co2TTW?: number | null;
    weight?: number | null;
    distance?: number | null;
    fuel?: number | null;
    orderNumbers: Array<string>;
    sustainabilityScore?: number | null;
    trueTransparency?: number | null;
  }>;
};

export type GetCustomReportForTransportBuyerQueryVariables = Exact<{
  filters?: InputMaybe<GhgReportFiltersInput>;
}>;

export type GetCustomReportForTransportBuyerQuery = {
  __typename?: 'Query';
  getGhgReportTransportOrderDataForTransportBuyer: Array<{
    __typename?: 'GhgReportTransportOrderData';
    transportOrderId: string;
    transportOrderNumber: string;
    fromTime: any;
    toTime: any;
    from: string;
    to: string;
    transportModes: Array<TransportMode>;
    carrierName?: string | null;
    co2WTT?: number | null;
    co2TTW?: number | null;
    co2WTW?: number | null;
    weight?: number | null;
    distance?: number | null;
    sustainabilityScore?: number | null;
    trueTransparency?: number | null;
    transportIds: Array<number>;
  }>;
};

export type GetCustomTransportReportForTransportBuyerQueryVariables = Exact<{
  filters?: InputMaybe<GhgReportFiltersInput>;
}>;

export type GetCustomTransportReportForTransportBuyerQuery = {
  __typename?: 'Query';
  getGhgReportTransportDataForTransportBuyer: Array<{
    __typename?: 'GhgReportTransportData';
    transportPid: string;
    transportNumber: string;
    fromTime: any;
    toTime: any;
    from: string;
    to: string;
    transportMode: TransportMode;
    carrierName?: string | null;
    co2WTT?: number | null;
    co2TTW?: number | null;
    co2WTW?: number | null;
    weight?: number | null;
    distance?: number | null;
    fuel?: number | null;
    orderNumbers: Array<string>;
    sustainabilityScore?: number | null;
    trueTransparency?: number | null;
  }>;
};

export type GetDashboardQueryVariables = Exact<{
  dashboardInput: DashboardInput;
}>;

export type GetDashboardQuery = {
  __typename?: 'Query';
  getDashboard: {
    __typename?: 'DashboardData';
    previousPeriod?: { __typename?: 'PreviousPeriod'; from: any; to: any } | null;
    widgets: {
      __typename?: 'Widgets';
      engineMix?: Array<{
        __typename?: 'EngineMixBusinessType';
        businessType?: BusinessType | null;
        item?: {
          __typename?: 'EngineMixWidget';
          items?: Array<{
            __typename?: 'EngineMixItem';
            fuelType?: DashBoardFuelType | null;
            lineHaul?: number | null;
            lastMile?: number | null;
          } | null> | null;
        } | null;
      } | null> | null;
      itemsTransported?: Array<{
        __typename?: 'ItemsTransportedBusinessType';
        businessType?: BusinessType | null;
        item?: {
          __typename?: 'ItemsTransportedWidget';
          lineHaul?: number | null;
          lastMile?: number | null;
          periodDifference?: number | null;
          total?: number | null;
        } | null;
      } | null> | null;
      transports?: Array<{
        __typename?: 'TransportsBusinessType';
        businessType?: BusinessType | null;
        item?: {
          __typename?: 'TransportsWidget';
          lineHaul?: number | null;
          lastMile?: number | null;
          periodDifference?: number | null;
          total?: number | null;
        } | null;
      } | null> | null;
      goodsVolume?: Array<{
        __typename?: 'GoodsVolumeBusinessType';
        businessType?: BusinessType | null;
        item?: {
          __typename?: 'GoodsVolumeWidget';
          lineHaul?: number | null;
          lastMile?: number | null;
          total?: number | null;
          periodDifference?: number | null;
        } | null;
      } | null> | null;
      co2e?: Array<{
        __typename?: 'Co2eBusinessType';
        businessType?: BusinessType | null;
        item?: {
          __typename?: 'Co2eWidget';
          lineHaul?: number | null;
          lastMile?: number | null;
          total?: number | null;
          periodDifference?: number | null;
          perKm?: number | null;
          perUnit?: number | null;
        } | null;
      } | null> | null;
      targets?: Array<{
        __typename?: 'TargetsWidget';
        type: TargetType;
        current: number;
        goal: number;
        periodDifference?: number | null;
        metrics?: Array<{
          __typename?: 'TargetsMetric';
          date?: any | null;
          current: number;
          target: number;
        } | null> | null;
      } | null> | null;
    };
  };
};

export type GetEnvironmentalClassesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEnvironmentalClassesQuery = {
  __typename?: 'Query';
  getEnvironmentalClasses: Array<{ __typename?: 'EnvironmentalClass'; id: string; label: string }>;
};

export type UpdateFlorgApiNamesMutationVariables = Exact<{
  apiNameInputs: Array<FlorgApiNameInput> | FlorgApiNameInput;
}>;

export type UpdateFlorgApiNamesMutation = {
  __typename?: 'Mutation';
  updateFlorgApiNames: Array<{ __typename?: 'FlorgRef'; id: string; name: string; apiName?: string | null }>;
};

export type GetAllFlorgsForCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllFlorgsForCurrentUserQuery = {
  __typename?: 'Query';
  getAllFlorgsForCurrentUser: Array<{
    __typename?: 'Florg';
    id?: string | null;
    name: string;
    iconUrl?: string | null;
    disabled: boolean;
  }>;
};

export type ChangeActiveFlorgMutationVariables = Exact<{
  florgPid: Scalars['String'];
}>;

export type ChangeActiveFlorgMutation = {
  __typename?: 'Mutation';
  changeActiveFlorg: {
    __typename?: 'Florg';
    id?: string | null;
    name: string;
    iconUrl?: string | null;
    disabled: boolean;
  };
};

export type GetProductsFromOrderItemsQueryVariables = Exact<{
  filter?: InputMaybe<SearchFilterInput>;
}>;

export type GetProductsFromOrderItemsQuery = {
  __typename?: 'Query';
  getProductsFromOrderItems: Array<{ __typename?: 'Product'; productNumber: string; goodsType: string }>;
};

export type GetProductsForAssignedTransportOrdersQueryVariables = Exact<{
  filter?: InputMaybe<SearchFilterInput>;
}>;

export type GetProductsForAssignedTransportOrdersQuery = {
  __typename?: 'Query';
  getProductsForAssignedTransportOrders: Array<{ __typename?: 'Product'; productNumber: string; goodsType: string }>;
};

export type GetReportForCarrierQueryVariables = Exact<{
  filters?: InputMaybe<GhgReportFiltersInput>;
}>;

export type GetReportForCarrierQuery = {
  __typename?: 'Query';
  getGhgReportDataForCarrier: Array<{
    __typename?: 'GhgReportData';
    vehicleName: string;
    goodsDescription: string;
    co2Usage?: number | null;
    noxUsage?: number | null;
    pmUsage?: number | null;
    coUsage?: number | null;
    hcUsage?: number | null;
    so2Usage?: number | null;
    routeName?: string | null;
    percentageOfCargo?: number | null;
    carrierName: string;
    productNumber: string;
    fromTime: any;
    toTime: any;
    fuelUsedInLitres?: number | null;
    delayInMin?: number | null;
    transportNumber: string;
    transportOrderNumber: string;
    transportBuyerName: string;
    co2WTT?: number | null;
    co2TTW?: number | null;
    co2WTW?: number | null;
  }>;
};

export type GetReportForTransportBuyerQueryVariables = Exact<{
  filters?: InputMaybe<GhgReportFiltersInput>;
}>;

export type GetReportForTransportBuyerQuery = {
  __typename?: 'Query';
  getGhgReportDataForTransportBuyer: Array<{
    __typename?: 'GhgReportData';
    vehicleName: string;
    goodsDescription: string;
    co2Usage?: number | null;
    noxUsage?: number | null;
    pmUsage?: number | null;
    coUsage?: number | null;
    hcUsage?: number | null;
    so2Usage?: number | null;
    routeName?: string | null;
    percentageOfCargo?: number | null;
    carrierName: string;
    productNumber: string;
    fromTime: any;
    toTime: any;
    fuelUsedInLitres?: number | null;
    delayInMin?: number | null;
    transportNumber: string;
    transportOrderNumber: string;
    transportBuyerName: string;
    co2WTT?: number | null;
    co2TTW?: number | null;
  }>;
};

export type GetRoutesQueryVariables = Exact<{
  filter?: InputMaybe<RouteFilterInput>;
}>;

export type GetRoutesQuery = {
  __typename?: 'Query';
  getRoutes: Array<{
    __typename?: 'Route';
    pid: string;
    from?: { __typename?: 'PointOfInterest'; name: string } | null;
    to?: { __typename?: 'PointOfInterest'; name: string } | null;
  }>;
};

export type GetRouteQueryVariables = Exact<{
  pid: Scalars['String'];
}>;

export type GetRouteQuery = {
  __typename?: 'Query';
  getRoute: {
    __typename?: 'Route';
    pid: string;
    from?: { __typename?: 'PointOfInterest'; name: string } | null;
    to?: { __typename?: 'PointOfInterest'; name: string } | null;
  };
};

export type StoreRouteMutationVariables = Exact<{
  route: RouteInput;
}>;

export type StoreRouteMutation = {
  __typename?: 'Mutation';
  storeRoute?: {
    __typename?: 'Route';
    pid: string;
    from?: { __typename?: 'PointOfInterest'; name: string } | null;
    to?: { __typename?: 'PointOfInterest'; name: string } | null;
  } | null;
};

export type DeleteRouteMutationVariables = Exact<{
  pid: Scalars['String'];
}>;

export type DeleteRouteMutation = {
  __typename?: 'Mutation';
  deleteRoute?: {
    __typename?: 'DeleteResponse';
    status: DeleteStatus;
    pids?: Array<string> | null;
    errorReasons?: Array<{ __typename?: 'ErrorReasonTuple'; pid?: string | null; value?: string | null }> | null;
  } | null;
};

export type GetReportRoutesQueryVariables = Exact<{ [key: string]: never }>;

export type GetReportRoutesQuery = {
  __typename?: 'Query';
  getReportRoutes: Array<{
    __typename?: 'Route';
    pid: string;
    from?: { __typename?: 'PointOfInterest'; name: string } | null;
    to?: { __typename?: 'PointOfInterest'; name: string } | null;
  }>;
};

export type GetScaniaUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetScaniaUsersQuery = {
  __typename?: 'Query';
  getScaniaUsers: Array<{ __typename?: 'RfmsUserData'; pid: string; name: string }>;
};

export type StoreScaniaUserMutationVariables = Exact<{
  storeUserRequest: StoreScaniaRfmsUserInput;
}>;

export type StoreScaniaUserMutation = {
  __typename?: 'Mutation';
  storeScaniaUser?: { __typename?: 'RfmsUserData'; pid: string; name: string } | null;
};

export type GetSustainabilityScoresForFlorgQueryVariables = Exact<{ [key: string]: never }>;

export type GetSustainabilityScoresForFlorgQuery = {
  __typename?: 'Query';
  getSustainabilityScoresForFlorg: Array<{ __typename?: 'SustainabilityScoreData'; threshold: number; score: number }>;
};

export type CreateSustainabilityScoresForFlorgMutationVariables = Exact<{
  scores: Array<FlorgSustainabilityScoreInput> | FlorgSustainabilityScoreInput;
}>;

export type CreateSustainabilityScoresForFlorgMutation = {
  __typename?: 'Mutation';
  createSustainabilityScoresForFlorg: Array<{
    __typename?: 'SustainabilityScoreData';
    score: number;
    florgPid?: string | null;
  }>;
};

export type GetTransportOrdersQueryVariables = Exact<{
  filter?: InputMaybe<SearchFilterInput>;
}>;

export type GetTransportOrdersQuery = {
  __typename?: 'Query';
  getTransportOrders: Array<{ __typename?: 'TransportOrderListItem'; id: string; transportOrderNumber: string }>;
};

export type StoreTransportOrderMutationVariables = Exact<{
  transportOrder: TransportOrderInput;
}>;

export type StoreTransportOrderMutation = {
  __typename?: 'Mutation';
  storeTransportOrder?: {
    __typename?: 'TransportOrder';
    id: string;
    pid: string;
    transportOrderNumber: string;
    wantedPickupTime?: any | null;
    wantedArrivalTime?: any | null;
    totalVolumeInCd3?: number | null;
    totalWeightInKg?: number | null;
    businessType: BusinessType;
    orderItems?: Array<{
      __typename?: 'TransportOrderItem';
      id: string;
      goodsWeight: number;
      goodsType: string;
      noOfItems: number;
      totalWeightInKg: number;
      goodsVolume?: number | null;
      totalVolumeInCd3?: number | null;
      productNumber?: string | null;
      transportItemType?: { __typename?: 'TransportItemType'; id: string; label: string } | null;
    }> | null;
    assignedCarriers?: Array<{ __typename?: 'FlorgRef'; id: string; name: string }> | null;
    route?: { __typename?: 'Route'; pid: string } | null;
    transportItemType?: { __typename?: 'TransportItemType'; id: string; label: string } | null;
  } | null;
};

export type StoreTransportOrdersMutationVariables = Exact<{
  transportOrders: Array<TransportOrderInput> | TransportOrderInput;
}>;

export type StoreTransportOrdersMutation = {
  __typename?: 'Mutation';
  storeTransportOrders: Array<{ __typename?: 'TransportOrder'; id: string; pid: string; transportOrderNumber: string }>;
};

export type DeleteTransportOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTransportOrderMutation = {
  __typename?: 'Mutation';
  deleteTransportOrder?: {
    __typename?: 'DeleteResponse';
    pids?: Array<string> | null;
    status: DeleteStatus;
    errorReasons?: Array<{ __typename?: 'ErrorReasonTuple'; pid?: string | null; value?: string | null }> | null;
  } | null;
};

export type GetTransportOrderQueryVariables = Exact<{
  id: Scalars['ID'];
  carrierPid?: InputMaybe<Scalars['String']>;
}>;

export type GetTransportOrderQuery = {
  __typename?: 'Query';
  getTransportOrder?: {
    __typename?: 'TransportOrder';
    id: string;
    pid: string;
    transportOrderNumber: string;
    wantedPickupTime?: any | null;
    wantedArrivalTime?: any | null;
    totalVolumeInCd3?: number | null;
    totalWeightInKg?: number | null;
    businessType: BusinessType;
    orderItems?: Array<{
      __typename?: 'TransportOrderItem';
      id: string;
      goodsWeight: number;
      goodsType: string;
      noOfItems: number;
      totalWeightInKg: number;
      goodsVolume?: number | null;
      totalVolumeInCd3?: number | null;
      productNumber?: string | null;
      transportItemType?: { __typename?: 'TransportItemType'; id: string; label: string } | null;
    }> | null;
    assignedCarriers?: Array<{ __typename?: 'FlorgRef'; id: string; name: string }> | null;
    route?: { __typename?: 'Route'; pid: string } | null;
    transportItemType?: { __typename?: 'TransportItemType'; id: string; label: string } | null;
  } | null;
};

export type GetTransportOrderWithEmissionsQueryVariables = Exact<{
  id: Scalars['ID'];
  carrierPid?: InputMaybe<Scalars['String']>;
}>;

export type GetTransportOrderWithEmissionsQuery = {
  __typename?: 'Query';
  getTransportOrder?: {
    __typename?: 'TransportOrder';
    transportOrderNumber: string;
    wantedPickupTime?: any | null;
    wantedArrivalTime?: any | null;
    co2e?: number | null;
    trueTransparency?: number | null;
    totalVolumeInCd3?: number | null;
    totalWeightInKg?: number | null;
    businessType: BusinessType;
    orderItems?: Array<{
      __typename?: 'TransportOrderItem';
      goodsWeight: number;
      goodsType: string;
      noOfItems: number;
      totalWeightInKg: number;
      goodsVolume?: number | null;
      totalVolumeInCd3?: number | null;
      productNumber?: string | null;
      transportItemType?: { __typename?: 'TransportItemType'; id: string; label: string } | null;
    }> | null;
    transports?: Array<{
      __typename?: 'Transport';
      transportNumber: string;
      startTime?: any | null;
      endTime?: any | null;
      kmDriven?: number | null;
      usedFuelInLitres?: number | null;
      energyUsedInKwh?: number | null;
      totalCargoWeight?: number | null;
      mode?: TransportMode | null;
      co2e?: number | null;
      sustainabilityScore?: number | null;
      trueTransparency?: number | null;
      vehicle?: {
        __typename?: 'Vehicle';
        pid: string;
        name?: string | null;
        vin?: string | null;
        vehicleBrand?: { __typename?: 'VehicleBrand'; id: string; label: string } | null;
        fuelType?: { __typename?: 'FuelType'; id: string; label: string } | null;
      } | null;
      route?: {
        __typename?: 'Route';
        from?: { __typename?: 'PointOfInterest'; name: string } | null;
        to?: { __typename?: 'PointOfInterest'; name: string } | null;
      } | null;
    }> | null;
    route?: {
      __typename?: 'Route';
      from?: { __typename?: 'PointOfInterest'; name: string } | null;
      to?: { __typename?: 'PointOfInterest'; name: string } | null;
    } | null;
    transportItemType?: { __typename?: 'TransportItemType'; id: string; label: string } | null;
  } | null;
};

export type GetTransportsQueryVariables = Exact<{
  filter?: InputMaybe<SearchFilterInput>;
}>;

export type GetTransportsQuery = {
  __typename?: 'Query';
  getTransports: Array<{
    __typename?: 'Transport';
    pid: string;
    transportNumber: string;
    vehicle?: { __typename?: 'Vehicle'; name?: string | null } | null;
  }>;
};

export type GetTransportQueryVariables = Exact<{
  pid: Scalars['ID'];
  buyerPid?: InputMaybe<Scalars['String']>;
}>;

export type GetTransportQuery = {
  __typename?: 'Query';
  getTransport?: {
    __typename?: 'Transport';
    pid: string;
    transportNumber: string;
    startTime?: any | null;
    endTime?: any | null;
    totalCargoWeight?: number | null;
    totalGoodsVolume?: number | null;
    kmDriven?: number | null;
    usedFuelInLitres?: number | null;
    energyUsedInKwh?: number | null;
    mode?: TransportMode | null;
    fuelQuality: FuelQuality;
    vehicle?: {
      __typename?: 'Vehicle';
      pid: string;
      name?: string | null;
      vin?: string | null;
      vehicleBrand?: { __typename?: 'VehicleBrand'; id: string; label: string } | null;
      fuelType?: { __typename?: 'FuelType'; id: string; label: string } | null;
    } | null;
    transportOrders?: Array<{
      __typename?: 'TransportOrder';
      id: string;
      pid: string;
      transportOrderNumber: string;
      completed?: boolean | null;
    }> | null;
    route?: {
      __typename?: 'Route';
      pid: string;
      label?: string | null;
      from?: { __typename?: 'PointOfInterest'; name: string } | null;
      to?: { __typename?: 'PointOfInterest'; name: string } | null;
    } | null;
  } | null;
};

export type StoreTransportMutationVariables = Exact<{
  transport: TransportInput;
}>;

export type StoreTransportMutation = {
  __typename?: 'Mutation';
  storeTransport?: {
    __typename?: 'Transport';
    pid: string;
    transportNumber: string;
    startTime?: any | null;
    endTime?: any | null;
    totalCargoWeight?: number | null;
    totalGoodsVolume?: number | null;
    kmDriven?: number | null;
    usedFuelInLitres?: number | null;
    energyUsedInKwh?: number | null;
    mode?: TransportMode | null;
    fuelQuality: FuelQuality;
    vehicle?: {
      __typename?: 'Vehicle';
      pid: string;
      name?: string | null;
      vin?: string | null;
      vehicleBrand?: { __typename?: 'VehicleBrand'; id: string; label: string } | null;
      fuelType?: { __typename?: 'FuelType'; id: string; label: string } | null;
    } | null;
    transportOrders?: Array<{
      __typename?: 'TransportOrder';
      id: string;
      pid: string;
      transportOrderNumber: string;
    }> | null;
    route?: {
      __typename?: 'Route';
      pid: string;
      from?: { __typename?: 'PointOfInterest'; name: string } | null;
      to?: { __typename?: 'PointOfInterest'; name: string } | null;
    } | null;
  } | null;
};

export type StoreTransportForImportMutationVariables = Exact<{
  transport: TransportInput;
}>;

export type StoreTransportForImportMutation = {
  __typename?: 'Mutation';
  storeTransport?: {
    __typename?: 'Transport';
    pid: string;
    transportNumber: string;
    startTime?: any | null;
    endTime?: any | null;
    totalCargoWeight?: number | null;
    totalGoodsVolume?: number | null;
    kmDriven?: number | null;
    usedFuelInLitres?: number | null;
    energyUsedInKwh?: number | null;
    mode?: TransportMode | null;
    fuelQuality: FuelQuality;
    vehicle?: {
      __typename?: 'Vehicle';
      pid: string;
      name?: string | null;
      vin?: string | null;
      vehicleBrand?: { __typename?: 'VehicleBrand'; id: string; label: string } | null;
      fuelType?: { __typename?: 'FuelType'; id: string; label: string } | null;
    } | null;
  } | null;
};

export type DeleteTransportMutationVariables = Exact<{
  pid: Scalars['ID'];
}>;

export type DeleteTransportMutation = {
  __typename?: 'Mutation';
  deleteTransport?: {
    __typename?: 'DeleteResponse';
    status: DeleteStatus;
    pids?: Array<string> | null;
    errorReasons?: Array<{ __typename?: 'ErrorReasonTuple'; pid?: string | null; value?: string | null }> | null;
  } | null;
};

export type GetTransportWithSustainabilityQueryVariables = Exact<{
  pid: Scalars['ID'];
  buyerPid?: InputMaybe<Scalars['String']>;
}>;

export type GetTransportWithSustainabilityQuery = {
  __typename?: 'Query';
  getTransport?: {
    __typename?: 'Transport';
    pid: string;
    transportNumber: string;
    startTime?: any | null;
    endTime?: any | null;
    totalCargoWeight?: number | null;
    totalGoodsVolume?: number | null;
    kmDriven?: number | null;
    usedFuelInLitres?: number | null;
    energyUsedInKwh?: number | null;
    mode?: TransportMode | null;
    fuelQuality: FuelQuality;
    co2e?: number | null;
    sustainabilityScore?: number | null;
    trueTransparency?: number | null;
    vehicle?: {
      __typename?: 'Vehicle';
      pid: string;
      name?: string | null;
      vin?: string | null;
      vehicleBrand?: { __typename?: 'VehicleBrand'; id: string; label: string } | null;
      fuelType?: { __typename?: 'FuelType'; id: string; label: string } | null;
    } | null;
    transportOrders?: Array<{
      __typename?: 'TransportOrder';
      id: string;
      pid: string;
      transportOrderNumber: string;
    }> | null;
    route?: {
      __typename?: 'Route';
      pid: string;
      from?: { __typename?: 'PointOfInterest'; name: string } | null;
      to?: { __typename?: 'PointOfInterest'; name: string } | null;
    } | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  userInput: UserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'User';
    id: string;
    name: string;
    userConsentAccepted?: boolean | null;
    selectedVehicle?: { __typename?: 'Vehicle'; pid: string; name?: string | null } | null;
  } | null;
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  __typename?: 'Query';
  getAllUsersForCurrentFlorg: Array<{
    __typename?: 'UserInFlorg';
    id: string;
    name: string;
    roles: Array<{
      __typename?: 'Role';
      pid: string;
      key: string;
      description?: string | null;
      rights: Array<{ __typename?: 'Right'; pid: string; key: string; description?: string | null }>;
    }>;
  }>;
};

export type GetUserQueryVariables = Exact<{
  pid: Scalars['String'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUserByPidForCurrentFlorg?: {
    __typename?: 'UserInFlorg';
    id: string;
    name: string;
    roles: Array<{
      __typename?: 'Role';
      pid: string;
      key: string;
      description?: string | null;
      rights: Array<{ __typename?: 'Right'; pid: string; key: string; description?: string | null }>;
    }>;
  } | null;
};

export type GetAllRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRolesQuery = {
  __typename?: 'Query';
  getAllRoles: Array<{
    __typename?: 'Role';
    pid: string;
    key: string;
    description?: string | null;
    rights: Array<{ __typename?: 'Right'; pid: string; key: string; description?: string | null }>;
  }>;
};

export type GetVehicleBrandsQueryVariables = Exact<{ [key: string]: never }>;

export type GetVehicleBrandsQuery = {
  __typename?: 'Query';
  getVehicleBrands: Array<{ __typename?: 'VehicleBrand'; id: string; label: string }>;
};

export type VehiclesQueryVariables = Exact<{
  filter?: InputMaybe<VehiclesFilterInput>;
}>;

export type VehiclesQuery = {
  __typename?: 'Query';
  vehicles: Array<{
    __typename?: 'VehicleListItem';
    pid: string;
    name?: string | null;
    telematicStatus?: TelematicStatus | null;
    telematicUnitType?: TelematicType | null;
    grossVehicleWeight?: number | null;
    type?: VehicleCharacteristic | null;
    vehicleBrand?: { __typename?: 'VehicleBrand'; id: string; label: string } | null;
    fuelType?: { __typename?: 'FuelType'; id: string; label: string } | null;
  }>;
};

export type VehiclesWithSubcarriersQueryVariables = Exact<{ [key: string]: never }>;

export type VehiclesWithSubcarriersQuery = {
  __typename?: 'Query';
  vehiclesWithSubcarriers: Array<{ __typename?: 'VehicleListItem'; pid: string; name?: string | null }>;
};

export type VehicleQueryVariables = Exact<{
  pid: Scalars['ID'];
}>;

export type VehicleQuery = {
  __typename?: 'Query';
  vehicle: {
    __typename?: 'Vehicle';
    pid: string;
    tispVehiclePid: string;
    name?: string | null;
    telematicUnitType?: TelematicType | null;
    telematicStatus?: TelematicStatus | null;
    grossVehicleWeight?: number | null;
    vin?: string | null;
    model?: string | null;
    year?: number | null;
    type?: VehicleCharacteristic | null;
    environmentClass?: { __typename?: 'EnvironmentalClass'; id: string; label: string } | null;
    vehicleBrand?: { __typename?: 'VehicleBrand'; id: string; label: string } | null;
    fuelType?: { __typename?: 'FuelType'; id: string; label: string } | null;
  };
};

export type StoreVehicleMutationVariables = Exact<{
  vehicle: VehicleInput;
}>;

export type StoreVehicleMutation = {
  __typename?: 'Mutation';
  storeVehicle: {
    __typename?: 'Vehicle';
    pid: string;
    tispVehiclePid: string;
    name?: string | null;
    telematicUnitType?: TelematicType | null;
    grossVehicleWeight?: number | null;
    vin?: string | null;
    model?: string | null;
    year?: number | null;
    type?: VehicleCharacteristic | null;
    environmentClass?: { __typename?: 'EnvironmentalClass'; id: string; label: string } | null;
    vehicleBrand?: { __typename?: 'VehicleBrand'; id: string; label: string } | null;
    fuelType?: { __typename?: 'FuelType'; id: string; label: string } | null;
  };
};

export type DeleteVehiclesMutationVariables = Exact<{
  pids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteVehiclesMutation = {
  __typename?: 'Mutation';
  deleteVehicles?: {
    __typename?: 'DeleteResponse';
    status: DeleteStatus;
    pids?: Array<string> | null;
    errorReasons?: Array<{ __typename?: 'ErrorReasonTuple'; pid?: string | null; value?: string | null }> | null;
  } | null;
};

export type GetVehicleDataFromVinQueryVariables = Exact<{
  vin: Scalars['String'];
}>;

export type GetVehicleDataFromVinQuery = {
  __typename?: 'Query';
  getVehicleDataFromVin?: {
    __typename?: 'VehicleDataFromVin';
    country?: string | null;
    manufacturer?: string | null;
    checkDigit?: string | null;
    year?: number | null;
    plantCode?: string | null;
    serialNumber: string;
    brandId?: number | null;
  } | null;
};

export type GetTelematicSuggestionsQueryVariables = Exact<{
  telematicSuggestionInputs: Array<TelematicSuggestionInput> | TelematicSuggestionInput;
}>;

export type GetTelematicSuggestionsQuery = {
  __typename?: 'Query';
  getTelematicSuggestions: Array<{
    __typename?: 'TelematicTypeSuggestion';
    name: string;
    suggestions: Array<{
      __typename?: 'TelematicTypeResponse';
      telematicType: TelematicType;
      label: string;
      priority: number;
    }>;
  }>;
};

export const ApplicationContextDocument = gql`
  query ApplicationContext {
    getApplicationContext {
      activeUser {
        id
        name
      }
      activeFlorg {
        id
        name
      }
      systemSetting {
        onDemandServiceDatasetName
      }
      fuelTypes {
        id
        label
      }
      transportItemTypes {
        id
        label
      }
      vehicleBrands {
        id
        label
      }
      environmentalClasses {
        id
        label
      }
      userRights
    }
  }
`;

/**
 * __useApplicationContextQuery__
 *
 * To run a query within a React component, call `useApplicationContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationContextQuery(
  baseOptions?: Apollo.QueryHookOptions<ApplicationContextQuery, ApplicationContextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationContextQuery, ApplicationContextQueryVariables>(
    ApplicationContextDocument,
    options,
  );
}
export function useApplicationContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplicationContextQuery, ApplicationContextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationContextQuery, ApplicationContextQueryVariables>(
    ApplicationContextDocument,
    options,
  );
}
export type ApplicationContextQueryHookResult = ReturnType<typeof useApplicationContextQuery>;
export type ApplicationContextLazyQueryHookResult = ReturnType<typeof useApplicationContextLazyQuery>;
export type ApplicationContextQueryResult = Apollo.QueryResult<
  ApplicationContextQuery,
  ApplicationContextQueryVariables
>;
export const GetAssignedTransportOrdersDocument = gql`
  query GetAssignedTransportOrders($filter: SearchFilterInput) {
    getAssignedTransportOrders(filter: $filter) {
      id
      assignedFlorg {
        id
        name
      }
      assigningFlorg {
        id
        name
      }
      assignedOrder {
        id
        pid
        transportOrderNumber
        wantedPickupTime
        wantedArrivalTime
        orderItems {
          id
          goodsWeight
          goodsType
          noOfItems
          totalWeightInKg
          transportItemType {
            id
            label
          }
          productNumber
        }
        transports {
          pid
          transportNumber
          startTime
          endTime
          vehicle {
            pid
            name
            vehicleBrand {
              id
              label
            }
            fuelType {
              id
              label
            }
            vin
          }
          totalCargoWeight
          kmDriven
          usedFuelInLitres
          energyUsedInKwh
        }
        assignedCarriers {
          id
          name
        }
        route {
          pid
        }
      }
      completedTime
    }
  }
`;

/**
 * __useGetAssignedTransportOrdersQuery__
 *
 * To run a query within a React component, call `useGetAssignedTransportOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedTransportOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedTransportOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAssignedTransportOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAssignedTransportOrdersQuery, GetAssignedTransportOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssignedTransportOrdersQuery, GetAssignedTransportOrdersQueryVariables>(
    GetAssignedTransportOrdersDocument,
    options,
  );
}
export function useGetAssignedTransportOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAssignedTransportOrdersQuery, GetAssignedTransportOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssignedTransportOrdersQuery, GetAssignedTransportOrdersQueryVariables>(
    GetAssignedTransportOrdersDocument,
    options,
  );
}
export type GetAssignedTransportOrdersQueryHookResult = ReturnType<typeof useGetAssignedTransportOrdersQuery>;
export type GetAssignedTransportOrdersLazyQueryHookResult = ReturnType<typeof useGetAssignedTransportOrdersLazyQuery>;
export type GetAssignedTransportOrdersQueryResult = Apollo.QueryResult<
  GetAssignedTransportOrdersQuery,
  GetAssignedTransportOrdersQueryVariables
>;
export const GetAssignedTransportOrdersForFilterDocument = gql`
  query GetAssignedTransportOrdersForFilter($filter: SearchFilterInput) {
    getAssignedTransportOrders(filter: $filter) {
      assignedFlorg {
        id
        name
      }
      assigningFlorg {
        id
        name
      }
      assignedOrder {
        transportOrderNumber
        orderItems {
          goodsType
          productNumber
        }
      }
    }
  }
`;

/**
 * __useGetAssignedTransportOrdersForFilterQuery__
 *
 * To run a query within a React component, call `useGetAssignedTransportOrdersForFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedTransportOrdersForFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedTransportOrdersForFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAssignedTransportOrdersForFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAssignedTransportOrdersForFilterQuery,
    GetAssignedTransportOrdersForFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssignedTransportOrdersForFilterQuery, GetAssignedTransportOrdersForFilterQueryVariables>(
    GetAssignedTransportOrdersForFilterDocument,
    options,
  );
}
export function useGetAssignedTransportOrdersForFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssignedTransportOrdersForFilterQuery,
    GetAssignedTransportOrdersForFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssignedTransportOrdersForFilterQuery,
    GetAssignedTransportOrdersForFilterQueryVariables
  >(GetAssignedTransportOrdersForFilterDocument, options);
}
export type GetAssignedTransportOrdersForFilterQueryHookResult = ReturnType<
  typeof useGetAssignedTransportOrdersForFilterQuery
>;
export type GetAssignedTransportOrdersForFilterLazyQueryHookResult = ReturnType<
  typeof useGetAssignedTransportOrdersForFilterLazyQuery
>;
export type GetAssignedTransportOrdersForFilterQueryResult = Apollo.QueryResult<
  GetAssignedTransportOrdersForFilterQuery,
  GetAssignedTransportOrdersForFilterQueryVariables
>;
export const GetB2BClientsDocument = gql`
  query GetB2BClients {
    getB2BClients {
      clientName
      clientId
      clientSecret
    }
  }
`;

/**
 * __useGetB2BClientsQuery__
 *
 * To run a query within a React component, call `useGetB2BClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2BClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2BClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetB2BClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetB2BClientsQuery, GetB2BClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetB2BClientsQuery, GetB2BClientsQueryVariables>(GetB2BClientsDocument, options);
}
export function useGetB2BClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetB2BClientsQuery, GetB2BClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetB2BClientsQuery, GetB2BClientsQueryVariables>(GetB2BClientsDocument, options);
}
export type GetB2BClientsQueryHookResult = ReturnType<typeof useGetB2BClientsQuery>;
export type GetB2BClientsLazyQueryHookResult = ReturnType<typeof useGetB2BClientsLazyQuery>;
export type GetB2BClientsQueryResult = Apollo.QueryResult<GetB2BClientsQuery, GetB2BClientsQueryVariables>;
export const CreateB2BClientDocument = gql`
  mutation createB2BClient($clientName: String!) {
    createB2BClient(clientName: $clientName) {
      clientName
      clientId
      clientSecret
    }
  }
`;
export type CreateB2BClientMutationFn = Apollo.MutationFunction<
  CreateB2BClientMutation,
  CreateB2BClientMutationVariables
>;

/**
 * __useCreateB2BClientMutation__
 *
 * To run a mutation, you first call `useCreateB2BClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateB2BClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createB2BClientMutation, { data, loading, error }] = useCreateB2BClientMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useCreateB2BClientMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateB2BClientMutation, CreateB2BClientMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateB2BClientMutation, CreateB2BClientMutationVariables>(
    CreateB2BClientDocument,
    options,
  );
}
export type CreateB2BClientMutationHookResult = ReturnType<typeof useCreateB2BClientMutation>;
export type CreateB2BClientMutationResult = Apollo.MutationResult<CreateB2BClientMutation>;
export type CreateB2BClientMutationOptions = Apollo.BaseMutationOptions<
  CreateB2BClientMutation,
  CreateB2BClientMutationVariables
>;
export const DeleteB2BClientDocument = gql`
  mutation DeleteB2BClient($clientId: String!) {
    deleteB2BClient(clientId: $clientId) {
      status
      errorReasons {
        pid
        value
      }
    }
  }
`;
export type DeleteB2BClientMutationFn = Apollo.MutationFunction<
  DeleteB2BClientMutation,
  DeleteB2BClientMutationVariables
>;

/**
 * __useDeleteB2BClientMutation__
 *
 * To run a mutation, you first call `useDeleteB2BClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteB2BClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteB2BClientMutation, { data, loading, error }] = useDeleteB2BClientMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useDeleteB2BClientMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteB2BClientMutation, DeleteB2BClientMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteB2BClientMutation, DeleteB2BClientMutationVariables>(
    DeleteB2BClientDocument,
    options,
  );
}
export type DeleteB2BClientMutationHookResult = ReturnType<typeof useDeleteB2BClientMutation>;
export type DeleteB2BClientMutationResult = Apollo.MutationResult<DeleteB2BClientMutation>;
export type DeleteB2BClientMutationOptions = Apollo.BaseMutationOptions<
  DeleteB2BClientMutation,
  DeleteB2BClientMutationVariables
>;
export const GetBusinessTypesDocument = gql`
  query GetBusinessTypes {
    getBusinessTypes
  }
`;

/**
 * __useGetBusinessTypesQuery__
 *
 * To run a query within a React component, call `useGetBusinessTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBusinessTypesQuery, GetBusinessTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBusinessTypesQuery, GetBusinessTypesQueryVariables>(GetBusinessTypesDocument, options);
}
export function useGetBusinessTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessTypesQuery, GetBusinessTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBusinessTypesQuery, GetBusinessTypesQueryVariables>(GetBusinessTypesDocument, options);
}
export type GetBusinessTypesQueryHookResult = ReturnType<typeof useGetBusinessTypesQuery>;
export type GetBusinessTypesLazyQueryHookResult = ReturnType<typeof useGetBusinessTypesLazyQuery>;
export type GetBusinessTypesQueryResult = Apollo.QueryResult<GetBusinessTypesQuery, GetBusinessTypesQueryVariables>;
export const GetSentFlorgInvitationsDocument = gql`
  query getSentFlorgInvitations($florgType: String!) {
    getSentFlorgInvitations(florgType: $florgType) {
      pid
      inviteLink
      name
      accepted
      sendingTime
      acceptanceTime
      sendingCompanyName
      sendingPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      contactPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      valid
      emailStatus
    }
  }
`;

/**
 * __useGetSentFlorgInvitationsQuery__
 *
 * To run a query within a React component, call `useGetSentFlorgInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSentFlorgInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSentFlorgInvitationsQuery({
 *   variables: {
 *      florgType: // value for 'florgType'
 *   },
 * });
 */
export function useGetSentFlorgInvitationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetSentFlorgInvitationsQuery, GetSentFlorgInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSentFlorgInvitationsQuery, GetSentFlorgInvitationsQueryVariables>(
    GetSentFlorgInvitationsDocument,
    options,
  );
}
export function useGetSentFlorgInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSentFlorgInvitationsQuery, GetSentFlorgInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSentFlorgInvitationsQuery, GetSentFlorgInvitationsQueryVariables>(
    GetSentFlorgInvitationsDocument,
    options,
  );
}
export type GetSentFlorgInvitationsQueryHookResult = ReturnType<typeof useGetSentFlorgInvitationsQuery>;
export type GetSentFlorgInvitationsLazyQueryHookResult = ReturnType<typeof useGetSentFlorgInvitationsLazyQuery>;
export type GetSentFlorgInvitationsQueryResult = Apollo.QueryResult<
  GetSentFlorgInvitationsQuery,
  GetSentFlorgInvitationsQueryVariables
>;
export const GetReceivedFlorgInvitationsDocument = gql`
  query getReceivedFlorgInvitations($florgType: String!) {
    getReceivedFlorgInvitations(florgType: $florgType) {
      pid
      inviteLink
      name
      accepted
      sendingTime
      receivingTime
      acceptanceTime
      sendingCompanyName
      sendingPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      contactPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      valid
      emailStatus
      inviterOrganizationType
      invitedOrganizationType
      inviterRelationRole
    }
  }
`;

/**
 * __useGetReceivedFlorgInvitationsQuery__
 *
 * To run a query within a React component, call `useGetReceivedFlorgInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceivedFlorgInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceivedFlorgInvitationsQuery({
 *   variables: {
 *      florgType: // value for 'florgType'
 *   },
 * });
 */
export function useGetReceivedFlorgInvitationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetReceivedFlorgInvitationsQuery, GetReceivedFlorgInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReceivedFlorgInvitationsQuery, GetReceivedFlorgInvitationsQueryVariables>(
    GetReceivedFlorgInvitationsDocument,
    options,
  );
}
export function useGetReceivedFlorgInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReceivedFlorgInvitationsQuery,
    GetReceivedFlorgInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReceivedFlorgInvitationsQuery, GetReceivedFlorgInvitationsQueryVariables>(
    GetReceivedFlorgInvitationsDocument,
    options,
  );
}
export type GetReceivedFlorgInvitationsQueryHookResult = ReturnType<typeof useGetReceivedFlorgInvitationsQuery>;
export type GetReceivedFlorgInvitationsLazyQueryHookResult = ReturnType<typeof useGetReceivedFlorgInvitationsLazyQuery>;
export type GetReceivedFlorgInvitationsQueryResult = Apollo.QueryResult<
  GetReceivedFlorgInvitationsQuery,
  GetReceivedFlorgInvitationsQueryVariables
>;
export const GetFlorgInvitationByPidDocument = gql`
  query getFlorgInvitationByPid($pid: ID!) {
    getFlorgInvitationByPid(pid: $pid) {
      pid
      inviteLink
      name
      accepted
      sendingTime
      receivingTime
      acceptanceTime
      sendingCompanyName
      sendingPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      contactPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      valid
      emailStatus
      inviterOrganizationType
      invitedOrganizationType
      inviterRelationRole
    }
  }
`;

/**
 * __useGetFlorgInvitationByPidQuery__
 *
 * To run a query within a React component, call `useGetFlorgInvitationByPidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlorgInvitationByPidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlorgInvitationByPidQuery({
 *   variables: {
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useGetFlorgInvitationByPidQuery(
  baseOptions: Apollo.QueryHookOptions<GetFlorgInvitationByPidQuery, GetFlorgInvitationByPidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFlorgInvitationByPidQuery, GetFlorgInvitationByPidQueryVariables>(
    GetFlorgInvitationByPidDocument,
    options,
  );
}
export function useGetFlorgInvitationByPidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFlorgInvitationByPidQuery, GetFlorgInvitationByPidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFlorgInvitationByPidQuery, GetFlorgInvitationByPidQueryVariables>(
    GetFlorgInvitationByPidDocument,
    options,
  );
}
export type GetFlorgInvitationByPidQueryHookResult = ReturnType<typeof useGetFlorgInvitationByPidQuery>;
export type GetFlorgInvitationByPidLazyQueryHookResult = ReturnType<typeof useGetFlorgInvitationByPidLazyQuery>;
export type GetFlorgInvitationByPidQueryResult = Apollo.QueryResult<
  GetFlorgInvitationByPidQuery,
  GetFlorgInvitationByPidQueryVariables
>;
export const CreateFlorgInvitationDocument = gql`
  mutation createFlorgInvitation($invitation: FlorgInvitationInput!) {
    createFlorgInvitation(invitation: $invitation) {
      pid
      inviteLink
      name
      contactPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      valid
      emailStatus
    }
  }
`;
export type CreateFlorgInvitationMutationFn = Apollo.MutationFunction<
  CreateFlorgInvitationMutation,
  CreateFlorgInvitationMutationVariables
>;

/**
 * __useCreateFlorgInvitationMutation__
 *
 * To run a mutation, you first call `useCreateFlorgInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFlorgInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFlorgInvitationMutation, { data, loading, error }] = useCreateFlorgInvitationMutation({
 *   variables: {
 *      invitation: // value for 'invitation'
 *   },
 * });
 */
export function useCreateFlorgInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFlorgInvitationMutation, CreateFlorgInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFlorgInvitationMutation, CreateFlorgInvitationMutationVariables>(
    CreateFlorgInvitationDocument,
    options,
  );
}
export type CreateFlorgInvitationMutationHookResult = ReturnType<typeof useCreateFlorgInvitationMutation>;
export type CreateFlorgInvitationMutationResult = Apollo.MutationResult<CreateFlorgInvitationMutation>;
export type CreateFlorgInvitationMutationOptions = Apollo.BaseMutationOptions<
  CreateFlorgInvitationMutation,
  CreateFlorgInvitationMutationVariables
>;
export const ResendFlorgInvitationDocument = gql`
  mutation resendFlorgInvitation($pid: ID!) {
    resendFlorgInvitation(pid: $pid) {
      pid
      inviteLink
      name
      contactPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      valid
      emailStatus
    }
  }
`;
export type ResendFlorgInvitationMutationFn = Apollo.MutationFunction<
  ResendFlorgInvitationMutation,
  ResendFlorgInvitationMutationVariables
>;

/**
 * __useResendFlorgInvitationMutation__
 *
 * To run a mutation, you first call `useResendFlorgInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendFlorgInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendFlorgInvitationMutation, { data, loading, error }] = useResendFlorgInvitationMutation({
 *   variables: {
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useResendFlorgInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendFlorgInvitationMutation, ResendFlorgInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendFlorgInvitationMutation, ResendFlorgInvitationMutationVariables>(
    ResendFlorgInvitationDocument,
    options,
  );
}
export type ResendFlorgInvitationMutationHookResult = ReturnType<typeof useResendFlorgInvitationMutation>;
export type ResendFlorgInvitationMutationResult = Apollo.MutationResult<ResendFlorgInvitationMutation>;
export type ResendFlorgInvitationMutationOptions = Apollo.BaseMutationOptions<
  ResendFlorgInvitationMutation,
  ResendFlorgInvitationMutationVariables
>;
export const DeleteSentFlorgInvitationsDocument = gql`
  mutation deleteSentFlorgInvitations($pids: [ID!]!) {
    deleteSentFlorgInvitations(pids: $pids) {
      status
      pids
      errorReasons {
        pid
        value
      }
    }
  }
`;
export type DeleteSentFlorgInvitationsMutationFn = Apollo.MutationFunction<
  DeleteSentFlorgInvitationsMutation,
  DeleteSentFlorgInvitationsMutationVariables
>;

/**
 * __useDeleteSentFlorgInvitationsMutation__
 *
 * To run a mutation, you first call `useDeleteSentFlorgInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSentFlorgInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSentFlorgInvitationsMutation, { data, loading, error }] = useDeleteSentFlorgInvitationsMutation({
 *   variables: {
 *      pids: // value for 'pids'
 *   },
 * });
 */
export function useDeleteSentFlorgInvitationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSentFlorgInvitationsMutation,
    DeleteSentFlorgInvitationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSentFlorgInvitationsMutation, DeleteSentFlorgInvitationsMutationVariables>(
    DeleteSentFlorgInvitationsDocument,
    options,
  );
}
export type DeleteSentFlorgInvitationsMutationHookResult = ReturnType<typeof useDeleteSentFlorgInvitationsMutation>;
export type DeleteSentFlorgInvitationsMutationResult = Apollo.MutationResult<DeleteSentFlorgInvitationsMutation>;
export type DeleteSentFlorgInvitationsMutationOptions = Apollo.BaseMutationOptions<
  DeleteSentFlorgInvitationsMutation,
  DeleteSentFlorgInvitationsMutationVariables
>;
export const DeleteReceivedFlorgInvitationsDocument = gql`
  mutation deleteReceivedFlorgInvitations($pids: [ID!]!) {
    deleteReceivedFlorgInvitations(pids: $pids) {
      status
      pids
      errorReasons {
        pid
        value
      }
    }
  }
`;
export type DeleteReceivedFlorgInvitationsMutationFn = Apollo.MutationFunction<
  DeleteReceivedFlorgInvitationsMutation,
  DeleteReceivedFlorgInvitationsMutationVariables
>;

/**
 * __useDeleteReceivedFlorgInvitationsMutation__
 *
 * To run a mutation, you first call `useDeleteReceivedFlorgInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReceivedFlorgInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReceivedFlorgInvitationsMutation, { data, loading, error }] = useDeleteReceivedFlorgInvitationsMutation({
 *   variables: {
 *      pids: // value for 'pids'
 *   },
 * });
 */
export function useDeleteReceivedFlorgInvitationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReceivedFlorgInvitationsMutation,
    DeleteReceivedFlorgInvitationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteReceivedFlorgInvitationsMutation, DeleteReceivedFlorgInvitationsMutationVariables>(
    DeleteReceivedFlorgInvitationsDocument,
    options,
  );
}
export type DeleteReceivedFlorgInvitationsMutationHookResult = ReturnType<
  typeof useDeleteReceivedFlorgInvitationsMutation
>;
export type DeleteReceivedFlorgInvitationsMutationResult =
  Apollo.MutationResult<DeleteReceivedFlorgInvitationsMutation>;
export type DeleteReceivedFlorgInvitationsMutationOptions = Apollo.BaseMutationOptions<
  DeleteReceivedFlorgInvitationsMutation,
  DeleteReceivedFlorgInvitationsMutationVariables
>;
export const AcceptReceivedFlorgInvitationsDocument = gql`
  mutation acceptReceivedFlorgInvitations($pids: [ID!]!) {
    acceptReceivedFlorgInvitations(pids: $pids) {
      pid
      inviteLink
      name
      accepted
      sendingTime
      receivingTime
      acceptanceTime
      sendingCompanyName
      sendingPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      contactPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      valid
      emailStatus
      inviterOrganizationType
      invitedOrganizationType
      inviterRelationRole
    }
  }
`;
export type AcceptReceivedFlorgInvitationsMutationFn = Apollo.MutationFunction<
  AcceptReceivedFlorgInvitationsMutation,
  AcceptReceivedFlorgInvitationsMutationVariables
>;

/**
 * __useAcceptReceivedFlorgInvitationsMutation__
 *
 * To run a mutation, you first call `useAcceptReceivedFlorgInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptReceivedFlorgInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptReceivedFlorgInvitationsMutation, { data, loading, error }] = useAcceptReceivedFlorgInvitationsMutation({
 *   variables: {
 *      pids: // value for 'pids'
 *   },
 * });
 */
export function useAcceptReceivedFlorgInvitationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptReceivedFlorgInvitationsMutation,
    AcceptReceivedFlorgInvitationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptReceivedFlorgInvitationsMutation, AcceptReceivedFlorgInvitationsMutationVariables>(
    AcceptReceivedFlorgInvitationsDocument,
    options,
  );
}
export type AcceptReceivedFlorgInvitationsMutationHookResult = ReturnType<
  typeof useAcceptReceivedFlorgInvitationsMutation
>;
export type AcceptReceivedFlorgInvitationsMutationResult =
  Apollo.MutationResult<AcceptReceivedFlorgInvitationsMutation>;
export type AcceptReceivedFlorgInvitationsMutationOptions = Apollo.BaseMutationOptions<
  AcceptReceivedFlorgInvitationsMutation,
  AcceptReceivedFlorgInvitationsMutationVariables
>;
export const ReceiveFlorgInvitationDocument = gql`
  mutation receiveFlorgInvitation($token: String!) {
    receiveFlorgInvitation(token: $token) {
      pid
      inviteLink
      name
      accepted
      sendingTime
      receivingTime
      acceptanceTime
      sendingCompanyName
      sendingPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      contactPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      valid
      emailStatus
      inviterOrganizationType
      invitedOrganizationType
      inviterRelationRole
    }
  }
`;
export type ReceiveFlorgInvitationMutationFn = Apollo.MutationFunction<
  ReceiveFlorgInvitationMutation,
  ReceiveFlorgInvitationMutationVariables
>;

/**
 * __useReceiveFlorgInvitationMutation__
 *
 * To run a mutation, you first call `useReceiveFlorgInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReceiveFlorgInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [receiveFlorgInvitationMutation, { data, loading, error }] = useReceiveFlorgInvitationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useReceiveFlorgInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<ReceiveFlorgInvitationMutation, ReceiveFlorgInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReceiveFlorgInvitationMutation, ReceiveFlorgInvitationMutationVariables>(
    ReceiveFlorgInvitationDocument,
    options,
  );
}
export type ReceiveFlorgInvitationMutationHookResult = ReturnType<typeof useReceiveFlorgInvitationMutation>;
export type ReceiveFlorgInvitationMutationResult = Apollo.MutationResult<ReceiveFlorgInvitationMutation>;
export type ReceiveFlorgInvitationMutationOptions = Apollo.BaseMutationOptions<
  ReceiveFlorgInvitationMutation,
  ReceiveFlorgInvitationMutationVariables
>;
export const GetConnectedFlorgsDocument = gql`
  query getConnectedFlorgs {
    getConnectedFlorgs {
      id
      name
      iconUrl
      connectionType
      apiName
    }
  }
`;

/**
 * __useGetConnectedFlorgsQuery__
 *
 * To run a query within a React component, call `useGetConnectedFlorgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectedFlorgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectedFlorgsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConnectedFlorgsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetConnectedFlorgsQuery, GetConnectedFlorgsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConnectedFlorgsQuery, GetConnectedFlorgsQueryVariables>(
    GetConnectedFlorgsDocument,
    options,
  );
}
export function useGetConnectedFlorgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConnectedFlorgsQuery, GetConnectedFlorgsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConnectedFlorgsQuery, GetConnectedFlorgsQueryVariables>(
    GetConnectedFlorgsDocument,
    options,
  );
}
export type GetConnectedFlorgsQueryHookResult = ReturnType<typeof useGetConnectedFlorgsQuery>;
export type GetConnectedFlorgsLazyQueryHookResult = ReturnType<typeof useGetConnectedFlorgsLazyQuery>;
export type GetConnectedFlorgsQueryResult = Apollo.QueryResult<
  GetConnectedFlorgsQuery,
  GetConnectedFlorgsQueryVariables
>;
export const GetConnectedFlorgDocument = gql`
  query getConnectedFlorg($pid: ID!) {
    getConnectedFlorg(pid: $pid) {
      id
      name
      iconUrl
      taxCode
      createdDate
      address
      grantedFlorgs {
        id
        name
        connectionType
        iconUrl
        apiName
      }
      florgsGrantedAccessToThisFlorg {
        id
        name
        connectionType
        iconUrl
        apiName
      }
      companyDetails {
        vatNumber
        registrationNumber
        address {
          pid
          addressLine1
          addressLine2
          postCode
          city
          country
        }
      }
      contactPerson {
        firstName
        lastName
        email
        phoneNumber
      }
      companySignatory {
        firstName
        lastName
        email
        phoneNumber
      }
      invoicingContact {
        firstName
        lastName
        email
        phoneNumber
      }
      invitedBy
      organizationTypes {
        key
        description
      }
      disabled
      tispFlorgPid
    }
  }
`;

/**
 * __useGetConnectedFlorgQuery__
 *
 * To run a query within a React component, call `useGetConnectedFlorgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectedFlorgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectedFlorgQuery({
 *   variables: {
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useGetConnectedFlorgQuery(
  baseOptions: Apollo.QueryHookOptions<GetConnectedFlorgQuery, GetConnectedFlorgQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConnectedFlorgQuery, GetConnectedFlorgQueryVariables>(GetConnectedFlorgDocument, options);
}
export function useGetConnectedFlorgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConnectedFlorgQuery, GetConnectedFlorgQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConnectedFlorgQuery, GetConnectedFlorgQueryVariables>(
    GetConnectedFlorgDocument,
    options,
  );
}
export type GetConnectedFlorgQueryHookResult = ReturnType<typeof useGetConnectedFlorgQuery>;
export type GetConnectedFlorgLazyQueryHookResult = ReturnType<typeof useGetConnectedFlorgLazyQuery>;
export type GetConnectedFlorgQueryResult = Apollo.QueryResult<GetConnectedFlorgQuery, GetConnectedFlorgQueryVariables>;
export const AcceptConnectionsToFlorgDocument = gql`
  mutation acceptConnectionsToFlorg($pids: [ID!]!) {
    acceptConnectionsToFlorg(pids: $pids) {
      id
      name
      connectionType
      iconUrl
      apiName
    }
  }
`;
export type AcceptConnectionsToFlorgMutationFn = Apollo.MutationFunction<
  AcceptConnectionsToFlorgMutation,
  AcceptConnectionsToFlorgMutationVariables
>;

/**
 * __useAcceptConnectionsToFlorgMutation__
 *
 * To run a mutation, you first call `useAcceptConnectionsToFlorgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptConnectionsToFlorgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptConnectionsToFlorgMutation, { data, loading, error }] = useAcceptConnectionsToFlorgMutation({
 *   variables: {
 *      pids: // value for 'pids'
 *   },
 * });
 */
export function useAcceptConnectionsToFlorgMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptConnectionsToFlorgMutation, AcceptConnectionsToFlorgMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptConnectionsToFlorgMutation, AcceptConnectionsToFlorgMutationVariables>(
    AcceptConnectionsToFlorgDocument,
    options,
  );
}
export type AcceptConnectionsToFlorgMutationHookResult = ReturnType<typeof useAcceptConnectionsToFlorgMutation>;
export type AcceptConnectionsToFlorgMutationResult = Apollo.MutationResult<AcceptConnectionsToFlorgMutation>;
export type AcceptConnectionsToFlorgMutationOptions = Apollo.BaseMutationOptions<
  AcceptConnectionsToFlorgMutation,
  AcceptConnectionsToFlorgMutationVariables
>;
export const DeleteConnectionsToFlorgDocument = gql`
  mutation deleteConnectionsToFlorg($pids: [ID!]!) {
    deleteConnectionsToFlorg(pids: $pids) {
      status
      pids
      errorReasons {
        pid
        value
      }
    }
  }
`;
export type DeleteConnectionsToFlorgMutationFn = Apollo.MutationFunction<
  DeleteConnectionsToFlorgMutation,
  DeleteConnectionsToFlorgMutationVariables
>;

/**
 * __useDeleteConnectionsToFlorgMutation__
 *
 * To run a mutation, you first call `useDeleteConnectionsToFlorgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConnectionsToFlorgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConnectionsToFlorgMutation, { data, loading, error }] = useDeleteConnectionsToFlorgMutation({
 *   variables: {
 *      pids: // value for 'pids'
 *   },
 * });
 */
export function useDeleteConnectionsToFlorgMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteConnectionsToFlorgMutation, DeleteConnectionsToFlorgMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteConnectionsToFlorgMutation, DeleteConnectionsToFlorgMutationVariables>(
    DeleteConnectionsToFlorgDocument,
    options,
  );
}
export type DeleteConnectionsToFlorgMutationHookResult = ReturnType<typeof useDeleteConnectionsToFlorgMutation>;
export type DeleteConnectionsToFlorgMutationResult = Apollo.MutationResult<DeleteConnectionsToFlorgMutation>;
export type DeleteConnectionsToFlorgMutationOptions = Apollo.BaseMutationOptions<
  DeleteConnectionsToFlorgMutation,
  DeleteConnectionsToFlorgMutationVariables
>;
export const GetCustomReportForCarrierDocument = gql`
  query GetCustomReportForCarrier($filters: GhgReportFiltersInput) {
    getGhgReportTransportOrderDataForCarrier(filters: $filters) {
      transportOrderId
      transportOrderNumber
      fromTime
      toTime
      from
      to
      transportModes
      carrierName
      co2WTT
      co2TTW
      co2WTW
      weight
      distance
      sustainabilityScore
      trueTransparency
      transportIds
    }
  }
`;

/**
 * __useGetCustomReportForCarrierQuery__
 *
 * To run a query within a React component, call `useGetCustomReportForCarrierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomReportForCarrierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomReportForCarrierQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCustomReportForCarrierQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomReportForCarrierQuery, GetCustomReportForCarrierQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomReportForCarrierQuery, GetCustomReportForCarrierQueryVariables>(
    GetCustomReportForCarrierDocument,
    options,
  );
}
export function useGetCustomReportForCarrierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomReportForCarrierQuery, GetCustomReportForCarrierQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomReportForCarrierQuery, GetCustomReportForCarrierQueryVariables>(
    GetCustomReportForCarrierDocument,
    options,
  );
}
export type GetCustomReportForCarrierQueryHookResult = ReturnType<typeof useGetCustomReportForCarrierQuery>;
export type GetCustomReportForCarrierLazyQueryHookResult = ReturnType<typeof useGetCustomReportForCarrierLazyQuery>;
export type GetCustomReportForCarrierQueryResult = Apollo.QueryResult<
  GetCustomReportForCarrierQuery,
  GetCustomReportForCarrierQueryVariables
>;
export const GetCustomTransportReportForCarrierDocument = gql`
  query GetCustomTransportReportForCarrier($filters: GhgReportFiltersInput) {
    getGhgReportTransportDataForCarrier(filters: $filters) {
      transportPid
      transportNumber
      fromTime
      toTime
      from
      to
      transportMode
      carrierName
      vehicleName
      vehicleFlorgName
      co2WTT
      co2TTW
      weight
      distance
      fuel
      orderNumbers
      sustainabilityScore
      trueTransparency
    }
  }
`;

/**
 * __useGetCustomTransportReportForCarrierQuery__
 *
 * To run a query within a React component, call `useGetCustomTransportReportForCarrierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTransportReportForCarrierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTransportReportForCarrierQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCustomTransportReportForCarrierQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomTransportReportForCarrierQuery,
    GetCustomTransportReportForCarrierQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomTransportReportForCarrierQuery, GetCustomTransportReportForCarrierQueryVariables>(
    GetCustomTransportReportForCarrierDocument,
    options,
  );
}
export function useGetCustomTransportReportForCarrierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomTransportReportForCarrierQuery,
    GetCustomTransportReportForCarrierQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomTransportReportForCarrierQuery, GetCustomTransportReportForCarrierQueryVariables>(
    GetCustomTransportReportForCarrierDocument,
    options,
  );
}
export type GetCustomTransportReportForCarrierQueryHookResult = ReturnType<
  typeof useGetCustomTransportReportForCarrierQuery
>;
export type GetCustomTransportReportForCarrierLazyQueryHookResult = ReturnType<
  typeof useGetCustomTransportReportForCarrierLazyQuery
>;
export type GetCustomTransportReportForCarrierQueryResult = Apollo.QueryResult<
  GetCustomTransportReportForCarrierQuery,
  GetCustomTransportReportForCarrierQueryVariables
>;
export const GetCustomReportForTransportBuyerDocument = gql`
  query GetCustomReportForTransportBuyer($filters: GhgReportFiltersInput) {
    getGhgReportTransportOrderDataForTransportBuyer(filters: $filters) {
      transportOrderId
      transportOrderNumber
      fromTime
      toTime
      from
      to
      transportModes
      carrierName
      co2WTT
      co2TTW
      co2WTW
      weight
      distance
      sustainabilityScore
      trueTransparency
      transportIds
    }
  }
`;

/**
 * __useGetCustomReportForTransportBuyerQuery__
 *
 * To run a query within a React component, call `useGetCustomReportForTransportBuyerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomReportForTransportBuyerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomReportForTransportBuyerQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCustomReportForTransportBuyerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomReportForTransportBuyerQuery,
    GetCustomReportForTransportBuyerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomReportForTransportBuyerQuery, GetCustomReportForTransportBuyerQueryVariables>(
    GetCustomReportForTransportBuyerDocument,
    options,
  );
}
export function useGetCustomReportForTransportBuyerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomReportForTransportBuyerQuery,
    GetCustomReportForTransportBuyerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomReportForTransportBuyerQuery, GetCustomReportForTransportBuyerQueryVariables>(
    GetCustomReportForTransportBuyerDocument,
    options,
  );
}
export type GetCustomReportForTransportBuyerQueryHookResult = ReturnType<
  typeof useGetCustomReportForTransportBuyerQuery
>;
export type GetCustomReportForTransportBuyerLazyQueryHookResult = ReturnType<
  typeof useGetCustomReportForTransportBuyerLazyQuery
>;
export type GetCustomReportForTransportBuyerQueryResult = Apollo.QueryResult<
  GetCustomReportForTransportBuyerQuery,
  GetCustomReportForTransportBuyerQueryVariables
>;
export const GetCustomTransportReportForTransportBuyerDocument = gql`
  query GetCustomTransportReportForTransportBuyer($filters: GhgReportFiltersInput) {
    getGhgReportTransportDataForTransportBuyer(filters: $filters) {
      transportPid
      transportNumber
      fromTime
      toTime
      from
      to
      transportMode
      carrierName
      co2WTT
      co2TTW
      co2WTW
      weight
      distance
      fuel
      orderNumbers
      sustainabilityScore
      trueTransparency
    }
  }
`;

/**
 * __useGetCustomTransportReportForTransportBuyerQuery__
 *
 * To run a query within a React component, call `useGetCustomTransportReportForTransportBuyerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTransportReportForTransportBuyerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTransportReportForTransportBuyerQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCustomTransportReportForTransportBuyerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomTransportReportForTransportBuyerQuery,
    GetCustomTransportReportForTransportBuyerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomTransportReportForTransportBuyerQuery,
    GetCustomTransportReportForTransportBuyerQueryVariables
  >(GetCustomTransportReportForTransportBuyerDocument, options);
}
export function useGetCustomTransportReportForTransportBuyerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomTransportReportForTransportBuyerQuery,
    GetCustomTransportReportForTransportBuyerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomTransportReportForTransportBuyerQuery,
    GetCustomTransportReportForTransportBuyerQueryVariables
  >(GetCustomTransportReportForTransportBuyerDocument, options);
}
export type GetCustomTransportReportForTransportBuyerQueryHookResult = ReturnType<
  typeof useGetCustomTransportReportForTransportBuyerQuery
>;
export type GetCustomTransportReportForTransportBuyerLazyQueryHookResult = ReturnType<
  typeof useGetCustomTransportReportForTransportBuyerLazyQuery
>;
export type GetCustomTransportReportForTransportBuyerQueryResult = Apollo.QueryResult<
  GetCustomTransportReportForTransportBuyerQuery,
  GetCustomTransportReportForTransportBuyerQueryVariables
>;
export const GetDashboardDocument = gql`
  query getDashboard($dashboardInput: DashboardInput!) {
    getDashboard(dashboardInput: $dashboardInput) {
      previousPeriod {
        from
        to
      }
      widgets {
        engineMix {
          businessType
          item {
            items {
              fuelType
              lineHaul
              lastMile
            }
          }
        }
        itemsTransported {
          businessType
          item {
            lineHaul
            lastMile
            periodDifference
            total
          }
        }
        transports {
          businessType
          item {
            lineHaul
            lastMile
            periodDifference
            total
          }
        }
        goodsVolume {
          businessType
          item {
            lineHaul
            lastMile
            total
            periodDifference
          }
        }
        co2e {
          businessType
          item {
            lineHaul
            lastMile
            total
            periodDifference
            perKm
            perUnit
          }
        }
        targets {
          type
          current
          goal
          metrics {
            date
            current
            target
          }
          periodDifference
        }
      }
    }
  }
`;

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *      dashboardInput: // value for 'dashboardInput'
 *   },
 * });
 */
export function useGetDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
}
export function useGetDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
}
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardQueryResult = Apollo.QueryResult<GetDashboardQuery, GetDashboardQueryVariables>;
export const GetEnvironmentalClassesDocument = gql`
  query GetEnvironmentalClasses {
    getEnvironmentalClasses {
      id
      label
    }
  }
`;

/**
 * __useGetEnvironmentalClassesQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentalClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentalClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentalClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnvironmentalClassesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEnvironmentalClassesQuery, GetEnvironmentalClassesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEnvironmentalClassesQuery, GetEnvironmentalClassesQueryVariables>(
    GetEnvironmentalClassesDocument,
    options,
  );
}
export function useGetEnvironmentalClassesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEnvironmentalClassesQuery, GetEnvironmentalClassesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEnvironmentalClassesQuery, GetEnvironmentalClassesQueryVariables>(
    GetEnvironmentalClassesDocument,
    options,
  );
}
export type GetEnvironmentalClassesQueryHookResult = ReturnType<typeof useGetEnvironmentalClassesQuery>;
export type GetEnvironmentalClassesLazyQueryHookResult = ReturnType<typeof useGetEnvironmentalClassesLazyQuery>;
export type GetEnvironmentalClassesQueryResult = Apollo.QueryResult<
  GetEnvironmentalClassesQuery,
  GetEnvironmentalClassesQueryVariables
>;
export const UpdateFlorgApiNamesDocument = gql`
  mutation UpdateFlorgApiNames($apiNameInputs: [FlorgApiNameInput!]!) {
    updateFlorgApiNames(apiNameInputs: $apiNameInputs) {
      id
      name
      apiName
    }
  }
`;
export type UpdateFlorgApiNamesMutationFn = Apollo.MutationFunction<
  UpdateFlorgApiNamesMutation,
  UpdateFlorgApiNamesMutationVariables
>;

/**
 * __useUpdateFlorgApiNamesMutation__
 *
 * To run a mutation, you first call `useUpdateFlorgApiNamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlorgApiNamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlorgApiNamesMutation, { data, loading, error }] = useUpdateFlorgApiNamesMutation({
 *   variables: {
 *      apiNameInputs: // value for 'apiNameInputs'
 *   },
 * });
 */
export function useUpdateFlorgApiNamesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFlorgApiNamesMutation, UpdateFlorgApiNamesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFlorgApiNamesMutation, UpdateFlorgApiNamesMutationVariables>(
    UpdateFlorgApiNamesDocument,
    options,
  );
}
export type UpdateFlorgApiNamesMutationHookResult = ReturnType<typeof useUpdateFlorgApiNamesMutation>;
export type UpdateFlorgApiNamesMutationResult = Apollo.MutationResult<UpdateFlorgApiNamesMutation>;
export type UpdateFlorgApiNamesMutationOptions = Apollo.BaseMutationOptions<
  UpdateFlorgApiNamesMutation,
  UpdateFlorgApiNamesMutationVariables
>;
export const GetAllFlorgsForCurrentUserDocument = gql`
  query GetAllFlorgsForCurrentUser {
    getAllFlorgsForCurrentUser {
      id
      name
      iconUrl
      disabled
    }
  }
`;

/**
 * __useGetAllFlorgsForCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetAllFlorgsForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFlorgsForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFlorgsForCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllFlorgsForCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllFlorgsForCurrentUserQuery, GetAllFlorgsForCurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllFlorgsForCurrentUserQuery, GetAllFlorgsForCurrentUserQueryVariables>(
    GetAllFlorgsForCurrentUserDocument,
    options,
  );
}
export function useGetAllFlorgsForCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllFlorgsForCurrentUserQuery, GetAllFlorgsForCurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllFlorgsForCurrentUserQuery, GetAllFlorgsForCurrentUserQueryVariables>(
    GetAllFlorgsForCurrentUserDocument,
    options,
  );
}
export type GetAllFlorgsForCurrentUserQueryHookResult = ReturnType<typeof useGetAllFlorgsForCurrentUserQuery>;
export type GetAllFlorgsForCurrentUserLazyQueryHookResult = ReturnType<typeof useGetAllFlorgsForCurrentUserLazyQuery>;
export type GetAllFlorgsForCurrentUserQueryResult = Apollo.QueryResult<
  GetAllFlorgsForCurrentUserQuery,
  GetAllFlorgsForCurrentUserQueryVariables
>;
export const ChangeActiveFlorgDocument = gql`
  mutation ChangeActiveFlorg($florgPid: String!) {
    changeActiveFlorg(florgPid: $florgPid) {
      id
      name
      iconUrl
      disabled
    }
  }
`;
export type ChangeActiveFlorgMutationFn = Apollo.MutationFunction<
  ChangeActiveFlorgMutation,
  ChangeActiveFlorgMutationVariables
>;

/**
 * __useChangeActiveFlorgMutation__
 *
 * To run a mutation, you first call `useChangeActiveFlorgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeActiveFlorgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeActiveFlorgMutation, { data, loading, error }] = useChangeActiveFlorgMutation({
 *   variables: {
 *      florgPid: // value for 'florgPid'
 *   },
 * });
 */
export function useChangeActiveFlorgMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeActiveFlorgMutation, ChangeActiveFlorgMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeActiveFlorgMutation, ChangeActiveFlorgMutationVariables>(
    ChangeActiveFlorgDocument,
    options,
  );
}
export type ChangeActiveFlorgMutationHookResult = ReturnType<typeof useChangeActiveFlorgMutation>;
export type ChangeActiveFlorgMutationResult = Apollo.MutationResult<ChangeActiveFlorgMutation>;
export type ChangeActiveFlorgMutationOptions = Apollo.BaseMutationOptions<
  ChangeActiveFlorgMutation,
  ChangeActiveFlorgMutationVariables
>;
export const GetProductsFromOrderItemsDocument = gql`
  query GetProductsFromOrderItems($filter: SearchFilterInput) {
    getProductsFromOrderItems(filter: $filter) {
      productNumber
      goodsType
    }
  }
`;

/**
 * __useGetProductsFromOrderItemsQuery__
 *
 * To run a query within a React component, call `useGetProductsFromOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsFromOrderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsFromOrderItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetProductsFromOrderItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProductsFromOrderItemsQuery, GetProductsFromOrderItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsFromOrderItemsQuery, GetProductsFromOrderItemsQueryVariables>(
    GetProductsFromOrderItemsDocument,
    options,
  );
}
export function useGetProductsFromOrderItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductsFromOrderItemsQuery, GetProductsFromOrderItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsFromOrderItemsQuery, GetProductsFromOrderItemsQueryVariables>(
    GetProductsFromOrderItemsDocument,
    options,
  );
}
export type GetProductsFromOrderItemsQueryHookResult = ReturnType<typeof useGetProductsFromOrderItemsQuery>;
export type GetProductsFromOrderItemsLazyQueryHookResult = ReturnType<typeof useGetProductsFromOrderItemsLazyQuery>;
export type GetProductsFromOrderItemsQueryResult = Apollo.QueryResult<
  GetProductsFromOrderItemsQuery,
  GetProductsFromOrderItemsQueryVariables
>;
export const GetProductsForAssignedTransportOrdersDocument = gql`
  query GetProductsForAssignedTransportOrders($filter: SearchFilterInput) {
    getProductsForAssignedTransportOrders(filter: $filter) {
      productNumber
      goodsType
    }
  }
`;

/**
 * __useGetProductsForAssignedTransportOrdersQuery__
 *
 * To run a query within a React component, call `useGetProductsForAssignedTransportOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsForAssignedTransportOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsForAssignedTransportOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetProductsForAssignedTransportOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductsForAssignedTransportOrdersQuery,
    GetProductsForAssignedTransportOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductsForAssignedTransportOrdersQuery,
    GetProductsForAssignedTransportOrdersQueryVariables
  >(GetProductsForAssignedTransportOrdersDocument, options);
}
export function useGetProductsForAssignedTransportOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsForAssignedTransportOrdersQuery,
    GetProductsForAssignedTransportOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsForAssignedTransportOrdersQuery,
    GetProductsForAssignedTransportOrdersQueryVariables
  >(GetProductsForAssignedTransportOrdersDocument, options);
}
export type GetProductsForAssignedTransportOrdersQueryHookResult = ReturnType<
  typeof useGetProductsForAssignedTransportOrdersQuery
>;
export type GetProductsForAssignedTransportOrdersLazyQueryHookResult = ReturnType<
  typeof useGetProductsForAssignedTransportOrdersLazyQuery
>;
export type GetProductsForAssignedTransportOrdersQueryResult = Apollo.QueryResult<
  GetProductsForAssignedTransportOrdersQuery,
  GetProductsForAssignedTransportOrdersQueryVariables
>;
export const GetReportForCarrierDocument = gql`
  query GetReportForCarrier($filters: GhgReportFiltersInput) {
    getGhgReportDataForCarrier(filters: $filters) {
      vehicleName
      goodsDescription
      co2Usage
      noxUsage
      pmUsage
      coUsage
      hcUsage
      so2Usage
      routeName
      percentageOfCargo
      carrierName
      productNumber
      fromTime
      toTime
      fuelUsedInLitres
      delayInMin
      transportNumber
      transportOrderNumber
      transportBuyerName
      co2WTT
      co2TTW
      co2WTW
    }
  }
`;

/**
 * __useGetReportForCarrierQuery__
 *
 * To run a query within a React component, call `useGetReportForCarrierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportForCarrierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportForCarrierQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetReportForCarrierQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReportForCarrierQuery, GetReportForCarrierQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportForCarrierQuery, GetReportForCarrierQueryVariables>(
    GetReportForCarrierDocument,
    options,
  );
}
export function useGetReportForCarrierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportForCarrierQuery, GetReportForCarrierQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportForCarrierQuery, GetReportForCarrierQueryVariables>(
    GetReportForCarrierDocument,
    options,
  );
}
export type GetReportForCarrierQueryHookResult = ReturnType<typeof useGetReportForCarrierQuery>;
export type GetReportForCarrierLazyQueryHookResult = ReturnType<typeof useGetReportForCarrierLazyQuery>;
export type GetReportForCarrierQueryResult = Apollo.QueryResult<
  GetReportForCarrierQuery,
  GetReportForCarrierQueryVariables
>;
export const GetReportForTransportBuyerDocument = gql`
  query GetReportForTransportBuyer($filters: GhgReportFiltersInput) {
    getGhgReportDataForTransportBuyer(filters: $filters) {
      vehicleName
      goodsDescription
      co2Usage
      noxUsage
      pmUsage
      coUsage
      hcUsage
      so2Usage
      routeName
      percentageOfCargo
      carrierName
      productNumber
      fromTime
      toTime
      fuelUsedInLitres
      delayInMin
      transportNumber
      transportOrderNumber
      transportBuyerName
      co2WTT
      co2TTW
    }
  }
`;

/**
 * __useGetReportForTransportBuyerQuery__
 *
 * To run a query within a React component, call `useGetReportForTransportBuyerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportForTransportBuyerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportForTransportBuyerQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetReportForTransportBuyerQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReportForTransportBuyerQuery, GetReportForTransportBuyerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportForTransportBuyerQuery, GetReportForTransportBuyerQueryVariables>(
    GetReportForTransportBuyerDocument,
    options,
  );
}
export function useGetReportForTransportBuyerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportForTransportBuyerQuery, GetReportForTransportBuyerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportForTransportBuyerQuery, GetReportForTransportBuyerQueryVariables>(
    GetReportForTransportBuyerDocument,
    options,
  );
}
export type GetReportForTransportBuyerQueryHookResult = ReturnType<typeof useGetReportForTransportBuyerQuery>;
export type GetReportForTransportBuyerLazyQueryHookResult = ReturnType<typeof useGetReportForTransportBuyerLazyQuery>;
export type GetReportForTransportBuyerQueryResult = Apollo.QueryResult<
  GetReportForTransportBuyerQuery,
  GetReportForTransportBuyerQueryVariables
>;
export const GetRoutesDocument = gql`
  query GetRoutes($filter: RouteFilterInput) {
    getRoutes(filter: $filter) {
      pid
      from {
        name
      }
      to {
        name
      }
    }
  }
`;

/**
 * __useGetRoutesQuery__
 *
 * To run a query within a React component, call `useGetRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetRoutesQuery(baseOptions?: Apollo.QueryHookOptions<GetRoutesQuery, GetRoutesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoutesQuery, GetRoutesQueryVariables>(GetRoutesDocument, options);
}
export function useGetRoutesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoutesQuery, GetRoutesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoutesQuery, GetRoutesQueryVariables>(GetRoutesDocument, options);
}
export type GetRoutesQueryHookResult = ReturnType<typeof useGetRoutesQuery>;
export type GetRoutesLazyQueryHookResult = ReturnType<typeof useGetRoutesLazyQuery>;
export type GetRoutesQueryResult = Apollo.QueryResult<GetRoutesQuery, GetRoutesQueryVariables>;
export const GetRouteDocument = gql`
  query GetRoute($pid: String!) {
    getRoute(pid: $pid) {
      pid
      from {
        name
      }
      to {
        name
      }
    }
  }
`;

/**
 * __useGetRouteQuery__
 *
 * To run a query within a React component, call `useGetRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteQuery({
 *   variables: {
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useGetRouteQuery(baseOptions: Apollo.QueryHookOptions<GetRouteQuery, GetRouteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRouteQuery, GetRouteQueryVariables>(GetRouteDocument, options);
}
export function useGetRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteQuery, GetRouteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRouteQuery, GetRouteQueryVariables>(GetRouteDocument, options);
}
export type GetRouteQueryHookResult = ReturnType<typeof useGetRouteQuery>;
export type GetRouteLazyQueryHookResult = ReturnType<typeof useGetRouteLazyQuery>;
export type GetRouteQueryResult = Apollo.QueryResult<GetRouteQuery, GetRouteQueryVariables>;
export const StoreRouteDocument = gql`
  mutation StoreRoute($route: RouteInput!) {
    storeRoute(route: $route) {
      pid
      from {
        name
      }
      to {
        name
      }
    }
  }
`;
export type StoreRouteMutationFn = Apollo.MutationFunction<StoreRouteMutation, StoreRouteMutationVariables>;

/**
 * __useStoreRouteMutation__
 *
 * To run a mutation, you first call `useStoreRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeRouteMutation, { data, loading, error }] = useStoreRouteMutation({
 *   variables: {
 *      route: // value for 'route'
 *   },
 * });
 */
export function useStoreRouteMutation(
  baseOptions?: Apollo.MutationHookOptions<StoreRouteMutation, StoreRouteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StoreRouteMutation, StoreRouteMutationVariables>(StoreRouteDocument, options);
}
export type StoreRouteMutationHookResult = ReturnType<typeof useStoreRouteMutation>;
export type StoreRouteMutationResult = Apollo.MutationResult<StoreRouteMutation>;
export type StoreRouteMutationOptions = Apollo.BaseMutationOptions<StoreRouteMutation, StoreRouteMutationVariables>;
export const DeleteRouteDocument = gql`
  mutation DeleteRoute($pid: String!) {
    deleteRoute(pid: $pid) {
      status
      pids
      errorReasons {
        pid
        value
      }
    }
  }
`;
export type DeleteRouteMutationFn = Apollo.MutationFunction<DeleteRouteMutation, DeleteRouteMutationVariables>;

/**
 * __useDeleteRouteMutation__
 *
 * To run a mutation, you first call `useDeleteRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRouteMutation, { data, loading, error }] = useDeleteRouteMutation({
 *   variables: {
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useDeleteRouteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRouteMutation, DeleteRouteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRouteMutation, DeleteRouteMutationVariables>(DeleteRouteDocument, options);
}
export type DeleteRouteMutationHookResult = ReturnType<typeof useDeleteRouteMutation>;
export type DeleteRouteMutationResult = Apollo.MutationResult<DeleteRouteMutation>;
export type DeleteRouteMutationOptions = Apollo.BaseMutationOptions<DeleteRouteMutation, DeleteRouteMutationVariables>;
export const GetReportRoutesDocument = gql`
  query GetReportRoutes {
    getReportRoutes {
      pid
      from {
        name
      }
      to {
        name
      }
    }
  }
`;

/**
 * __useGetReportRoutesQuery__
 *
 * To run a query within a React component, call `useGetReportRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportRoutesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReportRoutesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReportRoutesQuery, GetReportRoutesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportRoutesQuery, GetReportRoutesQueryVariables>(GetReportRoutesDocument, options);
}
export function useGetReportRoutesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportRoutesQuery, GetReportRoutesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportRoutesQuery, GetReportRoutesQueryVariables>(GetReportRoutesDocument, options);
}
export type GetReportRoutesQueryHookResult = ReturnType<typeof useGetReportRoutesQuery>;
export type GetReportRoutesLazyQueryHookResult = ReturnType<typeof useGetReportRoutesLazyQuery>;
export type GetReportRoutesQueryResult = Apollo.QueryResult<GetReportRoutesQuery, GetReportRoutesQueryVariables>;
export const GetScaniaUsersDocument = gql`
  query GetScaniaUsers {
    getScaniaUsers {
      pid
      name
    }
  }
`;

/**
 * __useGetScaniaUsersQuery__
 *
 * To run a query within a React component, call `useGetScaniaUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScaniaUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScaniaUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScaniaUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetScaniaUsersQuery, GetScaniaUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScaniaUsersQuery, GetScaniaUsersQueryVariables>(GetScaniaUsersDocument, options);
}
export function useGetScaniaUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetScaniaUsersQuery, GetScaniaUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScaniaUsersQuery, GetScaniaUsersQueryVariables>(GetScaniaUsersDocument, options);
}
export type GetScaniaUsersQueryHookResult = ReturnType<typeof useGetScaniaUsersQuery>;
export type GetScaniaUsersLazyQueryHookResult = ReturnType<typeof useGetScaniaUsersLazyQuery>;
export type GetScaniaUsersQueryResult = Apollo.QueryResult<GetScaniaUsersQuery, GetScaniaUsersQueryVariables>;
export const StoreScaniaUserDocument = gql`
  mutation StoreScaniaUser($storeUserRequest: StoreScaniaRfmsUserInput!) {
    storeScaniaUser(storeUserRequest: $storeUserRequest) {
      pid
      name
    }
  }
`;
export type StoreScaniaUserMutationFn = Apollo.MutationFunction<
  StoreScaniaUserMutation,
  StoreScaniaUserMutationVariables
>;

/**
 * __useStoreScaniaUserMutation__
 *
 * To run a mutation, you first call `useStoreScaniaUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreScaniaUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeScaniaUserMutation, { data, loading, error }] = useStoreScaniaUserMutation({
 *   variables: {
 *      storeUserRequest: // value for 'storeUserRequest'
 *   },
 * });
 */
export function useStoreScaniaUserMutation(
  baseOptions?: Apollo.MutationHookOptions<StoreScaniaUserMutation, StoreScaniaUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StoreScaniaUserMutation, StoreScaniaUserMutationVariables>(
    StoreScaniaUserDocument,
    options,
  );
}
export type StoreScaniaUserMutationHookResult = ReturnType<typeof useStoreScaniaUserMutation>;
export type StoreScaniaUserMutationResult = Apollo.MutationResult<StoreScaniaUserMutation>;
export type StoreScaniaUserMutationOptions = Apollo.BaseMutationOptions<
  StoreScaniaUserMutation,
  StoreScaniaUserMutationVariables
>;
export const GetSustainabilityScoresForFlorgDocument = gql`
  query getSustainabilityScoresForFlorg {
    getSustainabilityScoresForFlorg {
      threshold
      score
    }
  }
`;

/**
 * __useGetSustainabilityScoresForFlorgQuery__
 *
 * To run a query within a React component, call `useGetSustainabilityScoresForFlorgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSustainabilityScoresForFlorgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSustainabilityScoresForFlorgQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSustainabilityScoresForFlorgQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSustainabilityScoresForFlorgQuery,
    GetSustainabilityScoresForFlorgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSustainabilityScoresForFlorgQuery, GetSustainabilityScoresForFlorgQueryVariables>(
    GetSustainabilityScoresForFlorgDocument,
    options,
  );
}
export function useGetSustainabilityScoresForFlorgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSustainabilityScoresForFlorgQuery,
    GetSustainabilityScoresForFlorgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSustainabilityScoresForFlorgQuery, GetSustainabilityScoresForFlorgQueryVariables>(
    GetSustainabilityScoresForFlorgDocument,
    options,
  );
}
export type GetSustainabilityScoresForFlorgQueryHookResult = ReturnType<typeof useGetSustainabilityScoresForFlorgQuery>;
export type GetSustainabilityScoresForFlorgLazyQueryHookResult = ReturnType<
  typeof useGetSustainabilityScoresForFlorgLazyQuery
>;
export type GetSustainabilityScoresForFlorgQueryResult = Apollo.QueryResult<
  GetSustainabilityScoresForFlorgQuery,
  GetSustainabilityScoresForFlorgQueryVariables
>;
export const CreateSustainabilityScoresForFlorgDocument = gql`
  mutation createSustainabilityScoresForFlorg($scores: [FlorgSustainabilityScoreInput!]!) {
    createSustainabilityScoresForFlorg(scores: $scores) {
      score
      florgPid
    }
  }
`;
export type CreateSustainabilityScoresForFlorgMutationFn = Apollo.MutationFunction<
  CreateSustainabilityScoresForFlorgMutation,
  CreateSustainabilityScoresForFlorgMutationVariables
>;

/**
 * __useCreateSustainabilityScoresForFlorgMutation__
 *
 * To run a mutation, you first call `useCreateSustainabilityScoresForFlorgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSustainabilityScoresForFlorgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSustainabilityScoresForFlorgMutation, { data, loading, error }] = useCreateSustainabilityScoresForFlorgMutation({
 *   variables: {
 *      scores: // value for 'scores'
 *   },
 * });
 */
export function useCreateSustainabilityScoresForFlorgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSustainabilityScoresForFlorgMutation,
    CreateSustainabilityScoresForFlorgMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSustainabilityScoresForFlorgMutation,
    CreateSustainabilityScoresForFlorgMutationVariables
  >(CreateSustainabilityScoresForFlorgDocument, options);
}
export type CreateSustainabilityScoresForFlorgMutationHookResult = ReturnType<
  typeof useCreateSustainabilityScoresForFlorgMutation
>;
export type CreateSustainabilityScoresForFlorgMutationResult =
  Apollo.MutationResult<CreateSustainabilityScoresForFlorgMutation>;
export type CreateSustainabilityScoresForFlorgMutationOptions = Apollo.BaseMutationOptions<
  CreateSustainabilityScoresForFlorgMutation,
  CreateSustainabilityScoresForFlorgMutationVariables
>;
export const GetTransportOrdersDocument = gql`
  query GetTransportOrders($filter: SearchFilterInput) {
    getTransportOrders(filter: $filter) {
      id
      transportOrderNumber
    }
  }
`;

/**
 * __useGetTransportOrdersQuery__
 *
 * To run a query within a React component, call `useGetTransportOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTransportOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTransportOrdersQuery, GetTransportOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransportOrdersQuery, GetTransportOrdersQueryVariables>(
    GetTransportOrdersDocument,
    options,
  );
}
export function useGetTransportOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransportOrdersQuery, GetTransportOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransportOrdersQuery, GetTransportOrdersQueryVariables>(
    GetTransportOrdersDocument,
    options,
  );
}
export type GetTransportOrdersQueryHookResult = ReturnType<typeof useGetTransportOrdersQuery>;
export type GetTransportOrdersLazyQueryHookResult = ReturnType<typeof useGetTransportOrdersLazyQuery>;
export type GetTransportOrdersQueryResult = Apollo.QueryResult<
  GetTransportOrdersQuery,
  GetTransportOrdersQueryVariables
>;
export const StoreTransportOrderDocument = gql`
  mutation StoreTransportOrder($transportOrder: TransportOrderInput!) {
    storeTransportOrder(transportOrder: $transportOrder) {
      id
      pid
      transportOrderNumber
      wantedPickupTime
      wantedArrivalTime
      orderItems {
        id
        goodsWeight
        goodsType
        noOfItems
        totalWeightInKg
        goodsVolume
        totalVolumeInCd3
        transportItemType {
          id
          label
        }
        productNumber
      }
      assignedCarriers {
        id
        name
      }
      route {
        pid
      }
      transportItemType {
        id
        label
      }
      totalVolumeInCd3
      totalWeightInKg
      businessType
    }
  }
`;
export type StoreTransportOrderMutationFn = Apollo.MutationFunction<
  StoreTransportOrderMutation,
  StoreTransportOrderMutationVariables
>;

/**
 * __useStoreTransportOrderMutation__
 *
 * To run a mutation, you first call `useStoreTransportOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreTransportOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeTransportOrderMutation, { data, loading, error }] = useStoreTransportOrderMutation({
 *   variables: {
 *      transportOrder: // value for 'transportOrder'
 *   },
 * });
 */
export function useStoreTransportOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<StoreTransportOrderMutation, StoreTransportOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StoreTransportOrderMutation, StoreTransportOrderMutationVariables>(
    StoreTransportOrderDocument,
    options,
  );
}
export type StoreTransportOrderMutationHookResult = ReturnType<typeof useStoreTransportOrderMutation>;
export type StoreTransportOrderMutationResult = Apollo.MutationResult<StoreTransportOrderMutation>;
export type StoreTransportOrderMutationOptions = Apollo.BaseMutationOptions<
  StoreTransportOrderMutation,
  StoreTransportOrderMutationVariables
>;
export const StoreTransportOrdersDocument = gql`
  mutation StoreTransportOrders($transportOrders: [TransportOrderInput!]!) {
    storeTransportOrders(transportOrders: $transportOrders) {
      id
      pid
      transportOrderNumber
    }
  }
`;
export type StoreTransportOrdersMutationFn = Apollo.MutationFunction<
  StoreTransportOrdersMutation,
  StoreTransportOrdersMutationVariables
>;

/**
 * __useStoreTransportOrdersMutation__
 *
 * To run a mutation, you first call `useStoreTransportOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreTransportOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeTransportOrdersMutation, { data, loading, error }] = useStoreTransportOrdersMutation({
 *   variables: {
 *      transportOrders: // value for 'transportOrders'
 *   },
 * });
 */
export function useStoreTransportOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<StoreTransportOrdersMutation, StoreTransportOrdersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StoreTransportOrdersMutation, StoreTransportOrdersMutationVariables>(
    StoreTransportOrdersDocument,
    options,
  );
}
export type StoreTransportOrdersMutationHookResult = ReturnType<typeof useStoreTransportOrdersMutation>;
export type StoreTransportOrdersMutationResult = Apollo.MutationResult<StoreTransportOrdersMutation>;
export type StoreTransportOrdersMutationOptions = Apollo.BaseMutationOptions<
  StoreTransportOrdersMutation,
  StoreTransportOrdersMutationVariables
>;
export const DeleteTransportOrderDocument = gql`
  mutation DeleteTransportOrder($id: ID!) {
    deleteTransportOrder(id: $id) {
      pids
      status
      errorReasons {
        pid
        value
      }
    }
  }
`;
export type DeleteTransportOrderMutationFn = Apollo.MutationFunction<
  DeleteTransportOrderMutation,
  DeleteTransportOrderMutationVariables
>;

/**
 * __useDeleteTransportOrderMutation__
 *
 * To run a mutation, you first call `useDeleteTransportOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransportOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransportOrderMutation, { data, loading, error }] = useDeleteTransportOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTransportOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTransportOrderMutation, DeleteTransportOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTransportOrderMutation, DeleteTransportOrderMutationVariables>(
    DeleteTransportOrderDocument,
    options,
  );
}
export type DeleteTransportOrderMutationHookResult = ReturnType<typeof useDeleteTransportOrderMutation>;
export type DeleteTransportOrderMutationResult = Apollo.MutationResult<DeleteTransportOrderMutation>;
export type DeleteTransportOrderMutationOptions = Apollo.BaseMutationOptions<
  DeleteTransportOrderMutation,
  DeleteTransportOrderMutationVariables
>;
export const GetTransportOrderDocument = gql`
  query GetTransportOrder($id: ID!, $carrierPid: String) {
    getTransportOrder(id: $id, carrierPid: $carrierPid) {
      id
      pid
      transportOrderNumber
      wantedPickupTime
      wantedArrivalTime
      orderItems {
        id
        goodsWeight
        goodsType
        noOfItems
        totalWeightInKg
        goodsVolume
        totalVolumeInCd3
        transportItemType {
          id
          label
        }
        productNumber
      }
      assignedCarriers {
        id
        name
      }
      route {
        pid
      }
      transportItemType {
        id
        label
      }
      totalVolumeInCd3
      totalWeightInKg
      businessType
    }
  }
`;

/**
 * __useGetTransportOrderQuery__
 *
 * To run a query within a React component, call `useGetTransportOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      carrierPid: // value for 'carrierPid'
 *   },
 * });
 */
export function useGetTransportOrderQuery(
  baseOptions: Apollo.QueryHookOptions<GetTransportOrderQuery, GetTransportOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransportOrderQuery, GetTransportOrderQueryVariables>(GetTransportOrderDocument, options);
}
export function useGetTransportOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransportOrderQuery, GetTransportOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransportOrderQuery, GetTransportOrderQueryVariables>(
    GetTransportOrderDocument,
    options,
  );
}
export type GetTransportOrderQueryHookResult = ReturnType<typeof useGetTransportOrderQuery>;
export type GetTransportOrderLazyQueryHookResult = ReturnType<typeof useGetTransportOrderLazyQuery>;
export type GetTransportOrderQueryResult = Apollo.QueryResult<GetTransportOrderQuery, GetTransportOrderQueryVariables>;
export const GetTransportOrderWithEmissionsDocument = gql`
  query GetTransportOrderWithEmissions($id: ID!, $carrierPid: String) {
    getTransportOrder(id: $id, carrierPid: $carrierPid) {
      transportOrderNumber
      wantedPickupTime
      wantedArrivalTime
      orderItems {
        goodsWeight
        goodsType
        noOfItems
        totalWeightInKg
        goodsVolume
        totalVolumeInCd3
        transportItemType {
          id
          label
        }
        productNumber
      }
      transports {
        transportNumber
        startTime
        endTime
        vehicle {
          pid
          name
          vehicleBrand {
            id
            label
          }
          fuelType {
            id
            label
          }
          vin
        }
        kmDriven
        usedFuelInLitres
        energyUsedInKwh
        totalCargoWeight
        route {
          from {
            name
          }
          to {
            name
          }
        }
        mode
        co2e
        sustainabilityScore
        trueTransparency
      }
      route {
        from {
          name
        }
        to {
          name
        }
      }
      co2e
      trueTransparency
      transportItemType {
        id
        label
      }
      totalVolumeInCd3
      totalWeightInKg
      businessType
    }
  }
`;

/**
 * __useGetTransportOrderWithEmissionsQuery__
 *
 * To run a query within a React component, call `useGetTransportOrderWithEmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportOrderWithEmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportOrderWithEmissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      carrierPid: // value for 'carrierPid'
 *   },
 * });
 */
export function useGetTransportOrderWithEmissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTransportOrderWithEmissionsQuery,
    GetTransportOrderWithEmissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransportOrderWithEmissionsQuery, GetTransportOrderWithEmissionsQueryVariables>(
    GetTransportOrderWithEmissionsDocument,
    options,
  );
}
export function useGetTransportOrderWithEmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransportOrderWithEmissionsQuery,
    GetTransportOrderWithEmissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransportOrderWithEmissionsQuery, GetTransportOrderWithEmissionsQueryVariables>(
    GetTransportOrderWithEmissionsDocument,
    options,
  );
}
export type GetTransportOrderWithEmissionsQueryHookResult = ReturnType<typeof useGetTransportOrderWithEmissionsQuery>;
export type GetTransportOrderWithEmissionsLazyQueryHookResult = ReturnType<
  typeof useGetTransportOrderWithEmissionsLazyQuery
>;
export type GetTransportOrderWithEmissionsQueryResult = Apollo.QueryResult<
  GetTransportOrderWithEmissionsQuery,
  GetTransportOrderWithEmissionsQueryVariables
>;
export const GetTransportsDocument = gql`
  query GetTransports($filter: SearchFilterInput) {
    getTransports(filter: $filter) {
      pid
      transportNumber
      vehicle {
        name
      }
    }
  }
`;

/**
 * __useGetTransportsQuery__
 *
 * To run a query within a React component, call `useGetTransportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTransportsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTransportsQuery, GetTransportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransportsQuery, GetTransportsQueryVariables>(GetTransportsDocument, options);
}
export function useGetTransportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransportsQuery, GetTransportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransportsQuery, GetTransportsQueryVariables>(GetTransportsDocument, options);
}
export type GetTransportsQueryHookResult = ReturnType<typeof useGetTransportsQuery>;
export type GetTransportsLazyQueryHookResult = ReturnType<typeof useGetTransportsLazyQuery>;
export type GetTransportsQueryResult = Apollo.QueryResult<GetTransportsQuery, GetTransportsQueryVariables>;
export const GetTransportDocument = gql`
  query GetTransport($pid: ID!, $buyerPid: String) {
    getTransport(pid: $pid, buyerPid: $buyerPid) {
      pid
      transportNumber
      startTime
      endTime
      vehicle {
        pid
        name
        vehicleBrand {
          id
          label
        }
        fuelType {
          id
          label
        }
        vin
      }
      totalCargoWeight
      totalGoodsVolume
      kmDriven
      usedFuelInLitres
      energyUsedInKwh
      transportOrders {
        id
        pid
        transportOrderNumber
        completed
      }
      route {
        pid
        from {
          name
        }
        to {
          name
        }
        label
      }
      mode
      fuelQuality
    }
  }
`;

/**
 * __useGetTransportQuery__
 *
 * To run a query within a React component, call `useGetTransportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportQuery({
 *   variables: {
 *      pid: // value for 'pid'
 *      buyerPid: // value for 'buyerPid'
 *   },
 * });
 */
export function useGetTransportQuery(
  baseOptions: Apollo.QueryHookOptions<GetTransportQuery, GetTransportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransportQuery, GetTransportQueryVariables>(GetTransportDocument, options);
}
export function useGetTransportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransportQuery, GetTransportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransportQuery, GetTransportQueryVariables>(GetTransportDocument, options);
}
export type GetTransportQueryHookResult = ReturnType<typeof useGetTransportQuery>;
export type GetTransportLazyQueryHookResult = ReturnType<typeof useGetTransportLazyQuery>;
export type GetTransportQueryResult = Apollo.QueryResult<GetTransportQuery, GetTransportQueryVariables>;
export const StoreTransportDocument = gql`
  mutation StoreTransport($transport: TransportInput!) {
    storeTransport(transport: $transport) {
      pid
      transportNumber
      startTime
      endTime
      vehicle {
        pid
        name
        vehicleBrand {
          id
          label
        }
        fuelType {
          id
          label
        }
        vin
      }
      totalCargoWeight
      totalGoodsVolume
      kmDriven
      usedFuelInLitres
      energyUsedInKwh
      transportOrders {
        id
        pid
        transportOrderNumber
      }
      route {
        pid
        from {
          name
        }
        to {
          name
        }
      }
      mode
      fuelQuality
    }
  }
`;
export type StoreTransportMutationFn = Apollo.MutationFunction<StoreTransportMutation, StoreTransportMutationVariables>;

/**
 * __useStoreTransportMutation__
 *
 * To run a mutation, you first call `useStoreTransportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreTransportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeTransportMutation, { data, loading, error }] = useStoreTransportMutation({
 *   variables: {
 *      transport: // value for 'transport'
 *   },
 * });
 */
export function useStoreTransportMutation(
  baseOptions?: Apollo.MutationHookOptions<StoreTransportMutation, StoreTransportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StoreTransportMutation, StoreTransportMutationVariables>(StoreTransportDocument, options);
}
export type StoreTransportMutationHookResult = ReturnType<typeof useStoreTransportMutation>;
export type StoreTransportMutationResult = Apollo.MutationResult<StoreTransportMutation>;
export type StoreTransportMutationOptions = Apollo.BaseMutationOptions<
  StoreTransportMutation,
  StoreTransportMutationVariables
>;
export const StoreTransportForImportDocument = gql`
  mutation StoreTransportForImport($transport: TransportInput!) {
    storeTransport(transport: $transport) {
      pid
      transportNumber
      startTime
      endTime
      vehicle {
        pid
        name
        vehicleBrand {
          id
          label
        }
        fuelType {
          id
          label
        }
        vin
      }
      totalCargoWeight
      totalGoodsVolume
      kmDriven
      usedFuelInLitres
      energyUsedInKwh
      mode
      fuelQuality
    }
  }
`;
export type StoreTransportForImportMutationFn = Apollo.MutationFunction<
  StoreTransportForImportMutation,
  StoreTransportForImportMutationVariables
>;

/**
 * __useStoreTransportForImportMutation__
 *
 * To run a mutation, you first call `useStoreTransportForImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreTransportForImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeTransportForImportMutation, { data, loading, error }] = useStoreTransportForImportMutation({
 *   variables: {
 *      transport: // value for 'transport'
 *   },
 * });
 */
export function useStoreTransportForImportMutation(
  baseOptions?: Apollo.MutationHookOptions<StoreTransportForImportMutation, StoreTransportForImportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StoreTransportForImportMutation, StoreTransportForImportMutationVariables>(
    StoreTransportForImportDocument,
    options,
  );
}
export type StoreTransportForImportMutationHookResult = ReturnType<typeof useStoreTransportForImportMutation>;
export type StoreTransportForImportMutationResult = Apollo.MutationResult<StoreTransportForImportMutation>;
export type StoreTransportForImportMutationOptions = Apollo.BaseMutationOptions<
  StoreTransportForImportMutation,
  StoreTransportForImportMutationVariables
>;
export const DeleteTransportDocument = gql`
  mutation DeleteTransport($pid: ID!) {
    deleteTransport(pid: $pid) {
      status
      pids
      errorReasons {
        pid
        value
      }
    }
  }
`;
export type DeleteTransportMutationFn = Apollo.MutationFunction<
  DeleteTransportMutation,
  DeleteTransportMutationVariables
>;

/**
 * __useDeleteTransportMutation__
 *
 * To run a mutation, you first call `useDeleteTransportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransportMutation, { data, loading, error }] = useDeleteTransportMutation({
 *   variables: {
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useDeleteTransportMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTransportMutation, DeleteTransportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTransportMutation, DeleteTransportMutationVariables>(
    DeleteTransportDocument,
    options,
  );
}
export type DeleteTransportMutationHookResult = ReturnType<typeof useDeleteTransportMutation>;
export type DeleteTransportMutationResult = Apollo.MutationResult<DeleteTransportMutation>;
export type DeleteTransportMutationOptions = Apollo.BaseMutationOptions<
  DeleteTransportMutation,
  DeleteTransportMutationVariables
>;
export const GetTransportWithSustainabilityDocument = gql`
  query GetTransportWithSustainability($pid: ID!, $buyerPid: String) {
    getTransport(pid: $pid, buyerPid: $buyerPid) {
      pid
      transportNumber
      startTime
      endTime
      vehicle {
        pid
        name
        vehicleBrand {
          id
          label
        }
        fuelType {
          id
          label
        }
        vin
      }
      totalCargoWeight
      totalGoodsVolume
      kmDriven
      usedFuelInLitres
      energyUsedInKwh
      transportOrders {
        id
        pid
        transportOrderNumber
      }
      route {
        pid
        from {
          name
        }
        to {
          name
        }
      }
      mode
      fuelQuality
      co2e
      sustainabilityScore
      trueTransparency
    }
  }
`;

/**
 * __useGetTransportWithSustainabilityQuery__
 *
 * To run a query within a React component, call `useGetTransportWithSustainabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportWithSustainabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportWithSustainabilityQuery({
 *   variables: {
 *      pid: // value for 'pid'
 *      buyerPid: // value for 'buyerPid'
 *   },
 * });
 */
export function useGetTransportWithSustainabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTransportWithSustainabilityQuery,
    GetTransportWithSustainabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransportWithSustainabilityQuery, GetTransportWithSustainabilityQueryVariables>(
    GetTransportWithSustainabilityDocument,
    options,
  );
}
export function useGetTransportWithSustainabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransportWithSustainabilityQuery,
    GetTransportWithSustainabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransportWithSustainabilityQuery, GetTransportWithSustainabilityQueryVariables>(
    GetTransportWithSustainabilityDocument,
    options,
  );
}
export type GetTransportWithSustainabilityQueryHookResult = ReturnType<typeof useGetTransportWithSustainabilityQuery>;
export type GetTransportWithSustainabilityLazyQueryHookResult = ReturnType<
  typeof useGetTransportWithSustainabilityLazyQuery
>;
export type GetTransportWithSustainabilityQueryResult = Apollo.QueryResult<
  GetTransportWithSustainabilityQuery,
  GetTransportWithSustainabilityQueryVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($userInput: UserInput!) {
    createUser(userInput: $userInput) {
      id
      name
      name
      selectedVehicle {
        pid
        name
      }
      userConsentAccepted
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const GetUsersDocument = gql`
  query GetUsers {
    getAllUsersForCurrentFlorg {
      id
      name
      roles {
        pid
        key
        description
        rights {
          pid
          key
          description
        }
      }
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
  query GetUser($pid: String!) {
    getUserByPidForCurrentFlorg(pid: $pid) {
      id
      name
      roles {
        pid
        key
        description
        rights {
          pid
          key
          description
        }
      }
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetAllRolesDocument = gql`
  query getAllRoles {
    getAllRoles {
      pid
      key
      description
      rights {
        pid
        key
        description
      }
    }
  }
`;

/**
 * __useGetAllRolesQuery__
 *
 * To run a query within a React component, call `useGetAllRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllRolesQuery, GetAllRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRolesQuery, GetAllRolesQueryVariables>(GetAllRolesDocument, options);
}
export function useGetAllRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllRolesQuery, GetAllRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRolesQuery, GetAllRolesQueryVariables>(GetAllRolesDocument, options);
}
export type GetAllRolesQueryHookResult = ReturnType<typeof useGetAllRolesQuery>;
export type GetAllRolesLazyQueryHookResult = ReturnType<typeof useGetAllRolesLazyQuery>;
export type GetAllRolesQueryResult = Apollo.QueryResult<GetAllRolesQuery, GetAllRolesQueryVariables>;
export const GetVehicleBrandsDocument = gql`
  query GetVehicleBrands {
    getVehicleBrands {
      id
      label
    }
  }
`;

/**
 * __useGetVehicleBrandsQuery__
 *
 * To run a query within a React component, call `useGetVehicleBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVehicleBrandsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVehicleBrandsQuery, GetVehicleBrandsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVehicleBrandsQuery, GetVehicleBrandsQueryVariables>(GetVehicleBrandsDocument, options);
}
export function useGetVehicleBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleBrandsQuery, GetVehicleBrandsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVehicleBrandsQuery, GetVehicleBrandsQueryVariables>(GetVehicleBrandsDocument, options);
}
export type GetVehicleBrandsQueryHookResult = ReturnType<typeof useGetVehicleBrandsQuery>;
export type GetVehicleBrandsLazyQueryHookResult = ReturnType<typeof useGetVehicleBrandsLazyQuery>;
export type GetVehicleBrandsQueryResult = Apollo.QueryResult<GetVehicleBrandsQuery, GetVehicleBrandsQueryVariables>;
export const VehiclesDocument = gql`
  query Vehicles($filter: VehiclesFilterInput) {
    vehicles(filter: $filter) {
      pid
      name
      telematicStatus
      telematicUnitType
      grossVehicleWeight
      vehicleBrand {
        id
        label
      }
      fuelType {
        id
        label
      }
      type
    }
  }
`;

/**
 * __useVehiclesQuery__
 *
 * To run a query within a React component, call `useVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<VehiclesQuery, VehiclesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehiclesQuery, VehiclesQueryVariables>(VehiclesDocument, options);
}
export function useVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehiclesQuery, VehiclesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehiclesQuery, VehiclesQueryVariables>(VehiclesDocument, options);
}
export type VehiclesQueryHookResult = ReturnType<typeof useVehiclesQuery>;
export type VehiclesLazyQueryHookResult = ReturnType<typeof useVehiclesLazyQuery>;
export type VehiclesQueryResult = Apollo.QueryResult<VehiclesQuery, VehiclesQueryVariables>;
export const VehiclesWithSubcarriersDocument = gql`
  query VehiclesWithSubcarriers {
    vehiclesWithSubcarriers {
      pid
      name
    }
  }
`;

/**
 * __useVehiclesWithSubcarriersQuery__
 *
 * To run a query within a React component, call `useVehiclesWithSubcarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesWithSubcarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesWithSubcarriersQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehiclesWithSubcarriersQuery(
  baseOptions?: Apollo.QueryHookOptions<VehiclesWithSubcarriersQuery, VehiclesWithSubcarriersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehiclesWithSubcarriersQuery, VehiclesWithSubcarriersQueryVariables>(
    VehiclesWithSubcarriersDocument,
    options,
  );
}
export function useVehiclesWithSubcarriersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VehiclesWithSubcarriersQuery, VehiclesWithSubcarriersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehiclesWithSubcarriersQuery, VehiclesWithSubcarriersQueryVariables>(
    VehiclesWithSubcarriersDocument,
    options,
  );
}
export type VehiclesWithSubcarriersQueryHookResult = ReturnType<typeof useVehiclesWithSubcarriersQuery>;
export type VehiclesWithSubcarriersLazyQueryHookResult = ReturnType<typeof useVehiclesWithSubcarriersLazyQuery>;
export type VehiclesWithSubcarriersQueryResult = Apollo.QueryResult<
  VehiclesWithSubcarriersQuery,
  VehiclesWithSubcarriersQueryVariables
>;
export const VehicleDocument = gql`
  query Vehicle($pid: ID!) {
    vehicle(pid: $pid) {
      pid
      tispVehiclePid
      name
      telematicUnitType
      telematicStatus
      grossVehicleWeight
      environmentClass {
        id
        label
      }
      vehicleBrand {
        id
        label
      }
      fuelType {
        id
        label
      }
      vin
      model
      year
      type
    }
  }
`;

/**
 * __useVehicleQuery__
 *
 * To run a query within a React component, call `useVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleQuery({
 *   variables: {
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useVehicleQuery(baseOptions: Apollo.QueryHookOptions<VehicleQuery, VehicleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleQuery, VehicleQueryVariables>(VehicleDocument, options);
}
export function useVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleQuery, VehicleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehicleQuery, VehicleQueryVariables>(VehicleDocument, options);
}
export type VehicleQueryHookResult = ReturnType<typeof useVehicleQuery>;
export type VehicleLazyQueryHookResult = ReturnType<typeof useVehicleLazyQuery>;
export type VehicleQueryResult = Apollo.QueryResult<VehicleQuery, VehicleQueryVariables>;
export const StoreVehicleDocument = gql`
  mutation StoreVehicle($vehicle: VehicleInput!) {
    storeVehicle(vehicle: $vehicle) {
      pid
      tispVehiclePid
      name
      telematicUnitType
      grossVehicleWeight
      environmentClass {
        id
        label
      }
      vehicleBrand {
        id
        label
      }
      fuelType {
        id
        label
      }
      vin
      model
      year
      type
    }
  }
`;
export type StoreVehicleMutationFn = Apollo.MutationFunction<StoreVehicleMutation, StoreVehicleMutationVariables>;

/**
 * __useStoreVehicleMutation__
 *
 * To run a mutation, you first call `useStoreVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeVehicleMutation, { data, loading, error }] = useStoreVehicleMutation({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *   },
 * });
 */
export function useStoreVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<StoreVehicleMutation, StoreVehicleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StoreVehicleMutation, StoreVehicleMutationVariables>(StoreVehicleDocument, options);
}
export type StoreVehicleMutationHookResult = ReturnType<typeof useStoreVehicleMutation>;
export type StoreVehicleMutationResult = Apollo.MutationResult<StoreVehicleMutation>;
export type StoreVehicleMutationOptions = Apollo.BaseMutationOptions<
  StoreVehicleMutation,
  StoreVehicleMutationVariables
>;
export const DeleteVehiclesDocument = gql`
  mutation DeleteVehicles($pids: [String!]!) {
    deleteVehicles(pids: $pids) {
      status
      pids
      errorReasons {
        pid
        value
      }
    }
  }
`;
export type DeleteVehiclesMutationFn = Apollo.MutationFunction<DeleteVehiclesMutation, DeleteVehiclesMutationVariables>;

/**
 * __useDeleteVehiclesMutation__
 *
 * To run a mutation, you first call `useDeleteVehiclesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehiclesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehiclesMutation, { data, loading, error }] = useDeleteVehiclesMutation({
 *   variables: {
 *      pids: // value for 'pids'
 *   },
 * });
 */
export function useDeleteVehiclesMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVehiclesMutation, DeleteVehiclesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVehiclesMutation, DeleteVehiclesMutationVariables>(DeleteVehiclesDocument, options);
}
export type DeleteVehiclesMutationHookResult = ReturnType<typeof useDeleteVehiclesMutation>;
export type DeleteVehiclesMutationResult = Apollo.MutationResult<DeleteVehiclesMutation>;
export type DeleteVehiclesMutationOptions = Apollo.BaseMutationOptions<
  DeleteVehiclesMutation,
  DeleteVehiclesMutationVariables
>;
export const GetVehicleDataFromVinDocument = gql`
  query getVehicleDataFromVin($vin: String!) {
    getVehicleDataFromVin(vin: $vin) {
      country
      manufacturer
      checkDigit
      year
      plantCode
      serialNumber
      brandId
    }
  }
`;

/**
 * __useGetVehicleDataFromVinQuery__
 *
 * To run a query within a React component, call `useGetVehicleDataFromVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleDataFromVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleDataFromVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useGetVehicleDataFromVinQuery(
  baseOptions: Apollo.QueryHookOptions<GetVehicleDataFromVinQuery, GetVehicleDataFromVinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVehicleDataFromVinQuery, GetVehicleDataFromVinQueryVariables>(
    GetVehicleDataFromVinDocument,
    options,
  );
}
export function useGetVehicleDataFromVinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleDataFromVinQuery, GetVehicleDataFromVinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVehicleDataFromVinQuery, GetVehicleDataFromVinQueryVariables>(
    GetVehicleDataFromVinDocument,
    options,
  );
}
export type GetVehicleDataFromVinQueryHookResult = ReturnType<typeof useGetVehicleDataFromVinQuery>;
export type GetVehicleDataFromVinLazyQueryHookResult = ReturnType<typeof useGetVehicleDataFromVinLazyQuery>;
export type GetVehicleDataFromVinQueryResult = Apollo.QueryResult<
  GetVehicleDataFromVinQuery,
  GetVehicleDataFromVinQueryVariables
>;
export const GetTelematicSuggestionsDocument = gql`
  query getTelematicSuggestions($telematicSuggestionInputs: [TelematicSuggestionInput!]!) {
    getTelematicSuggestions(telematicSuggestionInputs: $telematicSuggestionInputs) {
      name
      suggestions {
        telematicType
        label
        priority
      }
    }
  }
`;

/**
 * __useGetTelematicSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetTelematicSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTelematicSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTelematicSuggestionsQuery({
 *   variables: {
 *      telematicSuggestionInputs: // value for 'telematicSuggestionInputs'
 *   },
 * });
 */
export function useGetTelematicSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTelematicSuggestionsQuery, GetTelematicSuggestionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTelematicSuggestionsQuery, GetTelematicSuggestionsQueryVariables>(
    GetTelematicSuggestionsDocument,
    options,
  );
}
export function useGetTelematicSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTelematicSuggestionsQuery, GetTelematicSuggestionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTelematicSuggestionsQuery, GetTelematicSuggestionsQueryVariables>(
    GetTelematicSuggestionsDocument,
    options,
  );
}
export type GetTelematicSuggestionsQueryHookResult = ReturnType<typeof useGetTelematicSuggestionsQuery>;
export type GetTelematicSuggestionsLazyQueryHookResult = ReturnType<typeof useGetTelematicSuggestionsLazyQuery>;
export type GetTelematicSuggestionsQueryResult = Apollo.QueryResult<
  GetTelematicSuggestionsQuery,
  GetTelematicSuggestionsQueryVariables
>;
