interface Colors {
  gray: string;
  red: string;
  orange: string;
  yellow: string;
  lightGreen: string;
  green: string;
  fontLightSecondary: string;
  accentLightMain: string;
  buttonPrimary: string;
  lightBlue: string;
  blue: string;
  buttonGray: string;
  LightBlueSelected: string;
  surfaceLightMain: string;
}

const colors: Colors = {
  gray: '#EAEAEA',
  red: '#F34A61',
  orange: '#FF8B66',
  yellow: '#FFCE4C',
  lightGreen: '#BBE429',
  green: '#01CF69',
  fontLightSecondary: '#141414',
  accentLightMain: '#346559',
  buttonPrimary: '#1251B5',
  lightBlue: '#b7c7e7',
  blue: '#1C6BBA',
  buttonGray: '#888B8D5E',
  LightBlueSelected: '#1C6BBA29',
  surfaceLightMain: '#f7f7f7',
};

export default colors;
