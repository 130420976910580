import { ApplicationContext as ApplicationContextValue, Vehicle } from '../../../generated/graphql';
import { ActionMap } from '../../types';

export enum ApplicationContextActionTypes {
  SELECT_VEHICLE = 'SELECT_VEHICLE',
  UPDATE = 'UPDATE',
  CLEAR = 'CLEAR',
}

export type ApplicationContextPayload = {
  [ApplicationContextActionTypes.SELECT_VEHICLE]: Vehicle;
  [ApplicationContextActionTypes.UPDATE]: ApplicationContextValue;
  [ApplicationContextActionTypes.CLEAR]: undefined;
};

export type ApplicationContextActions =
  ActionMap<ApplicationContextPayload>[keyof ActionMap<ApplicationContextPayload>];
