import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { Auth, Cache } from 'aws-amplify';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 3000,
    jitter: true,
  },
  attempts: {
    max: 1,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    retryIf: (error, _) => !!error,
  },
});

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL });

const customLink = new ApolloLink((operation, forward) => {
  operation.variables = {
    ...operation.variables,
  };

  return forward(operation);
});

const dev = process.env.NODE_ENV === 'development';

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`),
    );
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if (networkError.message === 'Response not successful: Received status code 401') {
      return new Observable((observer) => {
        (async () => {
          try {
            const refreshedToken = await refreshToken();
            if (refreshedToken) {
              if (dev) console.log('refreshedToken inside observable', refreshedToken.toString().slice(0, 50));

              // Set context for the operation
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  authorization: `${refreshedToken}` || null,
                },
              }));
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              if (dev) console.log('forward and retry last failed request');
              forward(operation).subscribe(subscriber);
            }
          } catch (error) {
            // No token available, we force user to login
            if (dev) console.log('observer.error which should force user to login. but does it really????', error);
            observer.error(error);
          }
        })(); // Invoke the async function immediately
      });
    }
  }
});

const authLink = setContext(async (_, { headers }) => {
  const cachedToken: string | null = Cache.getItem('token');
  return {
    headers: {
      ...headers,
      Authorization: cachedToken,
    },
  };
});

const refreshToken = async () => {
  if (dev) console.log('this is the refreshToken function being called');
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(cognitoUser.signInUserSession.refreshToken, (err: any, session: any) => {
        if (err) {
          if (dev) console.log('refreshSession error', err);
          reject(err);
        } else {
          const { idToken } = session;
          Cache.setItem('token', idToken.jwtToken);
          resolve(idToken.jwtToken);
          if (dev) console.log('resolving and setting token in refreshToken');
        }
      });
    });
  } catch (e) {
    if (dev) console.log('Unable to refresh Token in refreshToken function. What happens next??', e);
    throw e;
  }
};

const links = ApolloLink.from([authLink, customLink, retryLink, errorLink, httpLink]);

const mergePages =
  (uniqueKey: string) =>
  (existing: any, incoming: SafeReadonly<any>, { readField }: any) => {
    const merged: Record<string, any> = {};
    [...(existing?.items || []), ...(incoming?.items || [])].forEach((item: any) => {
      merged[readField(uniqueKey, item)] = item;
    });

    return { items: Object.values(merged), nextToken: incoming.nextToken };
  };

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getUsers: {
            keyArgs: false,
            merge: mergePages('id'),
          },
        },
      },
    },
  }),

  link: links,
});

export default client;
