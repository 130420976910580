import React, { useState } from 'react';
import {
  Body,
  Header,
  HeaderDivider,
  HeaderTitle,
  Icon,
  IconButton,
  List,
  ListCategory,
  Navigation,
  TextField,
} from '@ccsdk/kingbolt';
import messages from '../../messages';
import { useIntl } from 'react-intl';
import { OrganisationIcon } from '../../icons';
import styled from '@emotion/styled';
import { Organization } from '../../generated/sof-graphql';

interface Props {
  organisations: Organization[];
  goToView: (index: number) => () => void;
  onOrganisationClick: (organization: Organization | undefined | null) => () => void;
  loading?: boolean;
}

const SwitchOrganisationsView = ({ goToView, onOrganisationClick, organisations, loading }: Props) => {
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState<string | null>(null);

  const onSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredOrganisations = searchText?.length
    ? organisations.filter((organisation) => organisation.description.toLowerCase().includes(searchText.toLowerCase()))
    : organisations;

  return (
    <Navigation>
      <Header>
        <IconButton onClick={goToView(0)}>
          <Icon type="chevronleft" />
        </IconButton>
        <HeaderDivider />
        <HeaderTitle>{formatMessage(messages.switch_organisation)}</HeaderTitle>
      </Header>
      <SubHeader>
        <IconButton>
          <Icon type="search" />
        </IconButton>
        <HeaderDivider />
        <TextField fullWidth InputProps={{ disableUnderline: true }} onChange={onSearchTextChange} />
      </SubHeader>
      <Body>
        <List>
          {filteredOrganisations.map((organisation) => (
            <OrganisationListItem
              key={organisation.id}
              onClick={onOrganisationClick(organisation)}
              primary={`${organisation?.description} ${
                !organisation.active || organisation.active == null ? `(${formatMessage(messages.not_activated)})` : ''
              }`}
              icon={<OrganisationIcon />}
              disabled={loading || !organisation.active || organisation.active == null}
            />
          ))}
        </List>
      </Body>
    </Navigation>
  );
};

const OrganisationListItem = styled(ListCategory)`
  .MuiSvgIcon-root {
    display: none;
  }
`;

const SubHeader = styled(Header)`
  border-color: ${(props: any) => props.theme.palette.grey[200]} !important;
`;

export default SwitchOrganisationsView;
