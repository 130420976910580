import React, { createContext, FunctionComponent, useContext, useMemo, useState } from 'react';
import { FiltersInput } from '../../components/Reports/types';
import { TransportOrderListReport } from '../../generated/sof-graphql';

const defaultContextValue = {
  contextFilters: null,
  contextOrderReport: null,
  setContextFilters: () => {},
  setContextOrderReport: () => {},
};

interface TransportOrderReportsContextInterface {
  contextFilters: FiltersInput | null;
  contextOrderReport: TransportOrderListReport | null;
  setContextFilters(filters: FiltersInput): void;
  setContextOrderReport(orderReport: TransportOrderListReport): void;
}

export const TransportOrderReportsContext = createContext<TransportOrderReportsContextInterface>(defaultContextValue);

const useTransportOrderReport = () => {
  const context = useContext(TransportOrderReportsContext);
  if (!context) {
    throw new Error('useTransportOrderReportsContext must be used within a ToastContext');
  }
  return context;
};

interface Props {
  children: React.ReactNode;
}

const TransportOrderReportsContextProvider: FunctionComponent<Props> = ({ children }) => {
  const [contextFilters, setFilters] = useState<FiltersInput | null>(defaultContextValue.contextFilters);
  const [contextOrderReport, setOrderReport] = useState<TransportOrderListReport | null>(
    defaultContextValue.contextOrderReport,
  );

  const setContextFilters = (filters: FiltersInput) => {
    setFilters(filters);
  };

  const setContextOrderReport = (orderReport: TransportOrderListReport) => {
    setOrderReport(orderReport);
  };

  const value = useMemo(
    () => ({
      contextFilters,
      setContextFilters,
      contextOrderReport,
      setContextOrderReport,
      useTransportOrderReport,
    }),
    [contextFilters, contextOrderReport],
  );

  return <TransportOrderReportsContext.Provider value={value}>{children}</TransportOrderReportsContext.Provider>;
};

export { TransportOrderReportsContextProvider, useTransportOrderReport };
